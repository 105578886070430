import {useEffect, useState} from "react";
import {
    askSourcing,
    getStoreList,
    getStoreProductList,
    ManualSync
} from "../../../api/product";
import {message, Modal, Select} from "antd";
import CommonTable from "../../../components/CommonTable";
import {columns} from "./render";
import CommonContent from "../../../components/CommonContent";
import {useNavigate} from "react-router-dom";
import useStore from "../../../store/store";
import {ProductSourceStatus} from "../../../commons/enum";
import {getDetailByIdAndStore} from "../../../api/product";

const map = new Map()
const store = new Map()
export default function StoreProducts() {
    const [storeInfo,setStoreInfo] = useState([]);
    const [formQuery,setFormQuery] = useState({
        storeId:'',thirdTag:'',title:'',service_id:'',
        platformType:'', pageSize:10, pageNum: 1
    });
    const [listQuery, setListQuery] = useState({total:0,page:1,limit:10});
    const [loading,setLoading] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [selectedProducts,setSelectedProducts] = useState([]);
    const [selectedRowKeys,setSelectedRowKeys] = useState([])
    const [storeId,setStoreId] = useState();
    const navigate = useNavigate();
    const currentLocaleData = useStore(state => state.currentLocaleData);

    useEffect(() => {
        setLoading(true);
        (async function r(){
            const result = await getStoreList({pageNum: 1, pageSize: 999}).then((res) => {
                return res.data
            }).catch((err) => {
                console.error(err);
            })
            if(result && result.list.length){
                const list = result.list
                setStoreInfo(list);
                formQuery.storeId = list[0].storeId
                for(let i = 0;i<list.length;i++){
                    store.set(list[i].storeId, list[i])
                }
                setStoreId(list[0].storeId)
                setFormQuery({...formQuery});
                getStoreProducts();
            }
        })()
        setLoading(false);
    }, []);

    const getStoreProducts = async (formObj) => {
        setLoading(true);
        formQuery.pageSize = listQuery.limit;
        formQuery.pageNum = listQuery.page;
        const formData = formObj?{...formQuery,...formObj}:formQuery;
        setFormQuery({...formData});
        const result = await getStoreProductList(formData).then(res => {
            return res.data
            
        }).catch((err) => {
            console.error(err);
        })
        const arr = result.list.map((item) => {
            item.key = item.id;
            item.storeUrl = store.get(item.storeId) ? store.get(item.storeId).storeUrl : ""
            return item
        });
        setTableData(arr);
        listQuery.total = +result.total;
        setListQuery({...listQuery});
        setLoading(false);
        const newArr = [].concat(arr)
        for(let i=0;i<newArr.length;i++){
            const el = newArr[i]
            if(!el.imgUrl){
                await getDetailByIdAndStore(el.id).then(res=>{
                    if(res.data.skuList.length){
                        for(let j=0;j<res.data.skuList.length;j++){
                            if(res.data.skuList[j].imgUrl){
                                newArr[i].imgUrl = res.data.skuList[j].imgUrl
                                continue
                            }
                        }
                    }
                })
            }
        }
        setTableData(newArr);
    }

    const handleSearch = (event) => {
        formQuery.productName = event.target.value;
        setFormQuery(formQuery);
        updatePage(1,10);
    }

    const handleSyncData = () => {
        if(storeId){
            setLoading(true);
            ManualSync({ storeId: storeId }).then(res => {
                message.success('Sync data success');
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            message.warning("You need select a store")
        }
    }

    const handleActionsClick = (e) => {
        if(e.key !== '0'){
            return;
        }
        handleSyncData();
    }

    const handleTableSelect = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys([...selectedRowKeys]);
        setSelectedProducts([...selectedRows]);
    };

    const updatePage = (page,pageSize,formObj) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getStoreProducts(formObj);
    }

    const handleDetail = (record) => {
        const platformType = store.get(record.storeId).platformType
        navigate(`/my-product-detail/${'edit'}/${record.id}/${'store'}/${platformType}/${record.storeId}`);
    }

    const handleRowSelect = (record,rowIndex) => {
        if(selectedRowKeys.includes(rowIndex)){
            setSelectedRowKeys([...selectedRowKeys.filter((item) => item !== rowIndex)]);
            setSelectedProducts([...selectedProducts.filter((item) => item !== record)]);
            return;
        }
        setSelectedRowKeys([...selectedRowKeys,rowIndex]);
        setSelectedProducts([...selectedProducts,record]);
    }

    const handleAskSourcing = (record) => {
        setLoading(true);
        askSourcing(record.id).then(res => {
            getStoreProducts();
        }).finally(() => {
            setLoading(false);
        })
    }

    const tagClicks = {
        'ask sourcing':handleAskSourcing
    }

    return (
        <CommonContent
            loading={loading}
            filterable={true}
            searchText={currentLocaleData.CommonContent.MyProducts.searchText}
            searchEvent={handleSearch}
            apply={(formObj) => {
                formObj.storeId = formObj.storeId || '';
                setStoreId(formObj.storeId);
                formObj.thirdTag = formObj.thirdTag || '';
                formObj.sourceStatus = formObj.sourceStatus ? formObj.sourceStatus + '' : ''
                for (let i = 0; i < storeInfo.length; i++) {
                    if(formObj.storeId === storeInfo[i].storeId){
                        formObj.platformType = storeInfo[i].platformType;
                        break;
                    }
                }
                formObj.platformType = formObj.platformType || '';
                const filterShow = {};
                if(formObj.storeId){
                    filterShow['Store URL']= store.get(formObj.storeId).storeUrl;
                }
                if(formObj.thirdTag){
                    filterShow['Product Tag']=formObj.thirdTag;
                }
                if(formObj.sourceStatus + ""){
                    filterShow['Sourcing Status']=ProductSourceStatus[formObj.sourceStatus + ""];
                }
                updatePage(1,10,formObj);
                return [JSON.parse(JSON.stringify(formObj)),filterShow];
            }}
            initFilterObj={formQuery}
            filterList={[
                {
                    name:"storeId",
                    title:currentLocaleData.CommonContent.MyProducts.filter.selects[1].title,
                    component:
                        <Select  placeholder="Please Select Store URL"
                                options={storeInfo.map((item,index) => (
                                    {key:item.storeId,value: item.storeId,label:item.storeUrl}
                                ))}>
                        </Select>
                },
                {
                    name:"thirdTag",
                    title: currentLocaleData.CommonContent.MyProducts.filter.selects[2].title,
                    component:
                        <Select allowClear options={[
                            {label:currentLocaleData.CommonContent.MyProducts.filter.selects[2].options[0],value: "OutOfStock"},
                            {label:currentLocaleData.CommonContent.MyProducts.filter.selects[2].options[1],value: "Notfulfilled"},
                        ]}/>
                },
                {
                    name:"sourceStatus",
                    title: currentLocaleData.CommonContent.MyProducts.filter.selects[3].title,
                    component:
                        <Select allowClear options={[
                            {label: 'Sourcing',value: "0"},
                            {label: 'Sourced',value: "1"},
                            {label: 'Ask Sourcing',value: "2"},
                            {label: 'Error',value: "-1"},
                        ]}/>
                },
            ]}
            actions={{
                items:[{key:'0',label:currentLocaleData.CommonContent.MyProducts.actions[0]}],
                onClick:handleActionsClick
            }}
        >
            <CommonTable
                loading={false}
                rowSelection={{type:'checkbox',selectedRowKeys,onChange:handleTableSelect}}
                columns={columns(handleDetail,handleRowSelect,currentLocaleData.CommonContent.MyProducts.table.columns,tagClicks)}
                dataSource={tableData}
                page={listQuery.page}
                total={listQuery.total}
                pageSize={listQuery.limit}
                pageChange={updatePage}
            >
            </CommonTable>
        </CommonContent>
    )
}
