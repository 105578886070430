export const isEmpty = (str) => {
    return str===undefined || str===null || /\s+/g.test(str) || str === '' || str.trim() === '';
}

export const storeUrlFormat = (store_url) => {
    if(store_url){
        return store_url.split(".")[0] === "www"
            ? store_url.split(".")[1]
            : store_url.split(".")[0];
    }else{
        return '';
    }
}
