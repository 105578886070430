import {create} from "zustand";
import {getToken} from "../utils/auth";
import {getCookies} from "../utils/cookies";
import I18N from "../commons/i18n";

const useStore = create((set) => (
    {
        navTitle:'',
        updateNavTitle:(navTitle) => {
            set(() => ({navTitle}));
        },
        token:getToken(),
        updateToken:(token) => {
            set(() => ({token}));
        },
        uid: localStorage.getItem('uid'),
        updateUID:(uid) => {
            set(() => ({uid}));
        },
        name:getCookies('name'),
        updateName:(name) => {
            set(() => ({name}));
        },
        email:getCookies('email'),
        updateEmail:(email) => {
            set(() => ({email}));
        },
        avatar: '',
        updateAvatar:(avatar) => {
            set(() => ({avatar}));
        },
        balance:'',
        updateBalance:(balance) => {
            set(() => ({balance}));
        },
        autoProcess:'',
        updateAutoProcess:(autoProcess) => {
            set(() => ({autoProcess}));
        },
        currentLocaleData:I18N.en,
        setCurrentLocaleData:(currentLocaleData) => {
            set(() => ({currentLocaleData}));
        },
        shop:getCookies('shop'),
        updateShop:(shop) => {
            set(() => ({shop}));
        },
        shopify:getCookies('shopify'),
        updateShopify:(shopify) => {
            set(() => ({shopify}));
        },
        isAuth: localStorage.getItem("uid") ? true : false,
        updateIsAuth:(isAuth) => {
            set(() => ({isAuth}));
        }
    }
))

export default useStore;
