import uuid from "../../../utils/uuid";
import {Flex, Tag, Tooltip} from "antd";
import {CostStatusColor, TypeStatus} from "../../../commons/enum";
import {storeUrlFormat} from "../../../utils/stringUtils";
import Decimal from "decimal.js"

const map = new Map()

function toDecimal(num) {
    return new Decimal(num)
}

function countPrice(a1, a2, a3){
    let count = toDecimal(0);
    if(a1){
        a1 = toDecimal(a1)
        count = count.plus(a1)
    }
    if(a2){
        a2 = toDecimal(a2)
        count = count.plus(a2)
    }
    if(a3){
        a3 = toDecimal(a3)
        count = count.plus(a3)
    }
    return count.toNumber()
}

const transactionColumns = (cols) =>  [
    {title:cols[0],dataIndex:'id',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[1],dataIndex:'orderName',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[2],dataIndex:'type',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[3],dataIndex:'storeUrl',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {storeUrlFormat(_)}
            </Tooltip>
        )},
    {title:cols[5],dataIndex:'amount',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[6],dataIndex:'balance',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                <span style={{color:'red'}}>{_}</span>
            </Tooltip>
        )},
    {title:cols[7],dataIndex:'currency',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[8],dataIndex:'created',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[9],dataIndex:'remark',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
]
const orderCostColumns = (cols) => [
    {title:cols[0],dataIndex:'flowId',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[1],dataIndex:'orderName',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[2],dataIndex:'storeUrl',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {storeUrlFormat(_)}
            </Tooltip>
        )},
    {title:cols[3],dataIndex:'shipRule',key:uuid(),render:(_,record) => (
            <Flex vertical="vertical" gap={3} align="center">
                <Tooltip key={uuid()} title={"Weight: " + _.weightFee} placement={'bottomLeft'}>
                    <Tag color="default">
                    Weight: {_.weightFee}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Registered: " + _.trackingFee} placement={'bottomLeft'}>
                    <Tag color="default">
                    Registered: {_.trackingFee}
                    </Tag>
                </Tooltip>
            </Flex>
        )},
    // {title:cols[4],dataIndex:'shipRule',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
    //         <Tooltip title={_.country} placement={'bottomLeft'}>
    //             {_.country}
    //         </Tooltip>
    //     )},
    {title:cols[5],dataIndex:'shipRule',key:uuid(),render:(_,record) => (
            <Flex vertical="vertical" gap={3} align="center">
                <Tooltip key={uuid()} title={"Scheme: " + _.program} placement={'bottomLeft'}>
                    <Tag color="default">
                        {"Scheme: " + _.program}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Rule: " + _.name} placement={'bottomLeft'}>
                    <Tag color="default">
                        {"Rule: " + _.name}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Country: " + _.country} placement={'bottomLeft'}>
                    <Tag color="default">
                        {"Country: " + _.country}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Attribute: " + _.attribute} placement={'bottomLeft'}>
                    <Tag color="default">
                        {"Attribute: " + _.attribute}
                    </Tag>
                </Tooltip>
            </Flex>
        )},
    {title:cols[6],dataIndex:'cost',key:uuid(),render:(_,record) => (
            <Flex vertical="vertical" gap={3} align="center">
                <Tooltip key={uuid()} title={"Product Fee: " + record.productFee} placement={'bottomLeft'}>
                    <Tag color={CostStatusColor[0]}>
                        {"Product Fee: " + record.productFee}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Service Charge: " + record.serviceFee} placement={'bottomLeft'}>
                    <Tag color={CostStatusColor[1]}>
                        {"Service Charge: " + record.serviceFee}
                    </Tag>
                </Tooltip>
                <Tooltip key={uuid()} title={"Logistics Costs: " + record.shippingFee} placement={'bottomLeft'}>
                    <Tag color={CostStatusColor[2]}>
                        {"Logistics Costs: " + record.shippingFee}
                    </Tag>
                </Tooltip>
            </Flex>
        )},
    {title:cols[7],dataIndex:'discount',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[8],dataIndex:'total_amount',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={record.payAmount} placement={'bottomLeft'}>
                {record.payAmount}
            </Tooltip>
        )},
    {title:cols[9],dataIndex:'currency',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
    {title:cols[10],dataIndex:'createTime',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
            <Tooltip title={_} placement={'bottomLeft'}>
                {_}
            </Tooltip>
        )},
]

const billColumns = {
    '1':transactionColumns,
    '2':orderCostColumns,
}

export {billColumns}
