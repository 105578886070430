import './CommonContent.scss'
import {Button, Drawer, Dropdown, Flex, Form, Input, Modal, Row, Space, Spin, Tabs, Tag} from "antd";
import {DownOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import useStore from "../store/store";
import uuid from "../utils/uuid";

export default function CommonContent(props) {
    const navTitle = useStore((state) => state.navTitle);
    const [openFilter,setOpenFilter] = useState(false);
    const [visibleObj,setVisibleObj] = useState({pageTab:false,tableTab:false,search:false,filter:false,actions:false,drawer:false});
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [isApplyClose,setIsApplyClose] = useState(false);
    const [lastFilters,setLastFilters] = useState(props.initFilterObj || {});
    const [filterForm] = Form.useForm();
    const [isFilterShow,setFilterShow] = useState(false);
    const [filterShowList,setFilterShowList] = useState({});
    useEffect(() => {
        visibleObj.pageTab = props.pageTabs && props.pageTabs.length > 0;
        visibleObj.tableTab = props.tableTabs && props.tableTabs.length > 0;
        visibleObj.search = props.searchText !== undefined;
        visibleObj.filter = props.filterList && props.filterList.length > 0;
        visibleObj.actions = props.actions && props.actions.items && props.actions.items.length > 0;
        visibleObj.drawer = props.filterList && props.filterList.length > 0;
        setVisibleObj({...visibleObj});
    }, [props.tableTabs]);
    const handleFilterOpen = (type) => {
        if(type==='open'){
            filterForm && filterForm.setFieldsValue(lastFilters);
        }
        setOpenFilter(!openFilter);
        setIsApplyClose(false);
    }
    const calcDataRowHeight = () => {
        const pageTabsHeight = visibleObj.pageTab ? 55 : 0;
        const filterHeight = props.filterable ? 55 : 0;
        const tableTabsHeight = visibleObj.tableTab ? 55 : 0;
        const filterShowHeight = 45;
        return pageTabsHeight+filterHeight+tableTabsHeight+filterShowHeight;
    }
    const handleTabChange = (type,activeKey) => {
        const result = props.onTabChange(Number(type),activeKey,JSON.parse(JSON.stringify(filterShowList)));
        if(result){
            const [lastFilter,filterShow] = result;
            if(lastFilter){
                setLastFilters({...lastFilter});
            }
            filterShow && setFilterShowList({...filterShow});
            if(filterShow && Object.keys(filterShow).length === 0){
                setFilterShow(false);
            }
        }
    }
    return (
        <Spin spinning={props.loading}>
            <Row className="header-row">
                <Row className="title-row">
                    <span>{navTitle}</span>
                    {props.headerButton}
                </Row>
            </Row>
            <Row className="content-row">
                {
                    visibleObj.pageTab &&
                    <Row className="page-tabs-row">
                        <Tabs tabBarExtraContent={props.tabBarExtra} style={{width:'100%'}} defaultActiveKey={props.defaultActiveKey || "1"}
                              items={props.pageTabs} onChange={(activeKey) => {
                            handleTabChange(1,activeKey);
                        }}/>
                    </Row>
                }
                {
                    props.filterable &&
                    <Row className="search-list" size={[15,15]} wrap>
                        {visibleObj.search && <Input style={{flex:1, height: "40px !important"}} onPressEnter={props.searchEvent} prefix={<SearchOutlined/>} addonAfter={props.searchAfter} placeholder={props.searchText}></Input>}
                        {
                            visibleObj.filter &&
                            <Button onClick={() => {handleFilterOpen('open')}} icon={<FilterOutlined />}>{currentLocaleData.CommonContent.filterButton}</Button>
                        }
                        {
                            visibleObj.actions &&
                            <Dropdown placement="bottomRight" trigger="click" menu={props.actions}>
                                <Button>
                                    <Space>
                                        {currentLocaleData.CommonContent.actionButton}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        }
                        {props.extra && props.extra.map(item => item)}
                    </Row>
                }
                {
                    isFilterShow &&
                    <Row className="filter-show-row">
                        <Flex gap={5}>
                            {
                                Object.keys(filterShowList).length>0 &&
                                Object.keys(filterShowList).map(item => {
                                    return (
                                        ((!Array.isArray(filterShowList[item])&&filterShowList[item]) || (Array.isArray(filterShowList[item])&&filterShowList[item].length>0) || (typeof filterShowList[item] === 'object' && Object.keys(filterShowList[item]).length>0)) &&
                                        <Tag key={uuid()} style={{height:'100%',margin:0}}>
                                            {
                                                <span style={{fontWeight: 600}}>{item}: </span>
                                            }
                                            {
                                                <span style={{background:'#d4d4d4',padding:'0 5px'}}>
                                                {filterShowList[item]}
                                            </span>
                                            }
                                        </Tag>
                                    )
                                })
                            }
                        </Flex>
                        {/*<Button style={{height:'100%'}} type="link" onClick={() => {*/}
                        {/*    setFilterShowList({});*/}
                        {/*    setFilterShow(false);*/}
                        {/*}}>Clear All</Button>*/}
                    </Row>
                }
                {
                    visibleObj.tableTab &&
                    <Row className="table-tabs-row">
                        <Tabs style={{width: '100%'}} defaultActiveKey="0" activeKey={props.tableTabsActiveKey}
                              items={props.tableTabs} onTabClick={(activeKey) => {
                                  handleTabChange(2,activeKey);
                        }}/>
                    </Row>
                }
                <Row style={{height:`calc(100% - ${calcDataRowHeight()}px)`}} className="data-row">
                    {props.children}
                </Row>
            </Row>
            {
                visibleObj.drawer &&
                <Drawer
                    title={currentLocaleData.CommonContent.filterTitle}
                    width={500}
                    onClose={handleFilterOpen}
                    open={openFilter}
                    styles={{body: {paddingBottom: 80}}}
                    extra={
                        <Button type="primary" onClick={() => {
                            setIsApplyClose(true);
                            const formObj = JSON.parse(JSON.stringify(filterForm.getFieldsValue()));
                            const [obj,filterShow] = props.apply(JSON.parse(JSON.stringify(formObj)));
                            obj && setLastFilters(obj);
                            setOpenFilter(!openFilter);
                            setFilterShowList(filterShow || {});
                            setFilterShow(Object.keys(filterShow).length > 0);
                        }}>
                            {currentLocaleData.CommonContent.applyButton}
                        </Button>
                    }
                >
                    <Form initialValues={props.initFilterObj} className="filter-list" layout="vertical" form={filterForm}>
                        {
                            props.filterList.map((item) => (
                                <Row key={uuid()} className="filter-row">
                                    <label>{item.title}</label>
                                    {
                                        Array.isArray(item.component) ?
                                            <Flex gap={10}>
                                                {item.component.map((it,idx) => (
                                                    <Form.Item key={uuid()} style={{width:"calc((100% - 10px) / 2)",margin:0}} name={item.name[idx]}>
                                                        {it}
                                                    </Form.Item>
                                                ))}
                                            </Flex> :
                                            <Form.Item style={{margin:0}} name={item.name}>
                                                {item.component}
                                            </Form.Item>
                                    }
                                </Row>
                            ))
                        }
                    </Form>
                </Drawer>
            }
        </Spin>
    )
}
