import {Button, Flex, Form, Input, message} from "antd";
import LoginLayout from "../../components/LoginLayout";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCookies, setCookies} from "../../utils/cookies";
import {login} from "../../api/user";
import {setToken} from "../../utils/auth";
import useStore from "../../store/store";
import Base64 from'js-base64';
import { useLocation } from 'react-router-dom'

function decryptBase64(str) {
    const decodedString = Base64.Base64.decode(str);
    return decodedString;
}

const convertToken = (token) => {
    try{
        let parts = token.split('.');
        let payload = atob(parts[1]);
        let payloadObj = JSON.parse(payload);
        return payloadObj
    }catch(err){
        return false
    }
}

export default function Login(){
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    // const updateName = useStore(state => state.updateName);
    const shop_store = useStore(state => state.shop);
    const shopify_store = useStore(state => state.shopify);
    const { search } = useLocation();

    const signUp = () => {
        navigate('/register');
    }
    const handleLogin = (values) => {
        setLoading(true);
        const query = {};
        new URLSearchParams(search).forEach((value, key) => {
          if (query[key]) {
            query[key] = [].concat(query[key], value);
          } else {
            query[key] = value;
          }
        });
        const shop = query.shop
        let data = {};
        if(shop){
            data = { ...values, shopifyShopUrl: shop };
        }else {
            data = { ...values };
        }
        login(data).then(response => {
            const token = response.data
            setToken(token);
            let parts = token.split('.');
            let payload = atob(parts[1]);
            let payloadObj = JSON.parse(payload);
            setCookies('uid', payloadObj.uid);
            setCookies('email', payloadObj.sub);
            setLoading(false);
            navigate('/dashboard',{replace:true});
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }

    

    useEffect(()=>{
        const query = {};
        new URLSearchParams(search).forEach((value, key) => {
          if (query[key]) {
            query[key] = [].concat(query[key], value);
          } else {
            query[key] = value;
          }
        });
        if(query.token){
            const token = query.token
            
            const payloadObj = convertToken(token)
            if(!payloadObj){
                message.error("Invalid token!")
            } else {
                setToken(token);
                setCookies('uid', payloadObj.uid);
                setCookies('email', payloadObj.sub);
                setLoading(false);
                navigate('/dashboard',{replace:true});
            }
        }
    }, [])


    return (
        <LoginLayout footerText="Don't have an account?" title="Login In" buttonText="Sign Up" changePage={signUp}>
            <Form className="login-form" layout="vertical" onFinish={handleLogin}>
                <Form.Item name="email" rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                ]}>
                    <Input className="user-input" placeholder='Email'/>
                </Form.Item>
                <Form.Item name="password" rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}>
                    <Input.Password className="user-input" placeholder="Password"/>
                </Form.Item>
                <Form.Item>
                    <Flex align="center" gap={15}>
                        <Button loading={loading} disabled={loading} className="login-btn" htmlType="submit">Login</Button>
                    </Flex>
                </Form.Item>
            </Form>
        </LoginLayout>
    )
}
