import {
    AccountBookOutlined,
    BankOutlined,
    BarChartOutlined,
    PieChartOutlined,
    SettingOutlined,
    ShoppingOutlined,
    UserOutlined
} from "@ant-design/icons";

const I18N = {
    en:{
        Header:{
            personalDropdown:'Logout',
        },
        Side:{
            menus:[
                {
                    key:'/dashboard',
                    icon:<PieChartOutlined/>,
                    label:'Dashboard',
                    path:'/dashboard',
                    text:'Dashboard',
                },
                {
                    key:'/my-products',
                    icon:<ShoppingOutlined/>,
                    label:'My Products',
                    path:'/my-products',
                    text:'My Products',
                },
                {
                    key:'/orders',
                    icon:<BarChartOutlined/>,
                    label:'Orders',
                    path:'/orders',
                    text:'Orders',
                },
                {
                    key:'/after-sale',
                    icon:<UserOutlined/>,
                    label:'After Sale',
                    path:'/after-sale',
                    text:'After Sale',
                },
                {
                    key:'/store',
                    icon:<BankOutlined/>,
                    label:'Store',
                    path:'/store',
                    text:'Store',
                },
                {
                    key:'/bill',
                    icon:<AccountBookOutlined/>,
                    label:'Bill',
                    path:'/bill',
                    text:'Bill',
                },
                {
                    key:'/settings',
                    icon:<SettingOutlined/>,
                    label:'Settings',
                    path:'/settings',
                    text:'Settings',
                },
            ],
            detailMenus:[
                {
                    key:'/orders',
                    label: 'Order Detail',
                    path:'/order-detail',
                    text:'Order Detail',
                },
                {
                    key:'/after-sale',
                    label: 'After Sale Detail',
                    path:'/after-sale-detail',
                    text:'After Sale Detail',
                },
                {
                    key:'/my-products',
                    label: 'My Product Detail',
                    path:'/my-product-detail',
                    text:'My Product Detail',
                }
            ],
            extensionButton:'Extension'
        },
        CommonDetail:{
            returnButton:'Return'
        },
        CommonContent:{
            filterTitle:'Filter List',
            filterButton:'Filter',
            actionButton:'Actions',
            applyButton:"Apply",
            Dashboard:{
                orderCard:{
                    title:"Orders",
                    footer:["Complete","Unfinished"],
                    totalCircle:"Total",
                    newCircle:"New"
                },
                numberCard:{
                    title:["My product","Managed product","Number of stores","Sales amount"]
                },
                logistics:{
                    title:"Logistics information",
                    columnsDot:["To be delivered","To be received","Received goods"],
                    received:"received",
                    delivered:"delivered",
                    receivedGoods:"received goods",
                    x:"Store Name",
                    y:"count"
                },
                afterSaleTable:{
                    title:"After sales information",
                    columns:["Name","State","Type"],
                    allButton:"All"
                },
                salesData:{
                    title:"Sales data",
                    x:"Date",
                    y:"Completed",
                },
                dialog:{
                    content:"Please go to Shopify to jump！",
                    okButton:'Determine',
                }
            },
            MyProducts:{
                searchText:"Enter product name...",
                filter:{
                    selects:[
                        {
                            title:"Status",
                            options:["All","Active","Draft"]
                        },
                        {
                            title:"Store URL",
                            options:[]
                        },
                        {
                            title:"Product Tag",
                            options:['OutOfStock','NoFulfilled']
                        },
                        {
                            title:"Sourcing Status",
                            options:['Sourced','Sourcing','Ask Sourcing']
                        }
                    ]
                },
                actions:["Sync Data"],
                table:{
                    columns:["Product","Product Status","Last 7 days sales","Store","Sourcing Status"],
                },
                detail:{
                    tag:{
                        OutOfStock:'Some variant(s) for this product are currently out of stock. You will be notified as soon as the product is back in stock if you have previously imported this product to your store. Check the variants tab below for which variant(s) are currently out of stock.',
                        NoFulfilled:'Some variant(s) for this product are currently no need to be fulfilled by FbAli. You could change the status to let FbAli handle these variant(s). Check the variants tab below for which variant(s) are currently no need to be fulfilled.'
                    },
                    description:{
                        title:"Please Note",
                        article:{
                            '1':"Quoted prices may change as logistics prices fluctuate, sometimes increasing, sometimes decreasing.",
                            '2':"Quotation is in USD.",
                            '3':"When a variant gets a quote, it means that your variant has been linked to our database and you can't directly modify the content of the variant, for example, if you get a quote for clothes and you change to shoes, then we will still fulfill in the clothes, resulting in the wrong shipment.",
                        },
                        statement:"We are not responsible for this situation!"
                    },
                    table:{
                        tooltip:{
                            OutOfStock:'The variant(s) highlighted in red are currently out of stock.',
                            NoFulfilled:'The variant(s) highlighted in purple currently do not need to be fulfilled by FbAli. You could change the status to let FbAli handle these variant(s). Check the variants tab below for which variant(s) currently do not need to be fulfilled.'
                        },
                        columns:["Variant","Sale Price","Product Cost","Service Fee","Shipping","Total Cost"],
                        select:{
                            countries:{
                                placeholder:'Select a country',
                                options:["United States","France","Germany","United Kingdom"]
                            }
                        }
                    }
                }
            },
            Orders:{
                headerExtra:["Top-Up","Credit Balance"],
                searchText:"Enter order number...",
                filter:{
                    ranges:['Date Range','Order Range'],
                    selects:[
                        {
                            title:"Order Status",
                            options:["All","Processing","Top-Up","Sourcing","Shipped","Cancel"]
                        },
                        {
                            title:"FbAli Payment",
                            options:["UnPaid","Paid","Refund","Closed"]
                        },
                        {
                            title:"Store",
                            options:[]
                        }
                    ]
                },
                actions:["Sync Data","Placing"],
                pageTabs:["Shopify","Woo Commerce"],
                pageTabExtra:{
                    switch:{
                        label:"Auto Fulfillment",
                        onText:"on",
                        offText:"off"
                    }
                },
                tableTabs:["All","Processing","Top-Up","Sourcing","Shipped","Cancel"],
                table:{
                    columns:["Order","Store","Date","Customer","FbAli Payment","Total","Order Status"]
                },
                detail:{
                    header:{
                        title:["Order No","Order Status","Create Time"],
                        afterSalesButton:"After Sales"
                    },
                    description: {
                        shipments:{
                            title:"Shipments",
                            items:["Tracking Number","Shipped Date","Carrier","Country","Parcel Attributes"]
                        },
                        items:{
                            title:"Items",
                            table:{
                                columns:["Images","Items","Status","Quantity","Price"]
                            }
                        },
                        total:{
                            title:"Total",
                            items:["Subtotal","item","Shipping","Discount","Tax","Total Cost"]
                        },
                        cost:{
                            title:"Cost",
                            items:["Product Price","Shipping","Service Price","Total Cost"]
                        },
                        percentage:{
                            items:["Percentage","Profits"]
                        }
                    },
                    dialog:{
                        title:"After Sales",
                        cancelButton:"Cancel",
                        okButton:"Determine",
                        form:{
                            labels:["Type","Mode","Product"],
                            modeSelect:{
                              options:["Resend","Refund","Other"]
                            },
                            rules:["Please select a after type","Please select a after mode","Please select a after product"]
                        },
                    }
                },
                dialog:{
                    title:"Top Up",
                    content:"Please contact the manager.",
                    okText:"OK"
                }
            },
            AfterSale:{
                searchText:"Enter order number...",
                filter:{
                    selects:[
                        {
                            title:"Status",
                            options:["All","Pending","In Process","Completed"]
                        },
                        {
                            title:"Store",
                            options:[]
                        }
                    ]
                },
                actions:["Completed","New Information"],
                tableTabs:["All","Pending","In Process","Completed"],
                table:{
                    columns:["Order","Product","Update Time","After Sales Mode","Status","Source","Timeline"],
                    operation:["Track"]
                },
                detail:{
                    leftCard:{
                        title:"After Sales Information",
                        items:["Order No", "After Sales Type", "After Sales Mode", "After Sales Products", "Creation Time"]
                    },
                    rightCard:{
                        forwardButton:"Forward",
                        completeButton:"Complete",
                        replayButton:"Reply",
                        customerTab:'Customer',
                        vendorTab:'Vendor',
                        sendErrorMsg:"Message sending failed,Click to resend."
                    }
                }
            },
            Store:{
                filter:{
                    extra:{
                        connectStoreButton:"Connect A Store"
                    }
                },
                table:{
                    columns:["Store Name","Store URL","Platform","Status","Operating"],
                    operation:["edit"]
                }
            },
            Bill:{
                searchText:"Enter flow number or order name...",
                filter:{
                    searchType:['Flow Number','Order Name'],
                    ranges:['Date Range'],
                    selects:[
                        {
                            title:"Type",
                            options:[]
                        },
                        {
                            title:"Country",
                            options:[]
                        },
                        {
                            title:"Store URL",
                            options:[]
                        },
                    ],
                    extra:{
                        exportButton:"Export"
                    }
                },
                pageTabs:["Transaction","Order Costs"],
                tables:{
                    '1':["Flow Number","Order Name","Type","Store URL","Previous Balance","Income/Expenses","Current Balance","Currency","Create Time","Remarks"],
                    '2':["Flow Number","Order Name","Store URL","Program","Country","Shipping Fee","Order Cost","Discount","Real Amount","Currency","Create Time"],
                }
            },
            Settings:{
                pageTabs:["Notification","Change Password","Personal Center"],
                notification:{
                    table:{
                        columns:["Store Name","Store URL","Platform","Auto Push","Notification Customer"],
                    }
                },
                changePassword:{
                    forms:["Original Password","New Password"],
                    confirmChangeButton:"Confirm Change"
                },
                personalCenter:{
                    personal:{
                        title:"Personal",
                        forms:["Name","Lastname","E-email","Telephone"],
                        changeEmailAddressButton:"Change Email Address",
                        storageButton:"Storage",
                    },
                    shops:{
                        title:'Shops',
                        table:{
                            columns:["Shop Name","Links"]
                        }
                    }
                }
            },
        },
    },
    de:{
        Header:{
            personalDropdown:'Abmeldung',
        },
        Side:{
            menus:[
                {
                    key:'/dashboard',
                    icon:<PieChartOutlined/>,
                    label:'Instrumententafel',
                    path:'/dashboard',
                    text:'Instrumententafel',
                },
                {
                    key:'/my-products',
                    icon:<ShoppingOutlined/>,
                    label:'Meine Produkte',
                    path:'/my-products',
                    text:'Meine Produkte',
                },
                {
                    key:'/orders',
                    icon:<BarChartOutlined/>,
                    label:'Aufträge',
                    path:'/orders',
                    text:'Aufträge',
                },
                {
                    key:'/after-sale',
                    icon:<UserOutlined/>,
                    label:'Nach dem Verkauf',
                    path:'/after-sale',
                    text:'Nach dem Verkauf',
                },
                {
                    key:'/store',
                    icon:<BankOutlined/>,
                    label:'Abspeichern',
                    path:'/store',
                    text:'Abspeichern',
                },
                {
                    key:'/bill',
                    icon:<AccountBookOutlined/>,
                    label:'Rechnung',
                    path:'/bill',
                    text:'Rechnung',
                },
                {
                    key:'/settings',
                    icon:<SettingOutlined/>,
                    label:'Einstellungen',
                    path:'/settings',
                    text:'Einstellungen',
                },
            ],
            detailMenus: [
                {
                    key:'/orders',
                    label: 'Bestelldetail',
                    path:'/order-detail',
                    text:'Bestelldetail',
                },
                {
                    key:'/after-sale',
                    label: 'Details nach dem Verkauf',
                    path:'/after-sale-detail',
                    text:'Details nach dem Verkauf',
                },
                {
                    key:'/my-products',
                    label: 'Mein Produktdetail',
                    path:'/my-product-detail',
                    text:'Mein Produktdetail',
                }
            ],
            extensionButton:'Erweiterung'
        },
        CommonDetail:{
            returnButton:'Rückgabe'
        },
        CommonContent:{
            filterTitle:"Filter Liste",
            filterButton:'Filter',
            actionButton:'Aktionen',
            applyButton:"Anwenden",
            Dashboard:{
                orderCard:{
                    title:"Bestellungen",
                    footer:["Abgeschlossen","Unvollständig"],
                    totalCircle:"Gesamt",
                    newCircle:"Neu"
                },
                numberCard:{
                    title:["Mein Produkt","Verwaltetes Produkt","Anzahl der Geschäfte","Verkaufsbetrag"]
                },
                logistics:{
                    title:"Logistikinformationen",
                    columnsDot:["Zu liefern","Zu empfangen","Empfangene Waren"],
                    received:"eingegangen",
                    delivered:"abgegeben",
                    receivedGoods:"Erhaltene Ware",
                    x:"Geschäftsname",
                    y:"Anzahl"
                },
                afterSaleTable:{
                    title:"Nachverkaufsinformationen",
                    columns:["Name","Status","Typ"],
                    allButton:"Alle"
                },
                salesData:{
                    title:"Verkaufsdaten",
                    x:"Datum",
                    y:"Abgeschlossen",
                },
                dialog:{
                    content:"Bitte gehen Sie zu Shopify, um zu springen!",
                    okButton:'Bestimmen',
                }
            },
            MyProducts:{
                searchText:"Produktnamen eingeben...",
                filter:{
                    selects:[
                        {
                            title:"Status",
                            options:["Alle","Aktiv","Entwurf"]
                        },
                        {
                            title:"Store-URL",
                            options:[]
                        },
                        {
                            title:"Produkt-Tag",
                            options:['Nicht vorrätig','NeinErfüllt']
                        },
                        {
                            title:"Beschaffungsstatus",
                            options:['Quellenangabe','Beschaffung','Ask Sourcing']
                        }
                    ]
                },
                actions:["Daten synchronisieren"],
                table:{
                    columns:["Produkt","Produktstatus","Verkäufe der letzten 7 Tage","Abspeichern","Beschaffungsstatus"],
                },
                detail:{
                    tag:{
                        OutOfStock:'Einige Variante(n) für dieses Produkt sind derzeit nicht lagernd. Sie werden benachrichtigt, sobald das Produkt wieder auf Lager ist, wenn Sie dieses Produkt zuvor in Ihren Shop importiert haben. Überprüfen Sie auf der Registerkarte "Varianten" unten, welche Variante(n) derzeit nicht vorrätig sind.',
                        NoFulfilled:'Einige Variante(n) für dieses Produkt müssen momentan nicht von FbAli erfüllt werden. Sie können den Status ändern, damit FbAli diese Variante(n) verarbeiten kann. Überprüfen Sie auf der Registerkarte "Varianten" unten, für welche Variante(n) derzeit keine Erfüllung erforderlich sind.'
                    },
                    description:{
                        title:"Bitte beachten Sie",
                        article:{
                            '1':"Die Angebotspreise können sich ändern, wenn die Logistikpreise schwanken, manchmal steigend, manchmal sinkend.",
                            '2':"Die Notierung ist in USD.",
                            '3':"Wenn eine Variante ein Angebot erhält, bedeutet dies, dass Ihre Variante mit unserer Datenbank verknüpft wurde und Sie den Inhalt der Variante nicht direkt ändern können, z. B. wenn Sie ein Angebot für Kleidung erhalten und zu Schuhen wechseln, dann werden wir die Kleidung immer noch liefern, was zu einer falschen Lieferung führt.",
                        },
                        statement:"Wir sind für diese Situation nicht verantwortlich!"
                    },
                    table:{
                        tooltip:{
                            OutOfStock:'Die rot markierte(n) Variante(n) sind derzeit nicht vorrätig.',
                            NoFulfilled:'Die violett markierte(n) Variante(n) muss derzeit nicht von FbAli erfüllt werden. Sie können den Status ändern, damit FbAli diese Variante(n) verarbeiten kann. Überprüfen Sie auf der Registerkarte "Varianten" unten, für welche Variante(n) derzeit nicht erfüllt werden müssen.'
                        },
                        columns:["Variante","Verkaufspreis","Produktkosten","Servicegebühr","Versand","Gesamtkosten"],
                        select:{
                            countries:{
                                placeholder:'Wählen Sie ein Land aus',
                                options:["USA","Frankreich","Deutschland","Vereinigtes Königreich"]
                            }
                        }
                    }
                }
            },
            Orders:{
                headerExtra:["Aufladen","Guthaben"],
                searchText:"Bestellnummer eingeben...",
                filter:{
                    ranges:['Datumsbereich','Bestellbereich'],
                    selects:[
                        {
                            title:"Bestellstatus",
                            options:["Alle","Bearbeitung","Aufladen","Beschaffung","Versendet","Storniert"]
                        },
                        {
                            title:"FbAli-Zahlung",
                            options:["Non payé","Payé","Remboursement","Geschlossen"]
                        },
                        {
                            title:"Abspeichern",
                            options:[]
                        }
                    ]
                },
                actions:["Daten synchronisieren","Einbau"],
                pageTabs:["Shopify","Woo Commerce"],
                pageTabExtra:{
                    switch:{
                        label:"Automatische Erfüllung",
                        onText:"auf",
                        offText:"aus"
                    }
                },
                tableTabs:["Alle","Bearbeitung","Aufladen","Beschaffung","Versendet","Storniert"],
                table:{
                    columns:["Bestellung","Abspeichern","Datum","Kunde","FbAli Zahlung","Gesamt","Bestellstatus"]
                },
                detail:{
                    header:{
                        title:["Bestell-Nr","Bestellstatus","Zeit erstellen"],
                        afterSalesButton:"Kundendienst"
                    },
                    description: {
                        shipments:{
                            title:"Verschiffungen",
                            items:["Sendungsverfolgungsnummer","Versanddatum","Träger","Land","Flurstücks-Attribute"]
                        },
                        items:{
                            title:"Artikel",
                            table:{
                                columns:["Bilder","Artikel","Status","Menge","Preis"]
                            }
                        },
                        total:{
                            title:"Gesamt",
                            items:["Zwischensumme","artikel","Versand","Rabatt","Steuer","Gesamtkosten"]
                        },
                        cost:{
                            title:"Kosten",
                            items:["Produktpreis","Versand","Service-Preis","Gesamtkosten"]
                        },
                        percentage:{
                            items:["Prozentsatz","Gewinne"]
                        }
                    },
                    dialog:{
                        title:"Kundendienst",
                        cancelButton:"Abbrechen",
                        okButton:"Bestimmen",
                        form:{
                            labels:["Art","Modus","Produkt"],
                            modeSelect:{
                                options:["Wieder senden","Erstattung","Andere"]
                            },
                            rules:["Bitte wählen Sie einen Nach-Typ","Bitte wählen Sie einen After-Modus","Bitte wählen Sie ein After-Produkt"]
                        }
                    }
                },
                dialog:{
                    title:"Aufladen",
                    content:"Bitte wenden Sie sich an den Manager.",
                    okText:"OKAY"
                }
            },
            AfterSale:{
                searchText:"Bestellnummer eingeben...",
                filter:{
                    selects:[
                        {
                            title:"Status",
                            options:["Alle","Anhängig","In Bearbeitung","Abgeschlossen"]
                        },
                        {
                            title:"Abspeichern",
                            options:[]
                        }
                    ]
                },
                actions:["Abgeschlossen","Neue Informationen"],
                tableTabs:["Alle","Anhängig","In Bearbeitung","Abgeschlossen"],
                table:{
                    columns:["Bestellung","Produkt","Aktualisierungszeit","After-Sales-Modus","Status","Quelle","Zeitstrahl"],
                    operation:["Spur"]
                },
                detail:{
                    leftCard:{
                        title:"After-Sales-Informationen",
                        items:["Bestell-Nr", "After-Sales-Typ", "After-Sales-Modus", "After-Sales-Produkte", "Erstellungszeit"]
                    },
                    rightCard:{
                        forwardButton:"Vorwärts",
                        completeButton:"Vollständig",
                        replayButton:"Antwort",
                        customerTab:"Kunde",
                        vendorTab: "Verkäufer",
                        sendErrorMsg: "Nachrichtenversand fehlgeschlagen,Klicken Sie zum erneuten Senden."
                    }
                }
            },
            Store:{
                filter:{
                    extra:{
                        connectStoreButton:"Verbinden Sie einen Shop"
                    }
                },
                table:{
                    columns:["Name des Geschäfts","Store-URL","Bahnsteig","Status","Bedienung"],
                    operation:["redigieren"]
                }
            },
            Bill:{
                searchText:"Geben Sie die Durchflussnummer oder den Bestellnamen ein...",
                filter:{
                    searchType:['Durchflussnummer','Name der Bestellung'],
                    ranges:['Datumsbereich'],
                    selects:[
                        {
                            title:"Art",
                            options:[]
                        },
                        {
                            title:"Land",
                            options:[]
                        },
                        {
                            title:"Store-URL",
                            options:[]
                        },
                    ],
                    extra:{
                        exportButton:"Exportieren"
                    }
                },
                pageTabs:["Transaktion","Bestellkosten"],
                tables:{
                    '1':["Durchflussnummer","Name der Bestellung","Art","Store-URL","Vorheriger Saldo","Einnahmen/Ausgaben","Aktueller Saldo","Währung","Zeit erstellen","Bemerkungen"],
                    '2':["Durchflussnummer","Name der Bestellung","Store-URL","Programm","Land","Versandkosten","Bestellkosten","Rabatt","Tatsächlicher Betrag","Währung","Zeit erstellen"],
                },
            },
            Settings:{
                pageTabs:["Benachrichtigung","Passwort ändern","Persönliches Zentrum"],
                notification:{
                    table:{
                        columns:["Name des Geschäfts","Store-URL","Bahnsteig","Automatischer Push","Benachrichtigung Kunde"],
                    }
                },
                changePassword:{
                    forms:["Ursprüngliches Passwort","Neues Passwort"],
                    confirmChangeButton:"Änderung bestätigen"
                },
                personalCenter:{
                    personal:{
                        title:"Persönlich",
                        forms:["Name","Nachname","E-Mail","Telefon"],
                        changeEmailAddressButton:"E-Mail-Adresse ändern",
                        storageButton:"Lagerung",
                    },
                    shops:{
                        title:'Geschäfte',
                        table:{
                            columns:["Name des Shops","Verknüpfungen"]
                        }
                    }
                }
            },
        },
    },
    fr:{
        Header:{
            personalDropdown:'Déconnexion',
        },
        Side:{
            menus:[
                {
                    key:'/dashboard',
                    icon:<PieChartOutlined/>,
                    label:'Tableau de bord',
                    path:'/dashboard',
                    text:'Tableau de bord',
                },
                {
                    key:'/my-products',
                    icon:<ShoppingOutlined/>,
                    label:'Mes produits',
                    path:'/my-products',
                    text:'Mes produits',
                },
                {
                    key:'/orders',
                    icon:<BarChartOutlined/>,
                    label:'Ordres',
                    path:'/orders',
                    text:'Ordres',
                },
                {
                    key:'/after-sale',
                    icon:<UserOutlined/>,
                    label:'Après-vente',
                    path:'/after-sale',
                    text:'Après-vente',
                },
                {
                    key:'/store',
                    icon:<BankOutlined/>,
                    label:'Boutique',
                    path:'/store',
                    text:'Boutique',
                },
                {
                    key:'/bill',
                    icon:<AccountBookOutlined/>,
                    label:'Facture',
                    path:'/bill',
                    text:'Facture',
                },
                {
                    key:'/settings',
                    icon:<SettingOutlined/>,
                    label:'Paramètres',
                    path:'/settings',
                    text:'Paramètres',
                },
            ],
            detailMenus:[
                {
                    key:'/orders',
                    label: 'Détail de la commande',
                    path:'/order-detail',
                    text:'Détail de la commande',
                },
                {
                    key:'/after-sale',
                    label: 'Détail après-vente',
                    path:'/after-sale-detail',
                    text:'Détail après-vente',
                },
                {
                    key:'/my-products',
                    label: 'Détail de mon produit',
                    path:'/my-product-detail',
                    text:'Détail de mon produit',
                }
            ],
            extensionButton:'Extension'
        },
        CommonDetail:{
            returnButton:'Rendre'
        },
        CommonContent:{
            filterTitle:'Liste des filtres',
            filterButton:'Filtre',
            actionButton:'Actions',
            applyButton:"Appliquer",
            Dashboard:{
                orderCard:{
                    title:"Commandes",
                    footer:["Complet","Inachevé"],
                    totalCircle:"Total",
                    newCircle:"Nouveau"
                },
                numberCard:{
                    title:["Mon produit","Produit géré","Nombre de magasins","Montant des ventes"]
                },
                logistics:{
                    title:"Informations logistiques",
                    columnsDot:["À livrer","À recevoir","Marchandises reçues"],
                    received:"reçu",
                    delivered:"livré",
                    receivedGoods:"marchandises reçues",
                    x:"Nom du magasin",
                    y:"Compter"
                },
                afterSaleTable:{
                    title:"Informations après-vente",
                    columns:["Nom","État","Type"],
                    allButton:"Toute"
                },
                salesData:{
                    title:"Données de vente",
                    x:"Date",
                    y:"Terminé",
                },
                dialog:{
                    content:"S’il vous plaît allez sur Shopify pour sauter!",
                    okButton:'Déterminer',
                }
            },
            MyProducts:{
                searchText:"Entrez le nom du produit...",
                filter:{
                    selects:[
                        {
                            title:"Statut",
                            options:["Tous","Actif","Brouillon"]
                        },
                        {
                            title:"URL de la boutique",
                            options:[]
                        },
                        {
                            title:"Balise de produit",
                            options:['Rupture de stock','Non Rempli']
                        },
                        {
                            title:"Statut de l’approvisionnement",
                            options:['Sourcé','Approvisionnement','Demandez à Sourcing']
                        }
                    ]
                },
                actions:["Synchroniser les données"],
                table:{
                    columns:["Produit","Statut du produit","Ventes des 7 derniers jours","Boutique","Statut d'approvisionnement"],
                },
                detail:{
                    tag:{
                        OutOfStock:'Certaines variantes de ce produit sont actuellement en rupture de stock. Vous serez averti dès que le produit sera de nouveau en stock si vous avez déjà importé ce produit dans votre boutique. Consultez l’onglet Variantes ci-dessous pour quelle(s) variante(s) est(sont) actuellement en rupture de stock.',
                        NoFulfilled:'Certaines variantes de ce produit n’ont actuellement pas besoin d’être remplies par FbAli. Vous pouvez modifier le statut pour laisser FbAli gérer ces variantes. Consultez l’onglet Variantes ci-dessous pour quelle(s) variante(s) n’a actuellement pas besoin d’être satisfaite(s).'
                    },
                    description:{
                        title:"Veuillez noter",
                        article:{
                            '1':"Les prix indiqués peuvent changer en fonction des fluctuations des prix logistiques, augmentant parfois, diminuant parfois.",
                            '2':"La citation est en USD.",
                            '3':"Lorsqu'une variante est cotée, cela signifie que votre variante a été liée à notre base de données et vous ne pouvez pas modifier directement le contenu de la variante, par exemple, si vous obtenez une citation pour des vêtements et que vous passez aux chaussures, alors nous expédierons toujours les vêtements, ce qui entraînera un envoi incorrect.",
                        },
                        statement:"Nous ne sommes pas responsables de cette situation !"
                    },
                    table:{
                        tooltip:{
                            OutOfStock:'La ou les variantes surlignées en rouge sont actuellement en rupture de stock.',
                            NoFulfilled:'La ou les variantes surlignées en violet n’ont actuellement pas besoin d’être remplies par FbAli. Vous pouvez modifier le statut pour laisser FbAli gérer ces variantes. Consultez l’onglet Variantes ci-dessous pour quelle(s) variante(s) n’a actuellement pas besoin d’être remplie(s).'
                        },
                        columns:["Variante","Prix de vente","Coût du produit","Frais de service","Expédition","Coût total"],
                        select:{
                            countries:{
                                placeholder:'Sélectionnez un pays',
                                options:["États-Unis","France","Allemagne","Royaume-Uni"]
                            }
                        }
                    }
                }
            },
            Orders:{
                headerExtra:["Recharger","Solde créditeur"],
                searchText:"Entrez le numéro de commande...",
                filter:{
                    ranges:['Plage de dates','Plage de commandes'],
                    selects:[
                        {
                            title:"Statut de la commande",
                            options:["Tous","Traitement","Recharge","Approvisionnement","Expédié","Annulé"]
                        },
                        {
                            title:"Paiement FbAli",
                            options:["Tous","Payé","Remboursé","Fermée"]
                        },
                        {
                            title:"Boutique",
                            options:[]
                        }
                    ]
                },
                actions:["Synchroniser les données","Passation"],
                pageTabs:["Shopify","Woo Commerce"],
                pageTabExtra:{
                    switch:{
                        label:"Automatique",
                        onText:"activé",
                        offText:"désactivé"
                    }
                },
                tableTabs:["Tous","Traitement","Recharge","Approvisionnement","Expédié","Annulé"],
                table:{
                    columns:["Commande","Boutique","Date","Client","Paiement FbAli","Total","Statut de la commande"]
                },
                detail:{
                    header:{
                        title:["Numéro de commande","Statut de la commande","Date de création"],
                        afterSalesButton:"Après-vente"
                    },
                    description: {
                        shipments:{
                            title:"Expéditions",
                            items:["Numéro de suivi","Date d'expédition","Transporteur","Pays","Attributs du colis"]
                        },
                        items:{
                            title:"Articles",
                            table:{
                                columns:["Images","Articles","Statut","Quantité","Prix"]
                            }
                        },
                        total:{
                            title:"Total",
                            items:["Sous-total","article","Expédition","Remise","Taxe","Coût total"]
                        },
                        cost:{
                            title:"Coût",
                            items:["Prix du produit","Expédition","Prix du service","Coût total"]
                        },
                        percentage:{
                            items:["Pourcentage","Bénéfices"]
                        }
                    },
                    dialog:{
                        title:"Après-vente",
                        cancelButton:"Annuler",
                        okButton:"Déterminer",
                        form:{
                            labels:["Type","Mode","Produit"],
                            modeSelect:{
                                options:["Renvoyer","Remboursement","Autre"]
                            },
                            rules:["Veuillez sélectionner un type après","Veuillez sélectionner un mode après","Veuillez sélectionner un produit après"]
                        }
                    }
                },
                dialog:{
                    title:"Recharger",
                    content:"Veuillez contacter le responsable.",
                    okText:"D’ACCORD"
                }
            },
            AfterSale:{
                searchText:"Entrez le numéro de commande...",
                filter:{
                    selects:[
                        {
                            title:"Statut",
                            options:["Tous","En attente","En cours","Terminé"]
                        },
                        {
                            title:"Boutique",
                            options:[]
                        }
                    ]
                },
                actions:["Terminé","Nouvelle information"],
                tableTabs:["Tous","En attente","En cours","Terminé"],
                table:{
                    columns:["Commande","Produit","Heure de mise à jour","Mode après-vente","Statut","Source","Chronologie"],
                    operation:["Suivre"]
                },
                detail:{
                    leftCard:{
                        title:"Informations après-vente",
                        items:["Numéro de commande", "Type après-vente", "Mode après-vente", "Produits après-vente", "Heure de création"]
                    },
                    rightCard:{
                        forwardButton:"Transférer",
                        completeButton:"Terminer",
                        replayButton:"Répondre",
                        customerTab:'Client',
                        vendorTab:'Vendeur',
                        sendErrorMsg:"Échec de l'envoi du message, cliquez pour renvoyer."
                    }
                }
            },
            Store:{
                filter:{
                    extra:{
                        connectStoreButton:"Connecter une boutique"
                    }
                },
                table:{
                    columns:["Nom de la boutique","URL de la boutique","Plateforme","Statut","Fonctionnement"],
                    operation:["modifier"]
                }
            },
            Bill:{
                searchText:"Entrez le numéro de flux ou le nom de la commande...",
                filter:{
                    searchType:['Numéro de flux','Nom de la commande'],
                    ranges:['Plage de dates'],
                    selects:[
                        {
                            title:"Type",
                            options:[]
                        },
                        {
                            title:"Pays",
                            options:[]
                        },
                        {
                            title:"URL de la boutique",
                            options:[]
                        },
                    ],
                    extra:{
                        exportButton:"Exporter"
                    }
                },
                pageTabs:["Transaction","Coûts de commande"],
                tables:{
                    '1':["Numéro de flux","Nom de la commande","Type","URL de la boutique","Solde précédent","Recettes/Dépenses","Solde actuel","Devise","Heure de création","Remarques"],
                    '2':["Numéro de flux","Nom de la commande","URL de la boutique","Programme","Pays","Frais d'expédition","Coût de la commande","Remise","Montant réel","Devise","Heure de création"],
                }
            },
            Settings:{
                pageTabs:["Notification","Modifier le mot de passe","Centre personnel"],
                notification:{
                    table:{
                        columns:["Nom de la boutique","URL de la boutique","Plateforme","Poussée automatique","Notification client"],
                    }
                },
                changePassword:{
                    forms:["Mot de passe d'origine","Nouveau mot de passe"],
                    confirmChangeButton:"Confirmer le changement"
                },
                personalCenter:{
                    personal:{
                        title:"Personnel",
                        forms:["Prénom","Nom","E-mail","Téléphone"],
                        changeEmailAddressButton:"Changer l'adresse e-mail",
                        storageButton:"Stockage",
                    },
                    shops:{
                        title:'Boutiques',
                        table:{
                            columns:["Nom de la boutique","Liens"]
                        }
                    }
                }
            },
        },
    }
}

export default I18N;
