import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Empty, Flex, Layout, Modal, Spin, Table, Tooltip} from "antd";
import './Dashboard.scss'
import {createCircle, createColumn, createLine} from "../../../commons/graphic";
import useStore from "../../../store/store";
import {baseChartIndex, shopifyApi} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {getCookies, setCookies} from "../../../utils/cookies";
import uuid from "../../../utils/uuid";
import {storeUrlFormat} from "../../../utils/stringUtils";
import {afterSalesList} from "../../../api/after";
import {
    getStoreList,
    getStoreProductList
} from "../../../api/product";
import { getOrderList } from "../../../api/orders";

let websocket = null;
export default function  Dashboard(){
    const [scrollHeight,setScrollHeight] = useState(0);
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [tableData,setTableData] = useState([]);
    const [myProducts,setMyProducts] = useState();
    const [managedNum,setManageNum] = useState();
    const [storeNum,setStoreNum] = useState();
    const [salesAmount,setSalesAmount] = useState();
    const [complete,setComplete] = useState();
    const [unFinished,setUnFinished] = useState();
    const [orderNum,setOrderNum] = useState();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const [videoLoading,setVideoLoading] = useState(true);
    const [columnsIsEmpty,setColumnIsEmpty] = useState(false);
    const [lineIsEmpty,setLineIsEmpty] = useState(false);
    useEffect(() => {
        const tableDom = document.getElementById("after-sale-table");
        setScrollHeight(tableDom.clientHeight - 200);
        window.removeEventListener('resize',handleResize);
        window.addEventListener('resize',handleResize);
        return () => {
            window.removeEventListener('resize',handleResize);
        }
    }, []);


    useEffect(()=>{
        getAfterSalesList()
        getStores()
        getProducts()
        getOrders()
    }, [])

    const getAfterSalesList = () => {
        afterSalesList({pageNum: 1, pageSize: 10}).then(res => {
            setTableData(res.data.list.map((item, index) => {
                item.key = index;
                item.index = index;
                return item;
            }));
        }).catch(err => {
            console.error(err);
        })
    }

    const getOrders = ()=>{
        getOrderList({pageNum: 1, pageSize: 1}).then(res=>{
            setOrderNum(res.data.total)
        }).catch(err => console.error(err))
    }

    const getProducts = ()=>{
        getStoreProductList({pageNum: 1, pageSize: 1}).then(res=>{
            setMyProducts(res.data.total)
        }).catch(err => console.error(err))
        getStoreProductList({pageNum: 1, pageSize: 1, sourceStatus: "1"}).then(res=>{
            setManageNum(res.data.total)
        }).catch(err => console.error(err))
    }

    const getStores = () => {
        getStoreList({pageNum: 1, pageSize: 1}).then((res) => {
            setStoreNum(res.data.total)
        }).catch((err) => {
            console.error(err);
        })
    }

    function handleResize() {
        const tableDom = document.getElementById("after-sale-table");
        setScrollHeight(tableDom.clientHeight - 200);
    }

    return (
        <Spin style={{width:'100%',height:'100%'}} spinning={loading}>
            <Layout className="dashboard-container">
                <Flex gap={15} align="center" justify="center" className="w100 h100">
                    <Flex gap={15} vertical="vertical" style={{width:'100%',height:'100%'}}>
                        <Flex gap={15}>
                            <Flex className="h100" style={{width:'100%'}} vertical="vertical" gap={15}>
                                <Flex gap={15} align="center" className="w100" style={{height:'149px'}}>
                                    <Card className="number-card" title={
                                        <Flex align="center" justify="left" gap={5}>
                                            <span className="order-dot"></span>
                                            <Tooltip title={currentLocaleData.CommonContent.Dashboard.orderCard.title}
                                                     placement="bottomLeft">
                                                <span>{currentLocaleData.CommonContent.Dashboard.orderCard.title}</span>
                                            </Tooltip>
                                        </Flex>
                                    }>
                                        <Tooltip
                                            title={orderNum}
                                            placement="bottom"
                                        >
                                            <h2 style={{textAlign: 'center'}}>{orderNum}</h2>
                                        </Tooltip>
                                    </Card>
                                    <Card className="number-card" title={
                                        <Flex align="center" justify="left" gap={5}>
                                            <img width="20px" height="20px" src="/icons/user.png"/>
                                            <Tooltip title={currentLocaleData.CommonContent.Dashboard.numberCard.title[0]}
                                                     placement="bottomLeft">
                                                <span>{currentLocaleData.CommonContent.Dashboard.numberCard.title[0]}</span>
                                            </Tooltip>
                                        </Flex>
                                    }>
                                        <Tooltip
                                            title={myProducts}
                                            placement="bottom"
                                        >
                                            <h2 style={{textAlign: 'center'}}>{myProducts}</h2>
                                        </Tooltip>
                                    </Card>
                                    <Card className="number-card" title={
                                        <Flex align="center" justify="left" gap={5}>
                                            <img width="20px" height="20px" src="/icons/manage.png"/>
                                            <Tooltip
                                                title={currentLocaleData.CommonContent.Dashboard.numberCard.title[1]}
                                                placement="bottomLeft"
                                            >
                                                <span>{currentLocaleData.CommonContent.Dashboard.numberCard.title[1]}</span>
                                            </Tooltip>
                                        </Flex>
                                    }>
                                        <Tooltip
                                            title={managedNum}
                                            placement="bottom"
                                        >
                                            <h2 style={{textAlign: 'center'}}>{managedNum}</h2>
                                        </Tooltip>
                                    </Card>
                                    <Card className="number-card" title={
                                        <Flex align="center" justify="left" gap={5}>
                                            <img width="20px" height="20px" src="/icons/store.png"/>
                                            <Tooltip
                                                title={currentLocaleData.CommonContent.Dashboard.numberCard.title[2]}
                                                placement="bottomLeft"
                                            >
                                                <span>{currentLocaleData.CommonContent.Dashboard.numberCard.title[2]}</span>
                                            </Tooltip>
                                        </Flex>
                                    }>
                                        <Tooltip
                                            title={storeNum}
                                            placement="bottom"
                                        >
                                            <h2 style={{textAlign: 'center'}}>{storeNum}</h2>
                                        </Tooltip>
                                    </Card>
                                    <Card className="number-card" title={
                                        <Flex align="center" justify="left" gap={5}>
                                            <img width="20px" height="20px" src="/icons/money.png"/>
                                            <Tooltip
                                                title={currentLocaleData.CommonContent.Dashboard.numberCard.title[3]}
                                                placement="bottomLeft"
                                            >
                                                <span>{currentLocaleData.CommonContent.Dashboard.numberCard.title[3]}</span>
                                            </Tooltip>
                                        </Flex>
                                    }>
                                        <Tooltip
                                            title={salesAmount}
                                            placement="bottom"
                                        >
                                            <h2 style={{textAlign: 'center'}}>{salesAmount}</h2>
                                        </Tooltip>
                                    </Card>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex gap={15} align="center" style={{height: 'calc(100% - 149px)'}}>
                            <Flex id="after-sale-table" className="after-sale-table" vertical="vertical" align="center"
                                  gap={15}>
                                <Flex className="after-sale-card-title" align="left" gap={5} vertical="vertical">
                                    <Flex align="center" justify="left" gap={15}>
                                        <span className="after-sale-dot"></span>
                                        <Tooltip
                                            title={currentLocaleData.CommonContent.Dashboard.afterSaleTable.title}
                                            placement="bottomLeft"
                                        >
                                            <h3>{currentLocaleData.CommonContent.Dashboard.afterSaleTable.title}</h3>
                                        </Tooltip>
                                    </Flex>
                                    <Flex align="center" justify="right">
                                        <Button type="link" style={{lineHeight:'25px'}} onClick={() => {navigate('/after-sale')}}>{currentLocaleData.CommonContent.Dashboard.afterSaleTable.allButton}</Button>
                                    </Flex>
                                </Flex>
                                <Table
                                    style={{height: 'calc(100% - 74px)'}}
                                    scroll={{y: scrollHeight}}
                                    columns={[
                                        {
                                            title: currentLocaleData.CommonContent.Dashboard.afterSaleTable.columns[0],
                                            dataIndex:'orderId',ellipsis:{showTitle:false},render:(_,record) => (
                                                <Tooltip title={_} placement="bottomLeft">{_}</Tooltip>
                                            )},
                                        {
                                            title:currentLocaleData.CommonContent.Dashboard.afterSaleTable.columns[1],
                                            dataIndex:'refundStatus',ellipsis:{showTitle: false},render:(_,record) => (
                                                <Tooltip title={_} placement="bottomLeft">{_}</Tooltip>
                                            )},
                                        {
                                            title:currentLocaleData.CommonContent.Dashboard.afterSaleTable.columns[2],
                                            dataIndex:'refundMode',ellipsis:{showTitle:false},render:(_,record) => (
                                                <Tooltip title={_} placement="bottomLeft">{_}</Tooltip>
                                            )},
                                    ]}
                                    dataSource={tableData}
                                >
                                </Table>
                            </Flex>
                            <div style={{
                                width: 'calc(100% - 45px)',
                                height: '100%',
                                background: '#fff',
                                borderRadius: '10px',
                                boxShadow: '0 1px 14px 0 rgba(0,0,0,.14)'
                            }}>
                                <Spin spinning={videoLoading}>
                                    <iframe className="video-box" width="100%" height="100%"
                                            src="https://www.youtube.com/embed/X2hknYbBfPg"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            aria-errormessage="too long to load www.youtube.com"
                                            onLoad={() => {
                                                setVideoLoading(false);
                                            }}
                                    />
                                </Spin>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
            </Layout>
        </Spin>
    )
};
