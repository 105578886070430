import {Button} from "antd";
import CommonContent from "./CommonContent";
import uuid from "../utils/uuid";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import useStore from "../store/store";

export default function CommonDetail(props){
    const navigate = useNavigate();
    const currentLocaleData = useStore(state => state.currentLocaleData);
    return (
        <CommonContent
            loading={props.loading}
            filterable={props.filter || false}
            extra={props.extra}
            headerButton={[
                <Button
                    onClick={() => {navigate(-1);}}
                    style={{borderColor:'rgb(239,111,56)',color:'rgb(239,111,56)'}}
                    icon={<ArrowLeftOutlined/>} key={uuid()}
                >
                    {currentLocaleData.CommonDetail.returnButton}
                </Button>
            ]}
        >
            {props.children}
        </CommonContent>
    )
}
