import {Col, Row, Dropdown, Button, Avatar, Flex} from "antd";
import {DownOutlined, LogoutOutlined} from '@ant-design/icons'
import './Header.scss'
import Icon from "../../commons/Icon";
import useStore from "../../store/store";
import {removeAllCookies} from "../../utils/cookies";
import {findUserInfo, logout} from "../../api/user";
import {getToken, removeToken} from "../../utils/auth";
import {useEffect, useState} from "react";
import {getBaseMonitor} from "../../api/wifi";
import wifi4 from "../../assets/home/wifi4.png"
import wifi3 from "../../assets/home/wifi3.png"
import wifi2 from "../../assets/home/wifi2.png"
import wifi1 from "../../assets/home/wifi1.png"
import wifi0 from "../../assets/home/wifi0.png"
import {LocaleDropdown} from "../../commons/enum";
import I18N from "../../commons/i18n";

let wifiTask = null;
export default function Header(props) {
    const email = useStore(state => state.email);
    const avatar = useStore(state => state.avatar);
    const updateAvatar = useStore(state=>state.updateAvatar);
    const updateEmail = useStore(state=>state.updateEmail);
    const updateUID = useStore(state=>state.updateUID);
    const updateName = useStore(state=>state.updateName);
    const [realTime,setRealTime] = useState();
    const [currentLocale,setCurrentLocale] = useState(LocaleDropdown['1']);
    const setCurrentLocaleData = useStore(state => state.setCurrentLocaleData);
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const updateBalance = useStore(state=>state.updateBalance);
    const updateAutoProcess = useStore(state=>state.updateAutoProcess);
    const isAuth = useStore(state => state.isAuth);
    const menuProps = {
        items:[
            {
                label: 'English',
                key: '1',
                icon: <Icon type="English"/>,
            },
            {
                label: 'German',
                key: '2',
                icon: <Icon type="German"/>,
            },
            {
                label: 'French',
                key: '3',
                icon: <Icon type="French"/>,
            },
        ],
    };
    useEffect(() => {
        if(isAuth){
            wifiSetInterval();
            findUserInfo().then(res => {
                localStorage.setItem("uid", res.data.id)
                updateEmail(res.data.email);
                updateUID(res.data.id);
                updateName(res.data.username);
                updateAvatar(res.data.avatar);
                updateAutoProcess(res.data.autoProcess);
                updateBalance(res.data.balance);
            }).catch(err => console.error(err));
        }
        return () => {
            wifiTask && clearInterval(wifiTask);
            wifiTask = null;
        }
    }, [isAuth]);
    const wifiSetInterval = () => {
        handleRequestWifi();
        wifiTask = setInterval(handleRequestWifi, 45000);
    }
    const handleMenuClick = (e) => {
        setCurrentLocale(LocaleDropdown[e.key]);
        switch (e.key) {
            case '1':
                setCurrentLocaleData({...I18N.en});
                break;
            case '2':
                setCurrentLocaleData({...I18N.de});
                break;
            case '3':
                setCurrentLocaleData({...I18N.fr});
                break;
            case '4':
                handleLogout();
                break
            default:
                break;
        }
    };

    const handleLogout = () => {
        removeToken();
        localStorage.clear()
        removeAllCookies();
        clearInterval(wifiTask);
        wifiTask = null;
        window.open('/login', '_self');
    }

    const handleRequestWifi = () => {
        const startTime = new Date();
        getBaseMonitor().then(res => {
            const endTime = new Date();
            setRealTime((endTime.getTime() - startTime.getTime()));
        }).catch(err => console.error(err))
    }
    return (
        <Row style={{height:'100%'}}>
            <Col className="logo-col" xs={4}>
                <a href="/dashboard"><img src="/logo.png" alt="FbAli"/></a>
            </Col>
            <Col className="header-btn-group" xs={20}>
                <Flex align="center">
                    {
                        realTime &&
                        <>
                            <Flex align="center" gap={5} style={{cursor: 'pointer',padding:'4px 15px'}} onClick={handleRequestWifi}>
                                <img style={realTime > 0 && realTime <= 500 ? {} : {display: 'none'}}
                                     src={wifi4} width="25px" height="25px"/>
                                <img style={realTime > 500 && realTime <= 1000 ? {} : {display: 'none'}}
                                     src={wifi3} width="25px" height="25px"/>
                                <img style={realTime > 1000 && realTime <= 1500 ? {} : {display: 'none'}}
                                     src={wifi2} width="25px" height="25px"/>
                                <img style={realTime > 1500 && realTime <= 2000 ? {} : {display: 'none'}}
                                     src={wifi1} width="25px" height="25px"/>
                                <img style={realTime > 2000 || realTime === 0 ? {} : {display: 'none'}}
                                     src={wifi0} width="25px" height="25px"/>
                                <span style={{height: '25px', lineHeight: '25px'}}>{realTime}/ms</span>
                            </Flex>
                            <div style={{width: '1px', height: '30px', background: '#dddada'}}></div>
                        </>
                    }
                    <Dropdown menu={{...menuProps,onClick:handleMenuClick}} trigger="click">
                        <Button>
                            <Flex align="center" gap={2}>
                                {currentLocale?Object.values(currentLocale)[0]:Object.values(LocaleDropdown['1'])[0]}
                                {currentLocale?Object.keys(currentLocale)[0]:Object.keys(LocaleDropdown['1'])[0]}
                                <DownOutlined/>
                            </Flex>
                        </Button>
                    </Dropdown>
                    <div style={{width: '1px', height: '30px', background: '#dddada'}}></div>
                    <Dropdown trigger="click" menu={{
                        items: [{
                            label: currentLocaleData.Header.personalDropdown,
                            key: '4',
                            icon: <LogoutOutlined/>,
                        }],
                        onClick: handleMenuClick
                    }}>
                        <Button style={{height: 'auto', display: "flex", alignItems: "center"}}>
                            <Avatar
                                src={avatar || "/avatar.png"}
                                size="default"/>
                            <span style={{marginLeft: '5px'}}>{email}</span>
                            <DownOutlined/>
                        </Button>
                    </Dropdown>

                </Flex>
            </Col>
        </Row>
    )
}
