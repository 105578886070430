export const DateFormat = () => {
    const date = new Date();
    const y = String(date.getFullYear());
    const m = String(date.getMonth() + 1);
    const d = String(date.getDate());
    const H = String(date.getHours());
    const M = String(date.getMinutes());
    const S = String(date.getSeconds());
    return y + '-' + StringFormat(m) + '-' + StringFormat(d) + ' ' + StringFormat(H) + ':' + StringFormat(M) + ':' + StringFormat(S);
}

export const DateFormatFile = () => {
    const date = new Date();
    const y = String(date.getFullYear());
    const m = String(date.getMonth() + 1);
    const d = String(date.getDate());
    const H = String(date.getHours());
    const M = String(date.getMinutes());
    const S = String(date.getSeconds());
    return y + '_' + StringFormat(m) + '_' + StringFormat(d) + '_' + StringFormat(H) + '_' + StringFormat(M) + '_' + StringFormat(S);
}

const StringFormat = (str) => {
    return (str.length===2?str:`0${str}`);
}
