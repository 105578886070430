import request from '../utils/request'

export function shopList(params) {
  return request({
    url: '/store/list',
    method: 'get',
    params
  })
}

export function connectStore(data) {
  return request({
    url: '/l',
    method: 'get',
    params: data
  })
}

export function testStoreConnect(params) {
  return request({
    url: '/ts',
    method: 'post',
    data: params
  })
}

export function editStore(id, params) {
  return request({
    url: `/store/${id}/edit`,
    method: 'patch',
    data: params
  })
}

export function shopPush(params) {
  return request({
    url: '/shopify/index/push',
    method: 'get',
    params
  })
}

export function c_name(id, params) {
  return request({
    url: `/store/${id}/edit`,
    method: 'patch',
    data: params
  })
}

export function checkShop(params) {
  return request({
    url: '/store/shopify/check',
    method: 'get',
    params
  })
}

export function shopStoreSaved(params) {
  return request({
    url: '/store/connect',
    method: 'post',
    data: params
  })
}
