import {useEffect, useRef, useState} from "react";
import {
    Avatar,
    Button,
    Card,
    Flex, Form,
    Image,
    message, Modal,
    Select,
    Spin,
    Tabs,
    Typography, Upload,
} from "antd";
import CommonDetail from "../../../components/CommonDetail";
import {afterSalesChanngedStatus, afterSalesDetail} from "../../../api/after";
import {useParams} from "react-router-dom";
import io from 'socket.io-client'
import {getToken} from "../../../utils/auth";
import RichText from "../../../components/RichText";
import uuid from "../../../utils/uuid";
import {WarningOutlined} from "@ant-design/icons";
import useStore from "../../../store/store";
import {DateFormat} from "../../../utils/DateUtils";
import {getBase64} from "../../../utils/fileUtils";
import {uploadStatic} from "../../../api/product";

let sc = null;
let autoClose = null;
let reConnectCount = 0;
let cacheObj = [];

function getUniqueId() {
    let random = Math.random().toString(16)
    random = random.split('.')[1]
    return new Date().getTime().toString(16) + random
}

function getTimeString(){
    const date = new Date()
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    const H = date.getHours()
    const M = date.getMinutes()
    const S = date.getSeconds()
    const FormatDate = y + '/' + m + '/' + d + ' ' + H + ':' + M + ':' + S
    return FormatDate
}

const sendIndexMap = new Map()
let imgList = []
export default function AfterSaleDetail(){
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [tableData,setTableData] = useState();
    const [completedDialog,setCompletedDialog] = useState(false);
    const contentRef = useRef();
    const editorRef = useRef(null);
    const name = useStore(state => state.name);
    const avatar = useStore(state => state.avatar);
    const userId = useStore(state => state.uid);
    const messageBoxRef = useRef(null);
    const [closeType,setCloseType] = useState('3');// 1:超时5分钟断开，2:组件销毁断开 3:服务器断开
    const [uploadFile,setUploadFile] = useState(null);
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [sendEnd,setSendEnd] = useState(true);
    const [isUpload,setIsUpload] = useState(true);
    const [replies, setReplies] = useState([])

    useEffect(() => {
        if(params.type === 'edit'){
            getAfterSalesDetail();
        }
        return destroySocket;
    }, []);

    useEffect(()=>{
        if(replies.length){
            setTimeout(()=>{
                messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
            }, 1000)
        }
    }, [replies])

    const destroySocket = () => {
        sc && sc.emit('leave-after', { after_id: params.id });
        sc && sc.disconnect();
        autoClose && clearTimeout(autoClose);
        sc = null;
        autoClose = null;
        reConnectCount = 0;
        console.log('component destroy to disconnect...');
    }

    const getAfterSalesDetail = () => {
        setLoading(true);
        afterSalesDetail(params.id).then(res => {
            setTableData(res.data);
            const arr = res.data.replyList.map(el => {
                el.is_push = 1 //1已推送，0 未推送，-1 推送失败
                return el
            })
            setReplies(arr)
            cacheObj = [].concat(arr)
            initWebSocket();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const completed = () => {
        // handleCompleted();
        setCompletedDialog(true)
    }

    const handleCompleted = () => {
        setLoading(true);
        afterSalesChanngedStatus(params.id).then(res => {
            message.success(res.msg);
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setCompletedDialog(false);
            setLoading(false);
        })
    }

    const initWebSocket = () => {
        const url = process.env.REACT_APP_BASE_AFTER_SOCKET + '/?userId=' + userId + '&refundId=' + params.id + '&token=' + getToken()
        sc = io.connect(url, {
            timeout: 60000,
            reconnectionDelayMax: 1000,
            reconnectionDelay: 500,
            httpCompress: false,
            wsEngine: 'ws',
            origins: '*',
            transports: ['websocket'],
            allowRequest: true,
            allowUpgraders: true
        })
        sc.on('connect', () => {
            console.log('socket connect success...');
            setLoading(false);
            sc.emit('customer-join-reply', { refundId: params.id, userId });
            reConnectCount = 0;
        });
        // 有人加入房间
        sc.on('customer-joined', (e) => {
            if ( userId == e.userId) {
                console.log("customer joined")
            }
        });
        // 收到回复信息
        sc.on('manager-reply-refund', (e) => {
            console.log("message receive")
            console.log(e)
            e.is_push = 1
            cacheObj.push(e)
            const arr = [].concat(cacheObj)
            setReplies(arr)
            sc.emit('customer-read-refund', { refundId: params.id });
        });
        // 发送信息成功
        sc.on('customer-send-success', e => {
            const messageid = e.messageUniqId
            const index = sendIndexMap.get(messageid)
            cacheObj[index].is_push = 1
            const arr = [].concat(cacheObj)
            setReplies(arr)
            setSendEnd(true);
            imgList = [] //清空上传文件
        })
        sc.on('send-error', (e) => {
            if (e.code === 400) {
                sc.emit('join-after', { refundId: params.id })
            }
        })
        sc.on('disconnect', (reason) => {
            console.log("disconnected")
            console.log(reason);
            if(closeType !== '3'){
                return;
            }
            if(reConnectCount < 3){
                setCloseType('3');//服务器断开
                console.log(`try reconnect for the ${++reConnectCount} time...`);
            }else{
                sc.disconnect();
                reconnectMsgBox('The session failed to connect multiple times.');
                console.log('The number of reconnections is exceeded...');
            }
        });
    }

    const reconnectMsgBox = (msg) => {
        // setLoading(false);
        // Modal.confirm({
        //     title:'Session Closed',
        //     content:msg,
        //     okText:'Reconnect',
        //     closable:false,
        //     maskClosable:false,
        //     autoFocusButton:false,
        //     icon:<ExclamationCircleFilled/>,
        //     footer:(_,{OkBtn, CancelBtn})=>{
        //         return (
        //             <OkBtn/>
        //         )
        //     },
        //     onOk:() => {
        //         setLoading(true);
        //         reconnect();
        //     }
        // });
    }

    const reconnect = () => {
        reConnectCount = 0;
        autoClose && clearTimeout(autoClose);
        sc && sc.connect();
    }

    const reply = (data) => {
        setSendEnd(false);
        if(sc.connected){
        }
        // 重新发出回复
        sc.emit('customer-reply-refund', data);
    }

    const handleReply = () => {
        // setSendEnd(false);
        // const sendMessage = editorRef.current.getContent({format:'text'}).replaceAll('\n','<br>').replaceAll('<br><br>','<br>');
        const sendMessage = editorRef.current.getContent()
            .replace(/<img[^>]*>/g,'')
            .replace(/<video[^>]*>/g,'')
            .replaceAll(`<span id="mce_marker" data-mce-type="bookmark">﻿</span>`, '')
            .replaceAll(`</video>`, '')
        console.log(sendMessage)
        // 回复消息处理
        const FormatDate = DateFormat();
        const uniqId = getUniqueId();
        let afterChat = {
            name: name,
            avatar: avatar,
            refundId: params.id,
            textContent: sendMessage,
            replyUserId: userId,
            imgList: [].concat(imgList),
            messageUniqId: uniqId
        };
        if (afterChat.textContent.trim()==='' && afterChat.imgList.length === 0) {
            message.warning('Please enter the reply content or picture！');
            return;
        }
        
        const obj = {
            messageUniqId: uniqId,
            is_push: 0,
            refundId: params.id,
            replyRole: 1,
            avatar: avatar,
            replyUserId: userId,
            name: name,
            replyTime: FormatDate,
            textContent: sendMessage,
            imgList: [].concat(imgList)
        }
        cacheObj.push(obj);
        // 消息上屏
        const arr = [].concat(cacheObj)
        sendIndexMap.set(uniqId, arr.length - 1)
        setReplies(arr)
        editorRef.current.setContent('');
        setTimeout(()=>{
            sc && sc.emit('customer-reply-refund', afterChat);
        }, 100)
    }

    const handleEnter = () => {
        const replyBtn = document.getElementById('reply-btn');
        replyBtn.click();
    }

    const handleBeforeUpload = (file) => {
        // setIsUpload(false);
        return new Promise((resolve, reject) => {
            if(imgList.length){
                reject("the amount of uploaded videos or images cannot be greater than 1!")
            }
            if(file.type == "video/mp4"){
                const isLt5M = file.size / 1024 / 1024 < 100;
                isLt5M ? resolve() : reject('The size of uploaded video cannot be larger than 100MB, and the amount of uploaded videos cannot be greater than 1!')
            } else {
                const isLt5M = file.size / 1024 / 1024 < 5;
                isLt5M ? resolve() : reject('The size of uploaded images cannot be larger than 5MB, and the amount of uploaded images cannot be greater than 1!');
                
            }
        }).then(
            () => {
                imgList = [file]
                setUploadFile(file);
                return file;
            },
            (err) => {
                console.error(err)
                message.error(err);
                return false;
            }
        )
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file',uploadFile);
        uploadStatic(formData,() => {}).then(res => {
           imgList = [{url: res.data, type: uploadFile.type}]
           const imageKey = uuid();
            getBase64(res.data, uploadFile.type, imageKey).then(res => {
                console.log(res)
                editorRef.current.insertContent(res);
            }).catch((err) => {
                console.error(err);
            })
        }).catch(err => {
            message.error(err.msg)
            console.error(err)
        }).finally(() => {
            setIsUpload(true);
        });
    }

    const getVideosAndImages = (list) => {
        if(!list.length){
            imgList = []
        }
    }

    return (
        <CommonDetail
            loading={loading}
        >
            <Flex ref={contentRef} className="after-sales-detail" style={{width:'100%',height:'100%'}} gap={15} justify="center">
                <Card style={{width:'25%',border:'2px solid #eee'}} styles={{body:{height:'calc(100% - 56px)'}}} title={currentLocaleData.CommonContent.AfterSale.detail.leftCard.title}>
                    <Flex vertical="vertical" gap={10} style={{height:'100%'}}>
                        <Typography style={{flex:1,overflow:'auto'}}>
                            <Typography.Text strong>{currentLocaleData.CommonContent.AfterSale.detail.leftCard.items[0]}:</Typography.Text>
                            <Typography.Paragraph>{tableData && tableData.thirdOrderId	}</Typography.Paragraph>
                            <Typography.Text strong>{currentLocaleData.CommonContent.AfterSale.detail.leftCard.items[1]}:</Typography.Text>
                            <Typography.Paragraph>
                                {tableData && tableData.applyReason }
                            </Typography.Paragraph>
                            <Typography.Text strong>{currentLocaleData.CommonContent.AfterSale.detail.leftCard.items[2]}:</Typography.Text>
                            <Typography.Paragraph>
                                { tableData && tableData.refundMode }
                            </Typography.Paragraph>
                            <Typography.Text strong>{currentLocaleData.CommonContent.AfterSale.detail.leftCard.items[3]}:</Typography.Text>
                            {
                                tableData && tableData.refundLines.map((item,index) => {
                                    return (
                                        <Typography.Paragraph
                                            key={uuid()}>
                                            {item.itemName}
                                        </Typography.Paragraph>
                                    )
                                })
                            }
                            <Typography.Text strong>{currentLocaleData.CommonContent.AfterSale.detail.leftCard.items[4]}:</Typography.Text>
                            <Typography.Paragraph>
                                { tableData &&  tableData.createTime }
                            </Typography.Paragraph>
                        </Typography>
                    </Flex>
                </Card>
                <Flex vertical="vertical" style={{width:'75%'}}>
                    <div style={{textAlign: "right", height: "40px"}}><Button key={uuid()} type="primary" onClick={completed}>{currentLocaleData.CommonContent.AfterSale.detail.rightCard.completeButton}</Button></div>
                    <Flex vertical="vertical" style={{height:'calc(100% - 40px)',border:'2px solid #eee'}}>
                        <Flex ref={messageBoxRef} vertical="vertical" style={{border:'none',overflow:'auto',height:`calc(100% - 200px)`,scrollBehavior: 'smooth'}}>
                            <div style={{width:'100%',height:'100%'}}></div>
                            {
                                replies.map((item) => (
                                    <Flex key={uuid()} gap={10} vertical="vertical" className={`message-box-${item.replyRole === 1 ? 'right' : 'left'}`}>
                                        <Flex justify="center">
                                            <span style={{color:'#949191'}}>{item.replyTime}</span>
                                        </Flex>
                                        <Flex align="start" justify={item.replyRole===1?'right':'left'}>
                                            {
                                                item.replyRole!==1 &&
                                                <Avatar
                                                    className="message-avatar"
                                                    shape="square" size={40}
                                                    src={item.avatar || '/avatar.png'}>
                                                </Avatar>
                                            }
                                            <Flex vertical="vertical" style={{width:'calc(100% - 40px)'}}>
                                                <span className="message-uname" style={{textAlign:item.replyRole === 1 ?'right':'left'}}>{item.name}</span>
                                                <Flex align="start"
                                                      justify={item.replyRole === 1 ? 'right' : 'left'}>
                                                    {/* {
                                                        item.replyRole !== 1 &&
                                                        <div className="content-box-before"></div>
                                                    } */}
                                                    {
                                                        !sendEnd && item.replyRole === 1 && !item.id &&
                                                        <div className="content-box content-spin-box">
                                                            <Spin spinning={item.is_push === 0}/>
                                                            {
                                                                (item.is_push === -1 && item.replyRole === 1) &&
                                                                <a style={{color: '#fff'}}
                                                                   onClick={(e) => {e.preventDefault();reply(item)}}>
                                                                    {currentLocaleData.CommonContent.AfterSale.detail.rightCard.sendErrorMsg}
                                                                </a>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        item.is_push === 1 &&
                                                        <div className="content-box">
                                                            <span dangerouslySetInnerHTML={{__html:item.textContent}}></span>
                                                            {
                                                                    item.imgList && item.imgList.map(img => {
                                                                        return (
                                                                            <span key={uuid()}>
                                                                                {
                                                                                    img.url.indexOf(".mp4") === -1 &&
                                                                                    <Image key={uuid()} width={60} src={img.url}/>
                                                                                }
                                                                                {
                                                                                    img.url.indexOf(".mp4") > -1 &&
                                                                                    <video
                                                                                                muted
                                                                                                width="80%"
                                                                                                controls
                                                                                                preload="metadata"
                                                                                                src={img.url}/>
                                                                                    // <Image
                                                                                    //     width={60}
                                                                                    //     key={uuid()}
                                                                                    //     src="/icons/video.png"
                                                                                    //     preview={{
                                                                                    //     destroyOnClose:true,
                                                                                    //     imageRender:() => (
                                                                                    //         <video
                                                                                    //             muted
                                                                                    //             width="80%"
                                                                                    //             controls
                                                                                    //             preload="metadata"
                                                                                    //             src={img.url}/>
                                                                                    //     ),
                                                                                    //     toolbarRender:() => null
                                                                                    // }}/>
                                                                                }
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            item.replyRole === 1 &&
                                                            <div className="content-box-before"></div>
                                                        }
                                                </Flex>
                                            </Flex>
                                            {
                                                item.replyRole === 1 &&
                                                <Avatar
                                                    className="message-avatar"
                                                    shape="square" size={40}
                                                    src={item.avatar || '/avatar.png'}>
                                                </Avatar>
                                            }
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </Flex>
                        {/* <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '5px',
                                height: '30px',
                                lineHeight: '20px',
                                background: '#eee'
                            }}
                        >
                            <WarningOutlined/>The service provider applies to complete the current after-sales service.
                        </div> */}
                        <div>
                            <RichText getVideosAndImages={getVideosAndImages} send={handleEnter} ref={editorRef}/>
                            <Flex align="center" justify="space-between" className="after-sales-detail-toolbar">
                                <Upload showUploadList={false} customRequest={handleUpload} beforeUpload={handleBeforeUpload} accept="image/png, image/jpeg, image/gif, video/mp4">
                                    <Button className="after-sales-detail-upload">
                                        <Image preview={false} width={24} height={24} src="/icons/uploadImg.svg"></Image></Button>
                                </Upload>
                                <Button
                                    id={'reply-btn'}
                                    disabled={!isUpload}
                                    onClick={handleReply}
                                    type="primary"
                                >{isUpload?currentLocaleData.CommonContent.AfterSale.detail.rightCard.replayButton:'uploading...'}</Button>
                            </Flex>
                        </div>
                    </Flex>
                </Flex>
            </Flex>
            <Modal
                title="Completed"
                open={completedDialog}
                onCancel={() => {setCompletedDialog(false)}}
                footer={[
                    <Button type="primary" key={uuid()} onClick={() => {handleCompleted()}}>Complete</Button>
                ]}
            >
                Are you sure to complete the current after sales information?
            </Modal>
        </CommonDetail>
    )
}
