import request from '../utils/request'

export function getOrderTabs(params) {
  return request({
    url: '/order/order/tab-list',
    method: 'get',
    params
  })
}
// 订单列表
export function getOrderList(params) {
  return request({
    url: '/order/list',
    method: 'get',
    params
  })
}
// 订单物流
export function getLogisticIndex(params) {
  return request({
    url: '/order_logistics_index',
    method: 'get',
    params
  })
}
// 订单物流
export function getLogisticInfo(params) {
  return request({
    url: '/order_logistics_info',
    method: 'get',
    params
  })
}
// 订单详情
export function getOrderInfo(id, params) {
  return request({
    url: '/order/' + id,
    method: 'get',
    params
  })
}
// 清除订单异常
export function clearOrderException(params) {
  return request({
    url: '/order_clear_exception',
    method: 'get',
    params
  })
}
// 获取委托管产品
export function getOrderGoods(params) {
  return request({
    url: '/order_get_order_goods',
    method: 'get',
    params
  })
}
// 托管产品
export function orderJoinQueue(data) {
  return request({
    url: '/order_join_queue',
    method: 'post',
    data
  })
}




// 库存管理 getdetaillist

export function getinventorylist(data) {
  return request({
    url: '/goods/goods/inventory-list',
    method: 'get',
    params: data,
  })
}

export function getdetaillist(data) {
  return request({
    url: '/goods/goods/detail-list',
    method: 'get',
    params: data,
  })
}

export function fundslist(data) {
  return request({
    url: '/flow/list',
    method: 'get',
    params: data,
  })
}

export function businesslist(data) {
  return request({
    url: '/order_pay_log/list',
    method: 'get',
    params: data,
  })
}

export function autoFulfill(data) {
  return request({
    url:'/user/order_process',
    method:'patch',
    params: data
  })
}

export function orderStatus(url) {
  return request({
    url: url,
    method:'get'
  })
}

export function pullOrder(data) {
  return request({
    url: '/store/pull_order',
    method:'post',
    data
  })
}
