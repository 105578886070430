import request from '../utils/request'

export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: '/user/signup',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/vue-admin-template/user/info',
    method: 'get',
    params: { token }
  })
}

export function shopifyApi(data) {
  return request({
    url: '/shopify/index/indexs',
    method: 'get',
    params: data
  })
}

export function shopifyPush(data) {
  return request({
    url: '/shopify/index/push',
    method: 'get',
    params: data
  })
}

export function sendEmail(data) {
  return request({
    url: '/user/verification_code',
    method: 'get',
    params: data
  })
}

export function baseChartIndex(data) {
  return request({
    url: '/base/chart/index',
    method: 'get',
    params: data
  })
}

// 修改密码
export function changePassword(data) {
  return request({
    url: '/user/password',
    method: 'patch',
    data: data
  })
}
// 个人中心获取用户基本信息
export function findUserInfo(data) {
  return request({
    url: '/user/detail',
    method: 'get',
    params: data
  })
}
export function findUserBalance() {
  return request({
    url: '/user/balance',
    method: 'get'
  })
}
// 个人中心获取店铺
export function getGoodsStoreList(data) {
  return request({
    url: '/store/list',
    method: 'get',
    params: data
  })
}
// 个人中心修改用户信息
export function updateUserInfo(data) {
  return request({
    url: '/user/detail',
    method: 'patch',
    data: data
  })
}
// . after sale type
export function afterSalesType() {
  return request({
    url: '/after/after/type',
    method: 'get'
  })
}

// . after sale reply
export function afterSalesReply(data) {
  return request({
    url: '/after/after/reply',
    method: 'post',
    data: data
  })
}

// afterSalesGenerateKey
export function afterSalesGenerateKey() {
  return request({
    url: '/after/after-sales/generate-key',
    method: 'get'
  })
}
// . shop/store/edit-icon
export function afterSalesEditIcon(data) {
  return request({
    url: '/shop/store/edit-icon',
    method: 'post',
    data: data
  })
}
