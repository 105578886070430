export default function Icon(props) {
    return (
        <>
            {
                props.type === 'English' ?
                    <img style={{height: '22px', marginRight: props.marginRight || '5px'}} src="/icons/us.png"/> :
                    props.type === 'German' ?
                        <img style={{height: '22px', marginRight: props.marginRight || '5px'}}
                             src="/icons/german.png"/> :
                        props.type === 'French' ?
                            <img style={{height: '22px', marginRight: props.marginRight || '5px'}}
                                 src="/icons/french.png"/> :
                            props.type === 'Woocommerce' ?
                                <span className="store-icon ant-menu-item-icon">
                                    <svg t="1713429971284" viewBox="0 0 1024 1024"
                                         version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5475" width="200" height="200">
                                    <path
                                        d="M128 128v768h768V128H128z m716.8 174.944c-34.144 64-46.944 136.544-59.744 209.056-17.056 93.856-29.856 183.456-21.344 277.344 0 25.6-8.544 51.2-38.4 59.744-25.6 4.256-46.944-8.544-64-25.6-72.544-76.8-110.944-170.656-140.8-268.8a3188.64 3188.64 0 0 0-85.344 170.656c-21.344 38.4-42.656 76.8-72.544 106.656-12.8 12.8-34.144 25.6-51.2 12.8-25.6-21.344-34.144-59.744-42.656-89.6-42.656-140.8-64-285.856-89.6-430.944-8.544-29.856 4.256-59.744 25.6-68.256s46.944-8.544 64 4.256c12.8 8.544 17.056 25.6 17.056 42.656 17.056 110.944 38.4 226.144 64 332.8 46.944-89.6 93.856-174.944 140.8-264.544 8.544-12.8 17.056-25.6 29.856-34.144 17.056-12.8 42.656-8.544 55.456 4.256s17.056 29.856 21.344 46.944c12.8 72.544 34.144 145.056 59.744 213.344 12.8-102.4 25.6-209.056 64-307.2 8.544-21.344 17.056-42.656 29.856-55.456 25.6-25.6 68.256-17.056 85.344 12.8 12.8 12.8 12.8 34.144 8.544 51.2z"
                                        p-id="5476" fill="#2c2c2c"></path>
                                </svg>
                                </span> :
                                props.type === 'Shopify' ?
                                    <span className="store-icon ant-menu-item-icon">
                                        <svg t="1713430241625" viewBox="0 0 1024 1024"
                                             version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" p-id="6571" width="200" height="200">
                                        <path
                                            d="M851.2 198.4c0-6.4-6.4-9.6-9.6-9.6-3.2 0-83.2-6.4-83.2-6.4S704 131.2 697.6 124.8c-6.4-6.4-19.2-3.2-22.4-3.2 0 0-12.8 3.2-32 9.6-16-51.2-48-99.2-105.6-99.2h-3.2c-16-22.4-35.2-32-54.4-32-131.2 0-195.2 166.4-214.4 249.6-51.2 16-89.6 25.6-92.8 28.8-28.8 9.6-28.8 9.6-32 35.2-3.2 19.2-76.8 601.6-76.8 601.6L646.4 1024l316.8-67.2S851.2 204.8 851.2 198.4zM528 160v6.4c-32 9.6-67.2 19.2-102.4 32 19.2-76.8 57.6-112 89.6-124.8 9.6 19.2 12.8 48 12.8 86.4z m-51.2-124.8c6.4 0 12.8 3.2 16 6.4-41.6 19.2-86.4 70.4-105.6 169.6-28.8 9.6-54.4 16-80 25.6 22.4-76.8 73.6-201.6 169.6-201.6z m22.4 448s-35.2-19.2-76.8-19.2c-60.8 0-64 38.4-64 48 0 54.4 137.6 73.6 137.6 198.4 0 99.2-60.8 160-144 160-99.2 0-150.4-60.8-150.4-60.8l25.6-89.6s51.2 44.8 96 44.8c28.8 0 41.6-22.4 41.6-38.4 0-70.4-112-73.6-112-185.6 0-96 67.2-188.8 208-188.8 54.4 0 80 16 80 16l-41.6 115.2z m64-326.4v-9.6c0-32-3.2-57.6-12.8-80 28.8 3.2 48 38.4 60.8 73.6-12.8 6.4-28.8 9.6-48 16z"
                                            fill="#444444" p-id="6572"></path>
                                    </svg>
                                    </span> :
                                    <></>
            }
        </>
    )
}
