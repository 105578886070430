import LoginLayout from "../../components/LoginLayout";
import {Button, Flex, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCookies, setCookies} from "../../utils/cookies";
import {register, sendEmail} from "../../api/user";
import {setToken} from "../../utils/auth";
import {SendOutlined} from "@ant-design/icons";
import useStore from "../../store/store";
import Base64 from'js-base64';
import { useLocation } from 'react-router-dom'
let task;

function decryptBase64(str) {
    const decodedString = Base64.Base64.decode(str);
    return decodedString;
}

export default function Register(){
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formRef] = Form.useForm();
    const [startCount, setStartCount] = useState(false);
    const [timeCount, setTimeCount] = useState(60);
    const shop_store = useStore(state => state.shop);
    const shopify_store = useStore(state => state.shopify);
    const { search } = useLocation();

    useEffect(() => {
        task && clearInterval(task);
        return () => {
            task && clearInterval(task);
            task = null;
        };
    }, []);
    useEffect(() => {
        if (timeCount === 0) {
            clearInterval(task);
            setStartCount(false);
            setTimeCount(60);
        }
    }, [timeCount]);

    const handleRegister = async (values) => {
        setLoading(true);

        const query = {};
        new URLSearchParams(search).forEach((value, key) => {
          if (query[key]) {
            query[key] = [].concat(query[key], value);
          } else {
            query[key] = value;
          }
        });

        const shop = query.shop;
        const formData = shop ? { ...values, shopifyShopUrl: shop } : { ...values };
        await register(formData).then(response => {
            message.success("success")
            const token = response.data
            setToken(token);
            let parts = token.split('.');
            let payload = decryptBase64(parts[1]);
            let payloadObj = JSON.parse(payload);
            // setCookies('uid', payloadObj.uid);
            setCookies('email', payloadObj.sub);
            navigate('/dashboard',{replace:true});
        }).catch(err => console.error(err))
        setLoading(false);
    }

    const signIn = () => {
        navigate('/login');
    }

    const handleSendCode = () => {
        formRef.validateFields(['email']).then(res => {
            const email = res.email;
            setStartCount(true);
            task = setInterval(() => {
                setTimeCount(timeCount => --timeCount);
            }, 1000);
            sendEmail({ email: email }).then(res => {
                message.success('Sent successfully');
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            message.error('The input is not valid E-mail!');
        });
    }
    return (
        <LoginLayout footerText="Already have an account?" title="Sign Up" buttonText="Log In" changePage={signIn}>
            <Form form={formRef} layout="vertical" onFinish={handleRegister} className="login-form">
                <Form.Item name="email" rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                ]}>
                    <Input className="user-input" placeholder="Email"/>
                </Form.Item>
                <Form.Item name="code" rules={[
                    {
                        required: true,
                        message: 'Please input your verification code!',
                    }
                ]}>
                    <Flex align="center">
                        <Input className="user-input" placeholder="Email Verification Code" style={{borderTopRightRadius:'0',borderBottomRightRadius:'0'}}/>
                        <Button
                            style={{
                                width:'100px',
                                height:'50px',
                                lineHeight:'40px',
                                borderTopLeftRadius:'0',
                                borderBottomLeftRadius:'0'
                            }}
                            type="primary" icon={<SendOutlined/>} disabled={startCount} onClick={handleSendCode}>{startCount?timeCount:'Send'}</Button>
                    </Flex>
                </Form.Item>
                <Form.Item name="password" rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}>
                    <Input.Password className="user-input" placeholder="Password"/>
                </Form.Item>
                <Form.Item name="username" rules={[
                    {
                        required: true,
                        message: 'Please input your name!',
                    }
                ]}>
                    <Input className="user-input" placeholder="Name"/>
                </Form.Item>
                <Form.Item>
                    <Button className="login-btn" loading={loading} disabled={loading} htmlType="submit">Get Started</Button>
                </Form.Item>
            </Form>
        </LoginLayout>
    )
}
