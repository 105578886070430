import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {ConfigProvider} from "antd";
import {Theme} from "./commons/enum";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider theme={Theme}>
        <RouterProvider router={router}/>
    </ConfigProvider>
);
reportWebVitals();
