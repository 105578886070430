import {message, Switch, Tooltip} from "antd";
import uuid from "../../../utils/uuid";
import {useEffect, useState} from "react";
import CommonTable from "../../../components/CommonTable";
import {shopList} from "../../../api/shop";
import useStore from "../../../store/store";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export default function Notifications(props) {
    const [formQuery,setFormQuery] = useState({})
    const [listQuery, setListQuery] = useState({page:1,limit:10,total:0});
    const [tableData, setTableData] = useState([]);
    const currentLocaleData = useStore(state=>state.currentLocaleData);
    useEffect(() => {
        getNotifications();
    }, []);
    const getNotifications = () => {
        props.setLoading(true);
        formQuery.pageSize = listQuery.limit
        formQuery.pageNum = listQuery.page
        shopList(formQuery).then(res => {
            const list = res.data.list
            const newlist = list.map((item,index) => {
                item.key = item.storeId;
                return item
            })
            setTableData(newlist);
            listQuery.total = +res.data.total;
            setListQuery({...listQuery});
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            props.setLoading(false);
        });
    }
    const updatePage = (page,pageSize) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getNotifications();
    }
    return (
        <CommonTable
            loading={false}
            rowSelection={false}
            dataSource={tableData}
            page={listQuery.page}
            total={listQuery.total}
            pageSize={listQuery.limit}
            pageChange={updatePage}
            columns={[
                {title:currentLocaleData.CommonContent.Settings.notification.table.columns[0],dataIndex:'storeName',key:uuid(),ellipsis: {showTitle:false},render: (_, record) => (
                        <Tooltip placement="bottomLeft" title={record.storeName}>{record.storeName}</Tooltip>
                )},
                {title:currentLocaleData.CommonContent.Settings.notification.table.columns[1],dataIndex:'storeUrl',key:uuid(),ellipsis: {showTitle:false},render: (_, record) => (
                        <Tooltip placement="bottomLeft" title={record.storeUrl}>{record.storeUrl}</Tooltip>
                )},
                {title:currentLocaleData.CommonContent.Settings.notification.table.columns[2],dataIndex:'platformType',key:uuid(),ellipsis: {showTitle:false},render: (_, record) => (
                        <Tooltip placement="bottomLeft" title={capitalizeFirstLetter(record.platformType)}>{capitalizeFirstLetter(record.platformType)}</Tooltip>
                )}
            ]}
        />
    )
}
