import request from '../utils/request'

// . after sale list
export function afterSalesList(params) {
  return request({
    url: '/refund/list',
    method: 'get',
    params
  })
}

// . after sale detail
export function afterSalesDetail(id) {
  return request({
    url: '/refund/' + id,
    method: 'get'
  })
}

// . after sale type
export function afterSalesType() {
  return request({
    url: '/option/RefundReason/list',
    method: 'get'
  })
}

// . after sale create
export function afterSalesCreate(data) {
  return request({
    url: '/refund',
    method: 'post',
    data: data
  })
}

// . after sale channged status
export function afterSalesChanngedStatus(refundId) {
  return request({
    url: `/refund/${refundId}/completed`,
    method: 'patch'
  })
}

// . after sale confirm send
export function afterSalesConfirmSend(data) {
  return request({
    url: '/after/after-sales/confirm-send',
    method: 'post',
    data: data
  })
}

// . after sale reply
export function afterSalesReply(data) {
  return request({
    url: '/after/after-sales/reply',
    method: 'post',
    data: data
  })
}

// . after sale Timeline
export function afterSalesTimeline(id) {
  return request({
    url: `/refund/${id}/time_line`,
    method: 'get'
  })
}
