import CommonDetail from "../../../components/CommonDetail";
import {
    Button, Card, Dropdown, Flex, Image, Popover, Row, Select, Table, Tag, Tooltip, Typography,
} from "antd";
import {useEffect, useRef, useState} from "react";
import error_img from "../../../commons/img_error";
import {DownOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import uuid from "../../../utils/uuid";
import {useParams} from "react-router-dom";
import {getDetailByIdAndStore} from "../../../api/product";
import {ProductStatusColor, VariantStatusColor} from "../../../commons/enum";
import useStore from "../../../store/store";
import Decimal from "decimal.js"

const map = new Map()

function toDecimal(num) {
    return new Decimal(num)
}

function countPrice(a1, a2, a3){
    let count = toDecimal(0);
    if(a1){
        a1 = toDecimal(a1)
        count = count.plus(a1)
    }
    if(a2){
        a2 = toDecimal(a2)
        count = count.plus(a2)
    }
    if(a3){
        a3 = toDecimal(a3)
        count = count.plus(a3)
    }
    return count.toNumber()
}

export default function MyProductDetail(){
    const params = useParams();
    const [loading,setLoading] = useState(false);
    const carouselRef = useRef(null);
    const [index,setIndex] = useState(0);
    const [currentScroll,setCurrentScroll] = useState(0);
    const [fileList,setFileList] = useState([]);
    const [stopNum,setStopNum] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [detailData,setDetailData] = useState({});
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [countries,setCountries] = useState([]);
    const [currentCountry,setCurrentCountry] = useState('');
    
    useEffect(() => {
        if(params.type === 'edit'){
            getDetailData();
        }
    }, []);

    const formatImageList = (fileList) => {
        const num = fileList.length % 3;
        const extraList = [];
        setStopNum(fileList.length-1);
        if(num !== 0){
            for(let i=0;i<3-num;i++){
                extraList.push('extra');
            }
        }
        fileList.push(...extraList);
        setFileList([...fileList]);
    }

    const getDetailData = () => {
        setLoading(true);
        getDetailByIdAndStore(params.id).then(res => {
            console.log(res)
            const {imgUrl,title} = res.data;
            const imgs = []
            imgUrl && imgs.push(imgUrl)
            if(res.data.skuList.length){
                const countries = new Set()
                for(let i=0;i<res.data.skuList.length;i++){
                    const el = res.data.skuList[i]
                    if(el.imgUrl){
                        imgs.push(el.imgUrl)
                    }
                    if(el.sourcedResults){
                        for(let j=0;j<el.sourcedResults.length;j++){
                            map.set(el.sourcedResults[j].country + "-" + el.id, 
                                {productFee: el.sourcedResults[j].productFee, 
                                serviceFee: el.sourcedResults[j].serviceFee, 
                                shippingFee: el.sourcedResults[j].shippingFee, 
                                skuId: el.sourcedResults[j].skuId})
                            countries.add({label: el.sourcedResults[j].country, value: el.sourcedResults[j].country})
                        }
                    }
                }
                const countriesArr = Array.from(countries)
                if(countriesArr.length){
                    setCountries(countriesArr);
                    setCurrentCountry(countriesArr[0].value);
                }
                const data = res.data.skuList.map((item,index) => {
                    item.key = index;
                    return item;
                })
                setTableData(data);

            }
            formatImageList(imgs);
            setDetailData({imgs: imgs,title});
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleMove = (type) => {
        if(type === 1){//right
            if((index+1)%3 === 0){
                carouselRef.current.scrollTo(currentScroll+75*3,0);
                setCurrentScroll(currentScroll+75*3);
            }
            setIndex(index+1);
        }else {//left
            if(index%3 === 0){
                carouselRef.current.scrollTo(currentScroll-75*3,0);
                setCurrentScroll(currentScroll-75*3);
            }
            setIndex(index-1);
        }
    }
    return (
        <CommonDetail
            loading={loading}
            filter={false}
        >
            <Card
                className="store-detail-card"
                style={{width:'100%',height:'100%'}}
                styles={{body:{height:'calc(100% - 68px)',overflow:'auto'}}}
                title={
                    <Flex gap={15} align="center">
                        <Tooltip title={detailData.title} placement={"bottom"}>
                            <h3 style={{
                                maxWidth: 'calc(100% - 95px)',
                                overflow: 'hidden',
                                textWrap: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{detailData.title || ''}</h3>
                        </Tooltip>
                    </Flex>
                }
            >
                <Flex style={{height:'100%'}} vertical="vertical" gap={20}>
                    <Flex gap={100}>
                        <Flex vertical="vertical" align='center' gap={15}>
                            <Image width={210} height={210} src={detailData.imgs && detailData.imgs[index]} fallback={error_img}/>
                            <Flex align='center' gap={15}>
                                <Button disabled={index === 0} icon={<LeftOutlined />}
                                        onClick={() => {handleMove(2)}}/>
                                <Row ref={carouselRef} style={{display:'flex',flexDirection:'column',columnGap:'15px',width:'211px',height:'60px',alignItems:'center',justifyContent:'center',overflow:'hidden'}}>
                                    {
                                        fileList && fileList.map((item,idx) =>
                                            {
                                                if(item !== 'extra'){
                                                    return (
                                                        <Image onClick={() => {setIndex(idx)}} key={uuid()} src={item} preview={false} width={60} height={60}
                                                               fallback={error_img}
                                                               style={{
                                                                   cursor:'pointer',
                                                                   padding: '2px',
                                                                   border: index === idx ? '2px solid rgb(239,111,56)' : 'none'
                                                               }}/>
                                                    )
                                                }
                                                return (
                                                    <div key={uuid()} style={{width:'60px',height:'60px'}}></div>
                                                )
                                            }
                                        )
                                    }
                                </Row>
                                <Button disabled={index === stopNum || fileList.length <= 1}
                                        icon={<RightOutlined />}
                                        onClick={() => {handleMove(1)}}/>
                            </Flex>
                        </Flex>
                        <Typography>
                            <Typography.Title level={4}>{currentLocaleData.CommonContent.MyProducts.detail.description.title}</Typography.Title>
                            <Typography.Paragraph>
                                <Typography.Text type="danger">1. </Typography.Text>{currentLocaleData.CommonContent.MyProducts.detail.description.article['1']}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Typography.Text type="danger">2. </Typography.Text>{currentLocaleData.CommonContent.MyProducts.detail.description.article['2']}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Typography.Text type="danger">3. </Typography.Text>{currentLocaleData.CommonContent.MyProducts.detail.description.article['3']}
                            </Typography.Paragraph>
                            <Typography.Text strong>
                                {currentLocaleData.CommonContent.MyProducts.detail.description.statement}
                            </Typography.Text>
                        </Typography>
                    </Flex>
                    <Flex flex={1}>
                        <Table
                            className="store-detail-table" style={{flex:1}}
                            dataSource={tableData} columns={[
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[0],ellipsis:{showTitle:false},render:(_,record) => (
                                <Tooltip title={record.title} placement={"bottomLeft"}>
                                    <Flex style={{width:'100%',background:'#eff0f4',padding:'7px'}} align="center" gap={10}>
                                        <Image key={uuid()} width={40} height={40} src={record.imgUrl} fallback={error_img}/>
                                        <span
                                            style={{
                                                color:'rgb(239,111,56)',
                                                height:'40px',
                                                lineHeight:'40px',
                                                width:'calc(100% - 50px)',
                                                wordBreak:'break-all',whiteSpace:'nowrap',
                                                textOverflow:'ellipsis',overflow:'hidden'}}>
                                            {record.title}
                                        </span>
                                    </Flex>
                                </Tooltip>
                                )},
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[1],ellipsis:{showTitle:false},render:(_,record) => (
                                    <Tooltip title={record.salePrice} placement={"bottomLeft"}>
                                        {record.salePrice}
                                    </Tooltip>
                                )},
                            {title:'',className:'split-th'},
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[2],ellipsis:{showTitle:false},render:(_,record) => (
                                    currentCountry && map.get(currentCountry + "-" + record.id) &&
                                    <Tooltip title={map.get(currentCountry + "-" + record.id).productFee} placement={"bottomLeft"}>
                                        {map.get(currentCountry + "-" + record.id).productFee}
                                    </Tooltip>
                                )},
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[3],
                                ellipsis:{showTitle:false},render:(_,record) => (
                                    currentCountry && map.get(currentCountry + "-" + record.id) &&
                                    <Tooltip title={map.get(currentCountry + "-" + record.id).serviceFee} placement={"bottomLeft"}>
                                        {map.get(currentCountry + "-" + record.id).serviceFee}
                                    </Tooltip>
                                )},
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[4],
                                ellipsis:{showTitle:false},render:(_,record) => (
                                    currentCountry ? 
                                    (map.has(currentCountry + "-" + record.id) ? 
                                    (map.get(currentCountry + "-" + record.id) ?
                                    ( map.get(currentCountry + "-" + record.id).shippingFee ?
                                    <Tooltip title={map.get(currentCountry + "-" + record.id).shippingFee} placement={"bottomLeft"}>
                                        {map.get(currentCountry + "-" + record.id).shippingFee}
                                    </Tooltip> : <span title="Please contact your account manager">Please contact your account manager</span>) : "") : "") : ""
                                )},
                            {title:currentLocaleData.CommonContent.MyProducts.detail.table.columns[5],ellipsis:{showTitle:false},render:(_,record) => (
                                    currentCountry && map.get(currentCountry + "-" + record.id) &&
                                    <Tooltip title={countPrice(
                                        map.get(currentCountry + "-" + record.id).productFee, 
                                        map.get(currentCountry + "-" + record.id).serviceFee, 
                                        map.get(currentCountry + "-" + record.id).shippingFee)} placement={"bottomLeft"}>
                                        {countPrice(map.get(currentCountry + "-" + record.id).productFee, 
                                        map.get(currentCountry + "-" + record.id).serviceFee, 
                                        map.get(currentCountry + "-" + record.id).shippingFee)}
                                    </Tooltip>
                                )},
                            {
                                // onClick:(e) => {
                                //     setCurrentCountry(countries[Number(e.key)].label);
                                // }}
                                // title:
                                //     <Dropdown placement="bottom" arrow trigger="click"
                                //               menu={{items: countries}} >
                                //         <Button style={{width:'100%',height:'100%'}}>
                                //             <Flex align="center" justify="space-between">
                                //                 <span>{currentCountry || 'No Data'}</span>
                                //                 <DownOutlined/>
                                //             </Flex>
                                //         </Button>
                                //     </Dropdown>,
                                title: <Select style={{width:"150px"}} options={countries} value={currentCountry} 
                                    onChange={(val)=>{setCurrentCountry(val)}} 
                                    placeholder="no data">
                                    {/* <Select.Option></Select.Option> */}
                                </Select>,
                                render:(_,record) => (
                                    <Tooltip title={currentCountry} placement={"bottomLeft"}>
                                        {currentCountry}
                                    </Tooltip>
                                )
                            }
                        ]}/>
                    </Flex>
                </Flex>
            </Card>
        </CommonDetail>
    )
}
