import './LoginLayout.scss'
import {Button, Divider, Flex, Form, Input, Layout} from "antd";
import fb from "../assets/home/icon-1@2x.png";
import yb from "../assets/home/icon-2@2x.png";
import ig from "../assets/home/icon-3@2x.png";
import tw from "../assets/home/icon-4@2x.png";
import ing from "../assets/home/icon-5@2x.png";

export default function LoginLayout(props){
    return (
        <Flex className="login-container">
            <Flex className="left-advertising" align="center" justify="center">
                <Flex vertical="vertical" style={{width:'70%',height:'80%'}}>
                    <Flex align="center" gap={50} style={{color:'#fff'}}>
                        <Flex vertical="vertical">
                            <p style={{fontSize: '6em', margin: 0, fontFamily: 'fangsong', fontWeight: '700'}}>Work</p>
                            <p style={{fontSize: '6em', margin: 0, fontFamily: 'fangsong', fontWeight: '700'}}>with</p>
                        </Flex>
                        <Flex vertical="vertical" className="word-row" justify="space-between" gap={15}>
                            <Flex align="center" gap={10}><span className="dot"></span><h2>Sourcing agent</h2></Flex>
                            <Flex align="center" gap={10}><span className="dot"></span><h2>Cheaper</h2></Flex>
                            <Flex align="center" gap={10}><span className="dot"></span><h2>More choices</h2></Flex>
                            <Flex align="center" gap={10}><span className="dot"></span><h2>Safer</h2></Flex>
                            <Flex align="center" gap={10}><span className="dot"></span><h2>Quicker</h2></Flex>
                        </Flex>
                    </Flex>
                    <div style={{
                        width: '100%',
                        height: "calc(100% - 222px)",
                        backgroundImage:'url("/login.png")',
                        backgroundSize:'contain',
                        backgroundRepeat:'no-repeat'}}>
                    </div>
                </Flex>
            </Flex>
            <Flex vertical="vertical" align='center' justify='center' className="right-login">
                <Flex vertical="vertical" align='center' justify='center' gap={20} className="login-content">
                    <h2 style={{width: '45%', textAlign: 'left'}}>{props.title}</h2>
                    <Flex vertical="vertical" style={{width: '45%'}}>
                        {props.children}
                        <Divider style={{margin: 0, color: "#b4b1b1"}} children="OR"/>
                        <p style={{textAlign: 'center', fontSize: '14px', color: '#b4b1b1'}}>
                            {props.footerText}
                            <Button style={{fontSize: '16px'}} type="link" onClick={props.changePage}>{props.buttonText}</Button>
                        </p>
                    </Flex>
                </Flex>
                <Flex vertical="vertical" className="login-footer">
                    <Layout.Footer className="icon-footer">
                        <Flex justify="center" gap={50}>
                            <img src={fb} alt=""/>
                            <img src={yb} alt=""/>
                            <img src={ig} alt=""/>
                            <img src={tw} alt=""/>
                            <img src={ing} alt=""/>
                        </Flex>
                    </Layout.Footer>
                    <Layout.Footer className="login-copyright">
                        © 2024-2025 FbAil All rights reserved.
                    </Layout.Footer>
                </Flex>
            </Flex>
        </Flex>
    )
}
