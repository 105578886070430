import qs from "query-string";
import {getToken, setToken} from "../../utils/auth";
import {setCookies} from "../../utils/cookies";
import {shopifyApi} from "../../api/user";
import {Navigate, useLocation} from "react-router-dom";
import useStore from "../../store/store";
import {useEffect, useState} from "react";
import ErrorPage from "../404/ErrorPage";

export default function Intercept(props) {
    const location = useLocation();
    const uid = useStore(state => state.uid);
    const updateEmail = useStore(state => state.updateEmail);
    const updateUID = useStore(state => state.updateUID);
    const updateShopify = useStore(state => state.updateShopify);
    const updateShop = useStore(state => state.updateShop);
    const [jumpView, setJumpView] = useState(<ErrorPage/>);
    const updateIsAuth = useStore(state => state.updateIsAuth);
    // const whiteList = ['/', '/login', '/register', '/auth', '/privacy-policy', '/about-us', '/faq', '/content', '/contact'] // no redirect whitelist
    useEffect(() => {
        routerIntercept();
    },[]);

    const routerIntercept = () => {
        const token = getToken();
        if(location.pathname === '/track'){
            return props.children;
        }else{
            if (token) { // 已经有token
                // console.log(token)
                // updateShop(query.shop);
                updateIsAuth(true);
                setJumpView(props.children);
            } else {
                updateIsAuth(false);
                setJumpView(<Navigate to="/login" replace={true}/>);
            }
        }
    }
    return (
        <>
            {jumpView}
        </>
    )
}
