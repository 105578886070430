import uuid from "../../../utils/uuid";
import {Tag, Image, Tooltip, Flex} from "antd";
import {
    NewSourceStatus,
    ProductStatusColor,
} from "../../../commons/enum";
import error_img from "../../../commons/img_error";
import {storeUrlFormat} from "../../../utils/stringUtils";

const columns = (jumpToDetail,rowSelect,cols,tagClicks) => [
    {title: cols[0],dataIndex: 'imgUrl',key: uuid(),ellipsis: {showTitle: false},render: (_,record) => (
            <Tooltip placement="bottomLeft" title={record.title}>
                <Flex style={{width:'100%',background:'#eff0f4',padding:'7px'}} align="center" gap={10}>
                    <Image key={uuid()} width={40} height={40} src={record.imgUrl} fallback={error_img}/>
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            jumpToDetail(record);
                        }}
                        style={{
                            color:'rgb(239,111,56)',
                            height:'40px',
                            lineHeight:'40px',
                            width:'calc(100% - 50px)',
                            wordBreak:'break-all',whiteSpace:'nowrap',
                            textOverflow:'ellipsis',overflow:'hidden'}}>{record.title}</span>
                </Flex>
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                style: {
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[1],dataIndex: 'thirdTags',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
        <Flex vertical="vertical" align="start" gap={2}>
            {
                record.thirdTags && record.thirdTags.split(',').map(item => (
                    <Tag color={ProductStatusColor[item]} key={uuid()}>
                        <Tooltip placement="bottomLeft" title={item}>
                            {item}
                        </Tooltip>
                    </Tag>
                ))
            }
        </Flex>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[2],dataIndex: 'last_sale',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip title={_?`${_} units`:'No units'} placement="bottomLeft">
                {_?_:'No units'}
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[3],dataIndex: 'storeUrl',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip title={record.storeUrl} placement="bottomLeft">
                {storeUrlFormat(record.storeUrl)}
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[4],dataIndex: 'sourcingStatus',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <>
                {
                    record.sourcingStatus.toLowerCase() === "ask sourcing" ?NewSourceStatus["ask sourcing"](record,tagClicks['ask sourcing']) : NewSourceStatus[record.sourcingStatus.toLowerCase()](record) 
                }
            </>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
];

export {columns}
