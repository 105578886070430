import {createBrowserRouter} from "react-router-dom";
import Index from '../views'
import Dashboard from '../views/content/dashboard/Dashboard'
import StoreProducts from '../views/content/myProducts/MyProducts'
import Orders from '../views/content/orders/Orders'
import AfterSale from "../views/content/afterSale/AfterSale";
import Store from "../views/content/store/Store";
import Settings from "../views/content/settings/Settings";
import Bill from "../views/content/bill/Bill";
import OrderDetail from "../views/content/detail/OrderDetail";
import AfterSaleDetail from "../views/content/detail/AfterSaleDetail";
import MyProductDetail from "../views/content/detail/MyProductDetail";
import ErrorPage from "../views/404/ErrorPage";
import Login from "../views/login/Login";
import Register from "../views/register/Register";

const router = createBrowserRouter([
    {
        path:'/',
        element:<Index/>,
        errorElement:<ErrorPage/>,
        children:[
            {
              path:'/dashboard',
              index:true,
              element:<Dashboard/>
            },
            {
              path:'/my-products',
              element:<StoreProducts/>
            },
            {
                path:'/orders',
                element:<Orders/>,
            },
            {
                path:'/after-sale',
                element:<AfterSale/>,
            },
            {
                path:'/store',
                element:<Store/>
            },
            {
                path:'/bill',
                element:<Bill/>,
            },
            {
                path:'/settings',
                element:<Settings/>,
            },
            {
                path:'/order-detail/:id/:platform/:status',
                element:<OrderDetail/>,
            },
            {
                path:'/after-sale-detail/:type/:id/:orderNo',
                element:<AfterSaleDetail/>,
            },
            {
                path:'/my-product-detail/:type/:id/:storeType/:platform/:storeUrl',
                element:<MyProductDetail/>,
            }
        ]
    },
    {
        path:'/login',
        element:<Login/>
    },
    {
        path:'/register',
        element:<Register/>
    },
]);
export default router;
