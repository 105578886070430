// export const getBase64 = (file,type,key) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader()
//         reader.readAsDataURL(file);
//         // 转 失败
//         reader.onerror = (error) => {
//             reject(error);
//         }
//         // 转 结束
//         reader.onloadend = () => {
//             let dom = null;
//             if(type !== 'video/mp4'){
//                 dom = new Image();
//                 dom.src = reader.result;
//                 dom.onload = () => {
//                     const width = `${dom.width/4}px`;
//                     const height = `${dom.height/4}px`;
//                     resolve(`<img data-key="${key}" style="width: ${width};height: ${height}" src="${reader.result}"/>`);
//                 }
//             }else{
//                 dom = document.createElement('video');
//                 dom.src = reader.result;
//                 resolve(`<video controls data-key="${key}" src="${reader.result}"/>`);
//             }
//         }
//     })
// }

export const getBase64 = (url,type) => {
    return new Promise((resolve, reject) => {
        if(type !== 'video/mp4'){
            resolve(`<img src="${url}"/>`);
        }else{
            resolve(`<video controls src="${url}"/>`);
        }
    })
}
