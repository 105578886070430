import CommonContent from '../../../components/CommonContent'
import {useEffect, useRef, useState} from "react";
import CommonTable from "../../../components/CommonTable";
import uuid from "../../../utils/uuid";
import {Button, Dropdown, Form, Input, message, Modal, Space, Tooltip} from "antd";
import {CheckOutlined, CloseOutlined, DownOutlined} from "@ant-design/icons";
import {c_name, checkShop, shopList, shopStoreSaved, editStore} from "../../../api/shop";
import useStore from "../../../store/store";
import {isEmpty} from "../../../utils/stringUtils";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export default function Store() {
    const [loading,setLoading] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [formQuery,setFormQuery] = useState({});
    const [listQuery,setListQuery] = useState({page:1,total:0,limit:10});
    const [connectEdit,setConnectEdit] = useState(false);
    const [connectId,setConnectId] = useState();
    const [dialogAttr,setDialogAttr] = useState({isOpen:false,title:''});
    const [connectCommand,setConnectCommand] = useState();
    const [woocommerceEditForm] = Form.useForm();
    const [shopifyEditForm] = Form.useForm();
    const [editLoading,setEditLoading] = useState(false);
    const uid = useStore((state) => state.uid);
    const [inputEdit,setInputEdit] = useState(-1);
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const getShopList = () => {
        setLoading(true);
        formQuery.pageSize = listQuery.limit;
        formQuery.pageNum = listQuery.page;
        shopList(formQuery).then(res => {
            const list = res.data.list
            const newlist = list.map((item,index) => {
                item.key = item.storeId;
                return item
            })
            setTableData(newlist);
            listQuery.total = +res.data.total;
            setListQuery({...listQuery});
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        getShopList();
    }, []);
    const updatePage = (page,pageSize) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getShopList();
    }
    const handleEdit = (row) => {
        handleCommand('2');
        const form = {}
        form.storeName = row.storeName;
        form.apiKey = row.apiKey;
        form.apiSecret = row.apiSecret;
        woocommerceEditForm.setFieldsValue(form);
        setConnectEdit(true);
        setConnectId(row.storeId);
    }

    const handleCommand = (command) => {
        woocommerceEditForm.setFieldsValue({storeName:'',storeUrl:'',apiKey:'',apiSecret:''});
        if (command === '1') {
            dialogAttr.title = 'Connect to Shopify';
            setConnectCommand(1);
        } else {
            setConnectEdit(false);
            dialogAttr.title = 'Connect to Woo Commerce';
            setConnectCommand(2);
        }
        dialogAttr.isOpen = true;
        setDialogAttr({...dialogAttr});
    }

    const handleWoocommerce = async () => {
        let values 
        if(connectEdit){
            values = await woocommerceEditForm.validateFields(['storeName','apiKey','apiSecret']).then((values) => {
                return values
            }).catch(err => {
                console.log(err);
            });
        } else {
            values = await woocommerceEditForm.validateFields(['storeName', 'storeUrl','apiKey','apiSecret']).then((values) => {
                return values
            }).catch(err => {
                console.log(err);
            });
        }
        if(!values){
            return
        }
        const {storeName,apiKey,apiSecret} = values;
        // setEditLoading(true);
        const formData = {}
        formData.storeName = storeName;
        formData.apiKey = apiKey;
        formData.apiSecret = apiSecret;
        if (connectEdit) {
            await editStore(connectId, formData).then(res=>{
                message.success("success")
            })
            .catch(err => console.error(err))
        }
        formData.storeUrl = values.storeUrl
        formData.platform = 1
        shopStoreSaved(formData).then(res => {
            message.success("success");
            getShopList();
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setEditLoading(false);
            setDialogAttr({...dialogAttr,isOpen: false});
        });
    }

    const handleShopify = async () => {
        if(isEmpty(shopifyEditForm.getFieldValue("storeUrl"))){
            message.error('Please enter store url!');
            return;
        }
        setEditLoading(true);
        const url = `${shopifyEditForm.getFieldValue('storeUrl')}.myshopify.com`;
        const f = await checkShop({ shop: url }).then(res => {
            if(!res.data.notRepeat){
                message.warning("shop already exists")
                return false
            }
            if(!res.data.installed){
                //跳转去安装
                window.open(`${process.env.REACT_APP_BASE_API}/shopify/to_install?shop=${url}`)
                return false
            }
            if(res.data.notRepeat && res.data.installed){
                return true
            }
            // window.location.href = `${process.env.REACT_APP_BASE_API}/l?shop=${url}&uid=${uid}&type=2`;
        }).catch(err => {
            console.error(err);
            return false;
        }).finally(() => {
            setEditLoading(false);
            setDialogAttr({...dialogAttr,isOpen: false});
        });
        setEditLoading(true)
        setDialogAttr({...dialogAttr,isOpen: true});
        if(f){
            shopStoreSaved({storeUrl: url, platform: 2}).then(res => {
                message.success("success");
                getShopList();
            }).catch(e => {
                console.error(e);
            }).finally(() => {
                setEditLoading(false);
                setDialogAttr({...dialogAttr,isOpen: false});
            });
        } else {
            setEditLoading(false)
        }
    }

    const handleDetermine = () => {
        if(connectCommand === 1){
            handleShopify();
        }else if (connectCommand === 2){
            handleWoocommerce();
        }
    }

    const handleInputBlur = (value,row) => {
        setLoading(true);
        setInputEdit(-1);
        c_name(row.storeId, { storeName: value }).then(res => {
            message.success("success");
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <CommonContent
            loading={loading}
            filterable={true}
            actions={[]}
            extra={[
                <Dropdown key={uuid()} trigger="click" menu={{
                    items:[{key:'1',label:'Shopify'},{key:'2',label: 'Woo Commerce'}],
                    onClick: (e) => {handleCommand(e.key)}}}>
                    <Button type="primary">
                        <Space>
                            {currentLocaleData.CommonContent.Store.filter.extra.connectStoreButton}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            ]}
        >
            <CommonTable
                loading={false}
                columns={[
                    {title:currentLocaleData.CommonContent.Store.table.columns[0],dataIndex:'storeName',key:uuid(),ellipsis: {showTitle:false},render:(_,record,index) => (
                        <Tooltip title={record.storeName} placement="bottomLeft">
                            <Input
                                key={uuid()}
                                defaultValue={record.storeName}
                                style={(inputEdit!==index)?{border:'none'}:{}}
                                autoComplete="off"
                                onFocus={(e) => {setInputEdit(index);e.target.focus();}}
                                onChange={(e) => {record.storeName=e.target.value}}
                                onBlur={(e) => {handleInputBlur(e.target.value,record)}}
                                onPressEnter={(e) => {handleInputBlur(e.target.value,record)}}
                            />
                        </Tooltip>
                    )},
                    {title:currentLocaleData.CommonContent.Store.table.columns[1],dataIndex:'storeUrl',key:uuid(),ellipsis: {showTitle:false},render:(_,record,index) => (
                        <Tooltip title={record.storeUrl} placement={'bottomLeft'}>
                            {record.storeUrl}
                        </Tooltip>
                    )},
                    {title:currentLocaleData.CommonContent.Store.table.columns[2],dataIndex:'platformType',key:uuid(),ellipsis: {showTitle:false},render:(_,record,index) => (
                        <Tooltip title={capitalizeFirstLetter(record.platformType)} placement={'bottomLeft'}>
                            {capitalizeFirstLetter(record.platformType)}
                        </Tooltip>
                    )},
                    {title:currentLocaleData.CommonContent.Store.table.columns[3],dataIndex:'storeStatus',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        // record.is_del == 0 ? <CloseOutlined style={{color:'rgb(255, 73, 73)'}}/> : <CheckOutlined style={{color:'rgb(19, 206, 102)'}}/>
                        record.storeStatus
                    )},
                    {title:currentLocaleData.CommonContent.Store.table.columns[4],dataIndex:'operating',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={currentLocaleData.CommonContent.Store.table.operation[0]} placement="bottomLeft">
                            <Button
                                key={uuid()}
                                disabled={record.platformType.toLowerCase() === 'Shopify'.toLowerCase()}
                                type="default"
                                style={{
                                    border:'none',
                                    boxShadow:'none',
                                    background:'rgba(0,0,0,0)',
                                    color:record.platformType.toLowerCase()==='Shopify'.toLowerCase()?'rgb(171,168,168)':'rgb(239,111,56)'}}
                                onClick={() => {handleEdit(record)}}
                            >
                                {currentLocaleData.CommonContent.Store.table.operation[0]}
                            </Button>
                        </Tooltip>
                    )},
                ]}
                dataSource={tableData}
                page={listQuery.page}
                total={listQuery.total}
                pageSize={listQuery.limit}
                pageChange={updatePage}
            />
            <Modal
                open={dialogAttr.isOpen}
                title={dialogAttr.title}
                okText="Determine"
                cancelText="Cancel"
                onOk={handleDetermine}
                okButtonProps={{loading:editLoading}}
                cancelButtonProps={{disabled:editLoading}}
                maskClosable={false}
                closeIcon={null}
                onCancel={() => {setDialogAttr({...dialogAttr,isOpen: false})}}
            >
                {
                    connectCommand === 2 &&
                    <Form form={woocommerceEditForm} labelCol={{xs: { span: 24 },sm: { span: 6 },}} wrapperCol={{xs: { span: 24 }, sm: { span: 17 },}}>
                        <Form.Item label="Store Name" name="storeName" rules={[{required:true,message:'Please enter store name'}]}>
                            <Input/>
                        </Form.Item>
                        {
                            !connectEdit && <Form.Item label="Store Url" name="storeUrl" rules={[{required:true,message:'Please enter store url'}]}>
                                <Input/>
                            </Form.Item>
                        }
                        <Form.Item label="Api Key" name="apiKey" rules={[{required:true,message:'Please enter api key'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Secret Key" name="apiSecret" rules={[{required:true,message:'Please enter secret key'}]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                }
                {
                    connectCommand === 1 &&
                    <Form form={shopifyEditForm}>
                        <Form.Item name="storeUrl" rules={[{required:true,message:'Please enter store url'}]}>
                            <Input addonAfter=".myshopify.com"/>
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </CommonContent>
    )
}
