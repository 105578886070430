import {Button, Form, Input, message, Modal} from "antd";
import {changePassword} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {ExclamationCircleFilled} from "@ant-design/icons";
import useStore from "../../../store/store";

export default function ChangePassword(props) {
    const navigate = useNavigate();
    const currentLocaleData = useStore(state=>state.currentLocaleData);
    const submitForm = (values) => {
        props.setLoading(true);
        changePassword(values).then(res => {
            // Modal.confirm({
            //     title:'Operation tips',
            //     content:'Password reset complete，Please click to jump to the login page!',
            //     okText:'OK',
            //     cancelText:'Cancel',
            //     icon:<ExclamationCircleFilled />,
            //     onOk:() => {
            //         navigate('/login');
            //     }
            // });
            message.success("Password reset complete")
        })
        .catch(err => console.error(err))
        .finally(() => {
            props.setLoading(false);
        });
    }

    return (
        <Form style={{fontWeight:600}} layout="vertical" onFinish={submitForm}>
            <Form.Item label={currentLocaleData.CommonContent.Settings.changePassword.forms[0]} name="oldPassword" rules={[
                {
                    required: true,
                    message: 'Please input old password!',
                },
            ]}>
                <Input style={{height:'40px'}} type="password" autoComplete="off"/>
            </Form.Item>
            <Form.Item label={currentLocaleData.CommonContent.Settings.changePassword.forms[1]} name="password" rules={[
                    {
                        required: true,
                        message: 'Please input new password!',
                    },
            ]}>
                <Input style={{height:'40px'}} type="password" autoComplete="off"/>
            </Form.Item>
            <Form.Item>
                <Button style={{height:'40px',float:'right'}} type="primary" htmlType="submit">{currentLocaleData.CommonContent.Settings.changePassword.confirmChangeButton}</Button>
            </Form.Item>
        </Form>
    )
}
