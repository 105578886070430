import CommonContent from "../../../components/CommonContent";
import CommonTable from "../../../components/CommonTable";
import {useEffect, useState} from "react";
import {Button, Flex, message, Modal, Select, Tag, Timeline, Tooltip} from "antd";
import uuid from "../../../utils/uuid";
import {getStoreList} from "../../../api/product";
import {afterSalesChanngedStatus, afterSalesList, afterSalesTimeline} from "../../../api/after";
import {afterSalesType} from "../../../api/user";
import {AfterSalesStatus, AfterSalesStatusColor} from "../../../commons/enum";
import {useNavigate} from "react-router-dom";
import useStore from "../../../store/store";

const store = new Map()
export default function AfterSale(){
    const [loading,setLoading] = useState(false);
    const [formQuery,setFormQuery] = useState({order_name:'',status:'',storeId:'',pageSize:10,pageNum:0});
    const [tableData,setTableData] = useState([]);
    const [listQuery,setListQuery] = useState({page:1,total:0,limit:10});
    const [tableTabsActiveKey,setTableTabsActiveKey] = useState('');
    const [selectedRows,setSelectedRows] = useState([]);
    const [selectIds,setSelectIds] = useState([]);
    const [selectedRowKeys,setSelectedRowKeys] = useState([]);
    const [storeList,setStoreList] = useState([]);
    const [unReadCount,setUnReadCount] = useState(0);
    const [modeList,setModeList] = useState({ '1': 'Resend', '2': 'Refund', '3': 'Return/Refund', '4': 'Other' });
    const [completedDialog,setCompletedDialog] = useState(false);
    const [completedType,setCompletedType] = useState(0);
    const [timeLineDialog,setTimeLineDialog] = useState(false);
    const [activities,setActivities] = useState([]);
    const navigate = useNavigate();
    const currentLocaleData = useStore(state => state.currentLocaleData);


    useEffect(() => {
        setLoading(true);
        (async function r(){
            const result = await getStoreList({pageNum: 1, pageSize: 999}).then((res) => {
                return res.data
            }).catch((err) => {
                console.error(err);
            })
            if(result && result.list.length){
                const list = result.list
                setStoreList(list);
                for(let i = 0;i<list.length;i++){
                    store.set(list[i].storeId, list[i])
                }
            }
            getAfterSalesList();
        })()
        setLoading(false);
    }, []);

    const getAfterSalesList = (formObj) => {
        setLoading(true);
        formQuery.pageSize = listQuery.limit;
        formQuery.pageNum = listQuery.page;
        const formData = formObj?{...formQuery,...formObj}:formQuery;
        setFormQuery({...formData});
        afterSalesList(formData).then(res => {
            setTableData(res.data.list.map((item, index) => {
                item.key = index;
                item.index = index;
                return item;
            }));
            listQuery.total += res.data.total;
            setListQuery({...listQuery});
            // setUnReadCount(res.total.unreadCount);
        }).catch(err => {
            console.log('err', err);
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleTabChange = (type,activeKey,filterShowList) => {
        formQuery.refundStatus = activeKey;
        if(activeKey !== ''){
            filterShowList['Status'] = AfterSalesStatus[String(activeKey)];
        }else{
            delete filterShowList['Status'];
        }
        setFormQuery({...formQuery});
        setTableTabsActiveKey(activeKey);
        updatePage(1,10);
        return [JSON.parse(JSON.stringify(formQuery)),JSON.parse(JSON.stringify(filterShowList))];
    }
    const handleActionsClick = (e) => {
        if(e.key === '0'){
            handleCompletedAction();
        }else if(e.key === '1'){
            handleNewInformation();
        }
    }
    const handleCompletedAction = () => {
        if (selectIds.length < 1) {
            message.error('Please select a piece of data');
        } else {
            setLoading(true);
            let isVendor = false;
            let isConsignee = false;
            selectedRows.map(item => {
                if (parseInt(item.type) === 2) {
                    // 真实买家发起处理
                    if (parseInt(item.client_status) !== 3) {
                        isConsignee = true
                    }
                    if (parseInt(item.is_push) === 3 && parseInt(item.status) !== 3) {
                        isVendor = true
                    }
                } else {
                    // C端发起处理
                    if (parseInt(item.status) !== 3) {
                        isVendor = true
                    }
                }
            })
            if (isVendor === true && isConsignee === true) {
                setLoading(false);
                setCompletedDialog(true);
                return;
            }
            handleCompleted(isVendor ? 3 : 4);
        }
    }
    const handleCompleted = (type) => {
        setCompletedType(type);
        afterSalesChanngedStatus({ id: selectIds, status: type }).then(res => {
            if (res && res.code === 200) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            getAfterSalesList();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setCompletedType(0);
            setCompletedDialog(false);
            setLoading(false);
            setSelectIds([]);
            setSelectedRows([]);
            setSelectedRowKeys([]);
        });
    }
    const handleNewInformation = () => {
        setLoading(true);
        const formQuery = {
            unread: '1',
            status: formQuery.status,
            store_url: formQuery.store_url,
            pageSize: listQuery.limit,
            pageNum: listQuery.page
        }
        afterSalesList(formQuery).then(res => {
            if (res && res.code === 200) {
                setTableData(res.data.map((item, index) => {
                    item.index = index;
                    item.key = index;
                    item.product_json = item.product_json.map((da, ik) => {
                        return da.sku_name;
                    }).join(',');
                    return item;
                }));
                listQuery.total = +res.total.totalCount;
                setListQuery({...listQuery});
            }
        }).catch(err => {
            console.log('err', err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleTableSelect = (selectedRowKeys, selectedRows) => {
        setSelectIds(selectedRows.map(item => item.id));
        setSelectedRows([...selectedRows]);
        setSelectedRowKeys([...selectedRowKeys]);
    };
    const updatePage = (page,pageSize,formObj) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getAfterSalesList(formObj);
    }
    const handleTrack = (row) => {
        setLoading(true);
        afterSalesTimeline(row.id).then(res => {
            if (res.data.length > 0) {
                setActivities(res.data);
                setTimeLineDialog(true);
            } else {
                message.warning('No timeline');
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleSearch = (e) => {
        formQuery.orderId = e.target.value;
        setFormQuery({...formQuery});
        updatePage(1,10);
    }
    const handleDetail = (row) => {
        navigate(`/after-sale-detail/${'edit'}/${row.id}/${row.orderId}`);
    }

    const handleRowSelect = (record,rowIndex) => {
        if(selectedRowKeys.includes(rowIndex)){
            setSelectedRowKeys(selectedRowKeys.filter(item => item !== rowIndex));
            setSelectedRows(selectedRows.filter(item => item !== record));
            setSelectIds(selectIds.filter(item => item !== record.id));
            return;
        }
        setSelectIds([...selectIds,record.id]);
        setSelectedRows([...selectedRows,record]);
        setSelectedRowKeys([...selectedRowKeys,rowIndex]);
    }
    return (
        <>
        <CommonContent
            filterable={true}
            loading={loading}
            tableTabsActiveKey={tableTabsActiveKey}
            searchText={currentLocaleData.CommonContent.AfterSale.searchText}
            searchEvent={handleSearch}
            tableTabs={[
                {key:'',label:currentLocaleData.CommonContent.AfterSale.tableTabs[0]},
                {key:'0',label:currentLocaleData.CommonContent.AfterSale.tableTabs[1]},
                {key:'1',label:currentLocaleData.CommonContent.AfterSale.tableTabs[2]},
                {key:'2',label:currentLocaleData.CommonContent.AfterSale.tableTabs[3]},
            ]}
            initFilterObj={formQuery}
            apply={(formObj) => {
                formObj.refundStatus = formObj.refundStatus || '';
                formObj.storeId = formObj.storeId || '';
                setTableTabsActiveKey(formObj.refundStatus);
                const filterShow = {};
                // if(formObj.refundStatus !== ''){
                //     filterShow['Status'] = formObj.refundStatus;
                // }
                if(formObj.storeId){
                    filterShow['Store'] = store.get(formObj.storeId).storeUrl
                }
                updatePage(1,10,formObj);
                return [JSON.parse(JSON.stringify(formObj)),filterShow];
            }}
            filterList={[
                {
                    name:"refundStatus",
                    title:currentLocaleData.CommonContent.AfterSale.filter.selects[0].title,
                    component:
                        <Select key={uuid()} placeholder="Please Select Status" value={formQuery.status}
                                options={[{value:'',label:currentLocaleData.CommonContent.AfterSale.filter.selects[0].options[0]},
                                    {value:'0',label:currentLocaleData.CommonContent.AfterSale.filter.selects[0].options[1]},
                                    {value:'1',label:currentLocaleData.CommonContent.AfterSale.filter.selects[0].options[2]},
                                    {value:'2',label:currentLocaleData.CommonContent.AfterSale.filter.selects[0].options[3]},]}>
                        </Select>
                },
                {
                    name:"storeId",
                    title:currentLocaleData.CommonContent.AfterSale.filter.selects[1].title,
                    component:
                        <Select showSearch key={uuid()} allowClear placeholder="Please Select Store" value={formQuery.storeUrl}
                                options={storeList && storeList.map((item,index) => {
                                    return {value: item.storeId, label: item.storeUrl};
                                })}>
                        </Select>
                }
            ]}
            actions={{
                items:[{key:'0',label:currentLocaleData.CommonContent.AfterSale.actions[0]},
                    {key:'1',label:currentLocaleData.CommonContent.AfterSale.actions[1],disabled:!unReadCount}],
                onClick:handleActionsClick
            }}
            onTabChange={handleTabChange}
        >
            <CommonTable
                loading={false}
                rowSelection={{type:'checkbox',selectedRowKeys,onChange:handleTableSelect}}
                columns={[
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[0],dataIndex:'thirdOrderId',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={record.thirdOrderId} placement="bottomLeft">
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDetail(record);
                                }}
                                style={{color:'rgb(239,111,56)',}}>
                                {record.thirdOrderId}
                            </span>
                        </Tooltip>
                    ),onCell:(record,rowIndex) => {
                            return {
                                onClick:() => {handleRowSelect(record,rowIndex)},
                                style:{
                                    cursor:'pointer'
                                }
                            }
                        }},
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[1],dataIndex:'products',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={record.products && record.products.length && record.products.map(el => el.name).join(" , ")} placement="bottomLeft">
                            {record.products && record.products.length && record.products.map(el => {
                                return <>
                                    {el.name}
                                    <br/>
                                </>
                            })}
                        </Tooltip>
                        ),onCell:(record,rowIndex) => {
                            return {
                                onClick:() => {handleRowSelect(record,rowIndex)},
                                style:{
                                    cursor:'pointer'
                                }
                            }
                        }},
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[2],dataIndex:'createTime',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={record.createTime} placement="bottomLeft">
                            {record.createTime}
                        </Tooltip>
                    ),onCell:(record,rowIndex) => {
                            return {
                                onClick:() => {handleRowSelect(record,rowIndex)},
                                style:{
                                    cursor:'pointer'
                                }
                            }
                        }},
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[3],dataIndex:'refundMode',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip
                            title={record.refundMode}
                            placement="bottomLeft"
                        >
                            {record.refundMode}
                        </Tooltip>
                    ),onCell:(record,rowIndex) => {
                            return {
                                onClick:() => {handleRowSelect(record,rowIndex)},
                                style:{
                                    cursor:'pointer'
                                }
                            }
                        }},
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[4],dataIndex:'refundStatus',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={record.refundStatus} placement="bottomLeft">
                            <Tag color={AfterSalesStatusColor[record.refundStatus]}>
                                {record.refundStatus}
                            </Tag>
                        </Tooltip>
                    ),onCell:(record,rowIndex) => {
                            return {
                                onClick:() => {handleRowSelect(record,rowIndex)},
                                style:{
                                    cursor:'pointer'
                                }
                            }
                        }},
                    {title:currentLocaleData.CommonContent.AfterSale.table.columns[6],dataIndex:'track',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                        <Tooltip title={currentLocaleData.CommonContent.AfterSale.table.operation[0]} placement="bottomLeft">
                            <Button
                                key={uuid()}
                                type="default"
                                style={{
                                    border:'none',
                                    boxShadow:'none',
                                    background:'rgba(0,0,0,0)',
                                    color:'rgb(239,111,56)'}}
                                onClick={() => {handleTrack(record)}}
                            >
                                {currentLocaleData.CommonContent.AfterSale.table.operation[0]}
                            </Button>
                        </Tooltip>
                    )},
                ]}
                dataSource={tableData}
                page={listQuery.page}
                total={listQuery.total}
                pageSize={listQuery.limit}
                pageChange={updatePage}
            />
            <Modal
                open={completedDialog}
                title="Completed"
                maskClosable={false}
                closeIcon={null}
                footer={[
                    <Button disabled={completedType!==0} type="default" onClick={() => {
                        setCompletedDialog(false);
                    }}>
                        Cancel
                    </Button>,
                    <Button
                        loading={completedType===3}
                        disabled={completedType!==3&&completedType!==0}
                        type="primary" key="vendor"
                        onClick={() => {handleCompleted(3)}}>Vendor</Button>,
                    <Button
                        loading={completedType===4}
                        disabled={completedType!==4&&completedType!==0}
                        type="primary" key="customer"
                        onClick={() => {handleCompleted(4)}}>Customer</Button>,
                    <Button
                        loading={completedType===5}
                        disabled={completedType!==5&&completedType!==0}
                        type="primary" key="all"
                        onClick={() => {handleCompleted(5)}}>All</Button>
                ]}
            >
                Are you sure to complete the current after sales information ?
            </Modal>
            <Modal
                open={timeLineDialog}
                footer={null}
                maskClosable={true}
                onCancel={() => {
                    setTimeLineDialog(false);
                }}
            >
                <Timeline style={{padding:'20px'}} items={
                    activities.length > 0 ? activities.map(item => ({
                        children:
                            <Flex vertical="vertical" gap={5}>
                                <span>{item.time}</span>
                                <span><Tag color={AfterSalesStatusColor[item.status]}>{item.status}</Tag></span>
                                <span>{item.remark}</span>
                            </Flex>
                    })) : []
                }/>
            </Modal>
        </CommonContent>
    </>
    )
}
