import "./RichText.scss"
import { Editor } from "@tinymce/tinymce-react";
import {forwardRef, memo, useEffect} from "react";

const RichText = memo(forwardRef(function RichText(props,ref) {
    return (
        <Editor
            onInit={(_evt, editor) => ref.current = editor}
            onEditorChange={(content,editor) => {
                if(document.activeElement.id !== 'rich-text_ifr'){
                    return;
                }
                const images = editor.getBody().querySelectorAll('img');
                const videos = editor.getBody().querySelectorAll('video');
                const doms = [...images,...videos];
                // let list = [];
                // doms.forEach((item) => {
                //     noDeleteKey.push(item.getAttribute('data-key'));
                // });
                props.getVideosAndImages(doms);
            }}
            apiKey="ok80klysvbs0f88f4fv1eyufevmgsndmvszil6tduo61wwpg"
            id={"rich-text"}
            init={{
                content_style:`p{
                    margin:0;
                }
                div {
                    margin:0;
                }
                pre{
                    margin:0;
                }`,
                branding:false,
                elementpath:false,
                menubar:false,
                statusbar:false,
                placeholder:'Enter something...',
                resize:false,
                width: '100%',
                min_height:200,
                max_height:200,
                plugins: 'emoticons media lists',
                toolbar: 'emoticons',
                fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                autosave_ask_before_unload:false,
                smart_paste:false,
                paste_block_drop:true,
                paste_data_images:false,
                paste_as_text:true,
                setup:function (editor) {
                    editor.on('keydown',(e) => {
                        if (!e.shiftKey && e.key === 'Enter'){
                            e.preventDefault();
                            props.send();
                        }
                        if(e.shiftKey && e.key === 'Enter'){
                            e.preventDefault();
                            editor.execCommand('InsertLineBreak');
                        }
                    })
                }
            }}
        />
    )
}))

export default RichText;
