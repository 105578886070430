import {Pagination, Spin, Table} from "antd";
import {useEffect, useState} from "react";

export default function CommonTable(props) {
    const [scrollHeight, setScrollHeight] = useState(0);
    useEffect(() => {
        const dataRowDom = document.querySelector('.data-row');
        window.removeEventListener('resize',handleResize);
        window.addEventListener('resize',handleResize);
        setTimeout(() => {
            setScrollHeight(dataRowDom.clientHeight - 110);
        },750);
        return () => {
            window.removeEventListener('resize',handleResize);
        }
    },[props.dataSource]);
    function handleResize() {
        const dataRowDom = document.querySelector('.data-row');
        setScrollHeight(dataRowDom.clientHeight - 110);
    }
    return (
        <Spin spinning={props.loading}>
            <Table
                onRow={(record) => {
                    return {
                        onClick:(e) => {props.onRowClick && props.onRowClick(record,e)}
                    }
                }}
                rowSelection={props.rowSelection}
                columns={props.columns}
                dataSource={props.dataSource}
                pagination={false}
                scroll={{y:scrollHeight}}
            >
            </Table>
            <Pagination
                showSizeChanger
                style={{marginTop:'15px',display:"flex",justifyContent:'flex-end',alignItems:'center'}}
                showTotal={(total) => `Total ${total} items`}
                current={props.page}
                total={props.total}
                pageSize={props.pageSize}
                onChange={props.pageChange}
            />
        </Spin>
    )
}
