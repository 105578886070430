import axios from 'axios'
import { getToken, removeToken } from './auth'
import {message, Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";

const invalidTokenCode = "401";

const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_API, // url = base url + request url
    timeout: 60000, // request timeout
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'       //formdata格式
        'Content-Type': 'application/json' // json格式
    }
})
service.interceptors.request.use(
    config => {
        const token = getToken()
        config.headers.Authorization = token ? ("Bearer " + token) : ""
        return config
    }
)
service.interceptors.response.use(
    //  获取http信息，如头信息或状态信息
    response => {
        if(response.status === 200){
            const result = response.data
            if(result.success){
                return Promise.resolve(result)
            } else {
                message.open({
                    content: result.msg || 'Error Request',
                    duration:3,
                    icon:<ExclamationCircleFilled/>,
                    style:{
                        color:'red',
                    }
                });
                return Promise.reject(result.msg)
            }
        } else {
            const result = response.data
            if(result.code.indexOf(invalidTokenCode) > -1){
                message.open({
                    content: "Invalid authentication, please login again!",
                    duration:3,
                    icon:<ExclamationCircleFilled/>,
                    style:{
                        color:'red',
                    }
                });
                removeToken()
                window.location.href = '/login';
            }
            return Promise.reject(result)
        }
         // token失效
        //  Modal.confirm({
        //     title:'Tips',
        //     content:'Invalid authentication, please login again!',
        //     type:'warning',
        //     icon:<ExclamationCircleFilled/>,
        //     okText:'Re Login',
        //     autoFocusButton:null,
        //     keyboard:false,
        //     footer:(_,{OkBtn,CancelBtn}) => (
        //         <OkBtn/>
        //     ),
        //     onOk:() => {
        //         resetToken().then(() => {
        //             window.location.href = '/login';
        //             console.log('click this re login');
        //         })
        //     },
        // })
    },
    error => {
        console.log(error)
        if(error.response && error.response.status === 401){
            message.open({
                content: "Invalid authentication, please login again!",
                duration:3,
                icon:<ExclamationCircleFilled/>,
                style:{
                    color:'red',
                }
            });
            removeToken()
            window.location.href = '/login';
        } else {
            message.open({
                content: error.message,
                duration:3,
                icon:<ExclamationCircleFilled/>,
                style:{
                    color:'red',
                }
            })
        }
        return Promise.reject(error)
    }
)
export default service
