import FbAliLayout from "../layout/FbAliLayout";
import Side from "./sider/Side";
import Header from "./header/Header";
import SideFooter from "./sider/SideFooter";
import useStore from "../store/store";
import {searchByKey} from "../commons/menus";
import dayjs from 'dayjs'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {memo, useEffect} from "react";
import Intercept from "./intercept/Intercept";
dayjs.locale('en-us');
const Index = memo(function Index(){
    const location = useLocation();
    const updateNavTitle = useStore(state => state.updateNavTitle);
    const currentLocaleData = useStore(state => state.currentLocaleData);
    useEffect(() => {
        const currentMenuItem = searchByKey([...currentLocaleData.Side.menus,...currentLocaleData.Side.detailMenus],location.pathname);
        updateNavTitle(currentMenuItem.text);
    }, [location.pathname,currentLocaleData]);

    return (
        <FbAliLayout
            header={<Header/>}
            side={<Side/>}
            content={<Intercept><Outlet/></Intercept>}
            sideFooter={
                (collapsed) => <SideFooter collapsed={collapsed}/>
            }>
        </FbAliLayout>
    )
})
export default Index;
