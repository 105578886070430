import {Button, Tooltip} from "antd";
import './Side.scss'
import {DownloadOutlined} from '@ant-design/icons'
import useStore from "../../store/store";
export default function SideFooter(props) {
    const currentLocaleData = useStore(state => state.currentLocaleData);
    return (
        <div className="side-footer">
            {/* {
                props.collapsed ?
                    <Tooltip placement="right" title={currentLocaleData.Side.extensionButton}>
                        <Button
                            className="download-btn"
                            download
                            type="link" href="https://dwn.fbali.co/download/dist-1.0.0.zip"
                            icon={<DownloadOutlined />}>
                        </Button>
                    </Tooltip>:
                    <Button
                        className="download-btn"
                        download
                        type="link" href="https://dwn.fbali.co/download/dist-1.0.0.zip"
                        icon={<DownloadOutlined />}>
                        {currentLocaleData.Side.extensionButton}
                    </Button>
            } */}
        </div>
    )
}
