export const getExpandKeysWithPath = (pathname) => {
    const keys = pathname.split('/');
    let str = '';
    const expandKeys = []
    keys.forEach((item) => {
        str += item?`/${item}`:'';
        if(str){
            expandKeys.push(str);
        }
    });
    return expandKeys;
}

export const searchByKey = (allMenus,key) => {
    const tasks = [];
    tasks.push(...allMenus);
    while (tasks.length > 0) {
        if (tasks[0].children && tasks[0].children.length > 0) {
            tasks.push(...tasks[0].children);
        }
        if (tasks[0].path.split('/')[1] === key.split('/')[1]) {
            return tasks[0];
        }
        tasks.splice(0, 1);
    }
    return {};
}
