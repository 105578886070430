import {Menu,Layout} from "antd";
import './Side.scss'
import {useLocation, useNavigate} from "react-router-dom";
import useStore from "../../store/store";
import {memo, useEffect, useState} from "react";

const Side = memo(function Side(){
    const navigate= useNavigate();
    const updateNavTitle = useStore(state => state.updateNavTitle);
    const [selectedKey, setSelectedKey] = useState('1');
    const location = useLocation();
    const currentLocaleData = useStore(state => state.currentLocaleData);
    useEffect(() => {
        const isInSide = [...currentLocaleData.Side.menus,...currentLocaleData.Side.detailMenus].filter(item => {
            return item.path === `/${location.pathname.split('/')[1]}`;
        });
        if(isInSide.length > 0){
            setSelectedKey(isInSide[0].key);
        }
    }, [location.pathname]);
    const handleItemClick = ({item,key,keyPath}) => {
        navigate(item.props.path);
        updateNavTitle(item.props.text);
        setSelectedKey(key);
    }
    return (
        <Layout className="side-menus">
            <Menu mode="inline"
                  selectedKeys={[selectedKey]}
                  items={currentLocaleData.Side.menus}
                  onClick={handleItemClick}/>
        </Layout>
    )
})

export default Side;
