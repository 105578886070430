import {message, Row, Select, Button, Modal, Switch, Flex, DatePicker, Form, Input, InputNumber} from "antd";
import {useEffect, useState} from "react";
import {getStoreList, syncStoreData} from "../../../api/product";
import {autoFulfill, getOrderList, pullOrder, orderStatus} from "../../../api/orders";
import {columns,pageTabs,tableTabs} from "./render";
import uuid from "../../../utils/uuid";
import CommonTable from "../../../components/CommonTable";
import CommonContent from "../../../components/CommonContent";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import useStore from "../../../store/store";
import dayjs from "dayjs";
import {PayStatus, ProductOrderStatus} from "../../../commons/enum";
import {findUserBalance} from "../../../api/user";

const store = new Map()
export default function Orders() {
    const [storeList, setStoreList] = useState([]);
    const [tableData, setTableData] = useState();
    const [loading,setLoading] = useState(false);
    const [formQuery, setFormQuery] = useState({thirdCreateTimeAfter: "", thirdCreateTimeBefore: "", platformType:'2',thirdOrderId:'',processStatus:'',payStatus:'',storeId:'',pageNum:0,pageSize:10});
    const [listQuery, setListQuery] = useState({total:0,page:1,limit:10});
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [selectedRowKeys,setSelectedRowKeys] = useState([]);
    const [selectRows,setSelectedRows] = useState([]);
    const [tableTabsActiveKey,setTableTabsActiveKey] = useState('');
    const navigate = useNavigate();
    const currentLocaleData = useStore(state => state.currentLocaleData);
    let balance = useStore(state=>state.balance);
    const autoProcess = useStore(state=>state.autoProcess);
    const updateAutoProcess = useStore(state=>state.updateAutoProcess);
    const [show, setShow] = useState(false)
    const [pullOrderLoading, setPullOrderLoading] = useState(false)
    const [form] = Form.useForm();
    const updateBalance = useStore(state=>state.updateBalance);
    
    useEffect(() => {
        (async function(){
            const result = await getStoreList({pageNum: 1, pageSize: 999}).then((res) => {
                return res.data
            }).catch((err) => {
                console.error(err);
            });
            const list = result ? result.list : []
            for(let i = 0;i<list.length;i++){
                store.set(list[i].storeId, list[i])
            }
            setStoreList(list)
            getOrders();
            getBalance()
        }())
    }, []);

    const getBalance = async ()=>{
        findUserBalance().then(res=>{
            updateBalance(res.data)
        }).catch(err => {
            console.error(err);
        })
    }

    // 获取订单数据
    const getOrders = async (formObj) => {
        setLoading(true);
        formQuery.pageSize = listQuery.limit;
        formQuery.pageNum = listQuery.page;
        const formData = formObj?{...formQuery,...formObj}:formQuery;
        setFormQuery({...formData});
        const {thirdCreateTimeAfter, thirdCreateTimeBefore,platformType,thirdOrderId,processStatus,payStatus,storeId,pageNum,pageSize} = formData;
        const result = await getOrderList({thirdCreateTimeAfter, thirdCreateTimeBefore,platformType,thirdOrderId,processStatus,payStatus,storeId,pageNum,pageSize}).then(res => {
            return res.data
        }).catch(err => {
            console.error(err);
        })
        if(result){
            const list = result.list
            setTableData(list.map((item, index) => {
                item.index = index;
                item.key = index;
                return item;
            }))
            listQuery.total = +result.total;
            setListQuery({...listQuery});
        }
        setLoading(false);
    }

    const handleDetail = (record) => {
        navigate(`/order-detail/${record.orderId}/${formQuery.platformType}/${record.processStatus}`);
    }

    // 标签页切换事件
    const handleTabChange = (type,activeKey,filterShowList) => {
        let copyForm;
        let initFormQuery;
        if(type === 1){
            initFormQuery = {platformType:activeKey,thirdOrderId:'',processStatus:'',payStatus:'',storeId:'',pageNum:0,pageSize:10};
            setFormQuery({...initFormQuery});
            setTableTabsActiveKey('');
            filterShowList = {};
            copyForm = JSON.parse(JSON.stringify(initFormQuery));
        }else{
            formQuery.processStatus = activeKey;
            setFormQuery({...formQuery});
            setTableTabsActiveKey(activeKey);
            if(filterShowList['Order Status'] !== ProductOrderStatus[activeKey]){
                filterShowList && delete filterShowList['Order Status'];
            }
            copyForm = JSON.parse(JSON.stringify(formQuery));
            // copyForm.dateObj_range = formQuery.date_range.length>0?[dayjs(formQuery.date_range[0]),dayjs(formQuery.date_range[1])]:[];
        }
        updatePage(1,10,initFormQuery);
        getBalance()
        return [copyForm,JSON.parse(JSON.stringify(filterShowList))];
    }

    // 表格多选
    const handleTableSelect = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys([...selectedRowKeys]);
        setSelectedOrderIds([...selectedRows.map(item => item.id)]);
        setSelectedRows([...selectedRows]);
    };

    // 重置分页
    const updatePage = (page,pageSize,formObj)=> {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getOrders(formObj);
    }

    // 搜索框
    const handleSearch = (event) => {
        formQuery.thirdOrderId = event.target.value;
        setFormQuery(formQuery);
        updatePage(1,10);
    }

    const handleRowSelect = (record,rowIndex) => {
        if (selectedRowKeys.includes(rowIndex)){
            setSelectedRowKeys([...selectedRowKeys.filter(item => item !== rowIndex)]);
            setSelectedOrderIds([...selectedOrderIds.filter(item => item !== record.id)]);
            setSelectedRows([...selectRows.filter(item => item.id !== record.id)]);
            return;
        }
        setSelectedRows([...selectRows,record]);
        setSelectedRowKeys([...selectedRowKeys,rowIndex]);
        setSelectedOrderIds([...selectedOrderIds,record.id]);
    }

    const handleAutoPush = (checked) => {
        setLoading(true);
        autoFulfill({ auto: checked }).then(res => {
            message.success('Change Status Success!');
            updateAutoProcess(checked);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleTopUp = () => {
        Modal.confirm({
            title:currentLocaleData.CommonContent.Orders.dialog.title,
            content:currentLocaleData.CommonContent.Orders.dialog.content,
            footer:(_,{OkBtn,CancelBtn}) => {
                return <OkBtn/>;
            },
            closable:true,
            okText:currentLocaleData.CommonContent.Orders.dialog.okText,
            maskClosable:true,
        });
    }

    const handleOrderStatus = (url,record,index) => {
        setLoading(true);
        orderStatus(url).then(res => {
            message.success(res.msg);
            getOrders();
        }).catch(err=>{
            console.error(err)
        }).finally(() => {
            setLoading(false);
        });
    }

    const onFinish = (values) => {
        let ids = []
        console.log(values)
        if(values.orderId){
            ids = values.orderId.replace(/\s/g, "").split(",").filter((el)=> el !== "")
        }
        setPullOrderLoading(true)
        pullOrder({storeId: values.storeId, thirdOrderIds: ids}).then(res=>{
            message.success(res.msg)
        }).catch(err => console.error(err)).finally(()=>{
            form.resetFields()
            setPullOrderLoading(false)
            setShow(false)
        })
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const tagClicks = {
        'fulfill order': (record,index) => {handleOrderStatus(`/order/${record.orderId}/fulfill`,record,index)},
        'top-up': (record,index) => {handleTopUp()},
        'ask sourcing': (record,index) => {handleOrderStatus(`/order/${record.orderId}/ask_sourcing`,record,index)},
    }

    return (
        <>
            <CommonContent
                defaultActiveKey={"2"}
                loading={loading}
                filterable={true}
                initFilterObj={formQuery}
                searchText={currentLocaleData.CommonContent.Orders.searchText}
                searchEvent={handleSearch}
                pageTabs={pageTabs(currentLocaleData.CommonContent.Orders.pageTabs)}
                tableTabsActiveKey={tableTabsActiveKey}
                tableTabs={tableTabs(currentLocaleData.CommonContent.Orders.tableTabs)}
                tabBarExtra={
                    <Flex gap={10} align="center" justify="center">
                        <span style={{fontWeight:'500'}}>{currentLocaleData.CommonContent.Orders.pageTabExtra.switch.label}</span>
                        <Switch onChange={handleAutoPush} style={{height: 'auto'}} checked={autoProcess} checkedChildren={currentLocaleData.CommonContent.Orders.pageTabExtra.switch.onText}
                                unCheckedChildren={currentLocaleData.CommonContent.Orders.pageTabExtra.switch.offText}/>
                        <Button type="primary" onClick={()=>setShow(true)}>Sync Order</Button>
                    </Flex>
                }
                onTabChange={handleTabChange}
                apply={(formObj) => {
                    formObj.processStatus = formObj.processStatus || '';
                    formObj.payStatus = formObj.payStatus || '';
                    if(formObj.date_range && formObj.date_range.length>0){
                        formObj.date_range =  [dayjs(formObj.date_range[0]).format("YYYY-MM-DD HH:mm:ss"),dayjs(formObj.date_range[1]).format("YYYY-MM-DD HH:mm:ss")]
                    } else {
                        formObj.date_range = []
                        formObj.thirdCreateTimeAfter = ""
                        formObj.thirdCreateTimeBefore = ""
                    }
                    formObj.storeUrl = formObj.storeId ? store.get(formObj.storeId).storeUrl : '';
                    setTableTabsActiveKey(formObj.processStatus);
                    const filterShow = {};
                    if(formObj.date_range.length>0){
                        filterShow['Date Range'] = `${formObj.date_range[0]} - ${formObj.date_range[1]}`;
                        formObj.thirdCreateTimeAfter = formObj.date_range[0]
                        formObj.thirdCreateTimeBefore = formObj.date_range[1]
                    }
                    if(formObj.processStatus){
                        filterShow['Order Status'] = ProductOrderStatus[formObj.processStatus];
                    }
                    if(formObj.payStatus){
                        filterShow['FbAli Payment'] = PayStatus[formObj.payStatus];
                    }
                    if(formObj.storeId){
                        filterShow['Store'] = store.get(formObj.storeId).storeUrl;
                    }
                    const copyForm = JSON.parse(JSON.stringify(formObj));
                    copyForm.date_range = formObj.date_range.length>0?[dayjs(formObj.date_range[0]),dayjs(formObj.date_range[1])]:[];
                    updatePage(1,10,formObj);
                    return [copyForm,filterShow];
                }}
                filterList={[
                    {
                        name:"date_range",
                        title:currentLocaleData.CommonContent.Orders.filter.ranges[0],
                        component:
                            <DatePicker.RangePicker
                                style={{width:'100%'}}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                    },
                    {
                        name:"processStatus",
                        title: currentLocaleData.CommonContent.Orders.filter.selects[0].title,
                        component:
                            <Select placeholder="Please Select Order Status"
                                options={[{value: '', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[0]},
                                    {value: '1', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[1]},
                                    {value: '-1', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[2]},
                                    {value: '0', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[3]},
                                    {value: '2', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[4]},
                                    {value: '3', label: currentLocaleData.CommonContent.Orders.filter.selects[0].options[5]}]}>
                        </Select>
                },
                {
                    name:"payStatus",
                    title: currentLocaleData.CommonContent.Orders.filter.selects[1].title,
                    component:
                        <Select allowClear placeholder="Please Select Payment Status"
                                options={[{key: uuid(),value: '0', label: currentLocaleData.CommonContent.Orders.filter.selects[1].options[0]},
                                    {key: uuid(),value: '1', label: currentLocaleData.CommonContent.Orders.filter.selects[1].options[1]},
                                    {key: uuid(),value: '2', label: currentLocaleData.CommonContent.Orders.filter.selects[1].options[2]},
                                    {key: uuid(),value: '4', label: currentLocaleData.CommonContent.Orders.filter.selects[1].options[3]},]}>
                        </Select>
                },
                {
                    name:"storeId",
                    title: currentLocaleData.CommonContent.Orders.filter.selects[2].title,
                    component:
                        <Select  placeholder="Please Select Store" allowClear
                                options={storeList && storeList.map((item, index) => {
                                    return {value: item.storeId, label: item.storeUrl}})}>
                        </Select>,
                }
            ]}
            headerButton={[
                <Button onClick={handleTopUp} className="purple-btn" key={uuid()} style={{height:'40px',fontSize:'16px',fontWeight:'600'}}>{currentLocaleData.CommonContent.Orders.headerExtra[0]}</Button>,
                <Row key={uuid()} style={{display:"flex",flexDirection:'row',justifyContent:'center',height:'39px',background:"rgb(255,255,255)",borderRadius:'5px',padding:"0 5px",lineHeight:'40px'}}>
                    <span style={{fontWeight:'500',textAlign:'center'}}>{currentLocaleData.CommonContent.Orders.headerExtra[1]}:&nbsp;&nbsp;</span>
                    <span style={{fontWeight:'700',textAlign:'center',color:'rgb(239,111,56)',fontSize:"18px"}}>${String(balance||'0.00')}</span>
                </Row>
            ]}
            >
                <CommonTable
                    loading={false}
                    rowSelection={{type:'checkbox',selectedRowKeys,onChange:handleTableSelect}}
                    columns={columns(handleDetail,handleRowSelect,currentLocaleData.CommonContent.Orders.table.columns,tagClicks,autoProcess)}
                    dataSource={tableData}
                    page={listQuery.page}
                    total={listQuery.total}
                    pageSize={listQuery.limit}
                    pageChange={updatePage}
                >
                </CommonTable>
            </CommonContent>
            <Modal open={show} onCancel={()=>setShow(false)} footer={null} bodyStyle={{padding: '30px'}}>
                <p>Please enter the order number(s) in the box below. If there are multiple order numbers, separate them with a comma ",".</p>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ orderId: "", storeId: "" }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    >
                    <Form.Item
                        label="Order No"
                        name="orderId"
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Store Url"
                        name="storeId"
                        rules={[{ required: true, message: 'Please input your store url!' }]}
                    >
                        <Select placeholder="Please Select Store" allowClear
                                options={storeList && storeList.map((item, index) => {
                                    return {value: item.storeId, label: item.storeUrl}})}>
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" loading={pullOrderLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
