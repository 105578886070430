import './Detail.scss'
import CommonDetail from "../../../components/CommonDetail";
import {
    Button,
    Col,
    Descriptions,
    Divider,
    Flex,
    Form,
    Image,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOrderInfo} from "../../../api/orders";
import {
    NewOrderStatusColor,
    OrderStatusClient,
    OrderStatusClientColor,
    ProductOrderStatus
} from "../../../commons/enum";
import {afterSalesCreate, afterSalesType} from "../../../api/after";
import uuid from "../../../utils/uuid";
import error_img from "../../../commons/img_error";
import useStore from "../../../store/store";
import Decimal from "decimal.js"

function toDecimal(num) {
    return new Decimal(num)
}

function countPrice(a1, a2, a3){
    let count = toDecimal(0);
    if(a1){
        a1 = toDecimal(a1)
        count = count.plus(a1)
    }
    if(a2){
        a2 = toDecimal(a2)
        count = count.plus(a2)
    }
    if(a3){
        a3 = toDecimal(a3)
        count = count.plus(a3)
    }
    return count.toNumber()
}

function calcPercent(n1, n2){
    if(n1 == 0 || n2 == 0){
        return "0.00%"
    }
    if(n1 && n2){
        return ((n1/n2) * 100).toFixed(2) + "%"
    }
    return "0.00%"
}

function calcProfit(n1, n2){
    n1 = n1 || 0
    n2 = n2 || 0
    return toDecimal(n1).minus(n2).toNumber()
}

function calcShip(n1, n2, n3, n4){
    n1 = n1 || 0
    n2 = n2 || 0
    n3 = n3 || 0
    n4 = n4 || 0
    return toDecimal(n1).minus(n2).minus(n3).plus(n4).toNumber()
}

const currencySymbolMap = {
    "USD": "$",
    "CNY": "¥",
    "EUR": "€",
    "GBP": "￡",
}

const productMap = new Map()

export default function OrderDetail(){
    const params = useParams();
    const [loading,setLoading] = useState(false);
    const [detailInfo,setDetailInfo] = useState({});
    const [typeList,setTypeList] = useState([]);
    const [showDialog,setShowDialog] = useState(false);
    const navigate = useNavigate();
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [formRef] = Form.useForm();
    const [productsList,setProductsList] = useState([]);
    const name = useStore(state => state.name);
    useEffect(() => {
        setLoading(true);
        getOrderInfo(params.id).then(res => {
            setDetailInfo(res.data);
            // const item = res.data;
            // const Subtotal = subtotal(item);
            // let totals = numFilter(Subtotal[item.order_no].sub + Number(item.shipping_total) + Number(item.discount_total) + Number(item.total_tax))
            // let totals_cost = numFilter(Number(item.vendor.product_price) + Number(item.vendor.shipping_price) + Number(item.vendor.service_price))
            // let percentage = numFilter(totals_cost / totals) * 100
            // let profits = numFilter(totals - totals_cost)
            // arrays[item.order_no] = {
            //     "totals": totals,
            //     "totals_cost": totals_cost,
            //     "percentage": percentage,
            //     "profits": profits
            // }
            // setArrays({...arrays});
            afterSalesType().then(res => {
                setTypeList(res.data);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    function subtotal(data) {
        let y = toDecimal(0);
        data.forEach((el,index) => {
            y = y.plus(el.total)
        });
        return y.toNumber()
    }

    const handleAfterSales = () => {
        setShowDialog(true);
        if (detailInfo.processingRefundId) {
            navigate(`/after-sale-detail/${"edit"}/${detailInfo.processingRefundId}/${detailInfo.orderId}`);
        } else {
            for(let i=0;i<detailInfo.orderLines.length;i++){
                const el = detailInfo.orderLines[i]
                productMap.set(el.id, el)
            }
            setProductsList([...detailInfo.orderLines])
            setShowDialog(true);
        }
    }

    const afterSales = () => {
        const formVals = formRef.getFieldsValue();
        if(!formVals.applyReason || !formVals.refundMode || !formVals.products){
            message.warning('Please complete the required selections...');
            return;
        }
        const data = {
            orderId: detailInfo.orderId,
            applyReason: formVals.applyReason,
            refundMode: formVals.refundMode,
            refundLines: formVals.products.map(el =>({
                orderLineId: el,
                refundQuantity: productMap.get(el).quantity
            }))
        }
        afterSalesCreate(data).then(res => {
            message.success(res.msg);
            navigate(`/after-sale-detail/${"edit"}/${res.data}/${detailInfo.orderId}`);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setShowDialog(false);
        });
    }

    const convertMoney = (s, n)=>{
        if(currencySymbolMap[s]){
            return currencySymbolMap[s] + n
        } else {
            return n
        }
    }

    return (
        <CommonDetail
            loading={loading}
        >
            <Flex vertical="vertical" gap={15} style={{height:'100%'}}>
                <Row className="order-detail-title">
                    <Col span={6}>
                        <span className="wrap-span">{currentLocaleData.CommonContent.Orders.detail.header.title[0]}</span>
                        <span className="wrap-span" style={{color: 'rgb(239,111,56)'}}>{detailInfo.thirdOrderNo}</span>
                    </Col>
                    <Col span={1}>
                        <div style={{height:'60%',width:'2px',background:'rgb(239,111,56)'}}/>
                    </Col>
                    <Col span={6}>
                        <span className="wrap-span">{currentLocaleData.CommonContent.Orders.detail.header.title[1]}</span>
                        <span className="wrap-span">
                        <Tag color={NewOrderStatusColor[params.status]}>
                            {params.status}
                        </Tag>
                    </span>
                    </Col>
                    <Col span={1}>
                        <div style={{height: '60%', width: '2px', background: 'rgb(239,111,56)'}}/>
                    </Col>
                    <Col span={6}>
                        <span className="wrap-span">{currentLocaleData.CommonContent.Orders.detail.header.title[2]}</span>
                        <span className="wrap-span">{detailInfo.thirdCreateTime}</span>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" onClick={handleAfterSales}>{currentLocaleData.CommonContent.Orders.detail.header.afterSalesButton}</Button>
                    </Col>
                </Row>
                <Flex className="order-detail-content" align="center" vertical="vertical" gap={15}>
                    {
                        detailInfo.logisticList && detailInfo.logisticList.length > 0 ? detailInfo.logisticList.map((el, index) => 
                        <Descriptions key={index} title={currentLocaleData.CommonContent.Orders.detail.description.shipments.title} items={[
                            {key:'1',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[0],children: el.logisticNo},
                            {key:'2',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[1],children: el.shippedDate},
                            {key:'3',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[2],children: el.logisticCompany},
                            {key:'4',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[3],children:detailInfo.shipping.country},
                        ]}/>) : <Descriptions title={currentLocaleData.CommonContent.Orders.detail.description.shipments.title} items={[
                            {key:'1',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[0],children: ""},
                            {key:'2',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[1],children: ""},
                            {key:'3',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[2],children: ""},
                            {key:'4',label:currentLocaleData.CommonContent.Orders.detail.description.shipments.items[3],children: ""},
                        ]}/>
                    }
                    <Descriptions title={currentLocaleData.CommonContent.Orders.detail.description.items.title} items={[
                        {key:'1',label:'',children:
                            <Table
                                columns={[
                                    {title: currentLocaleData.CommonContent.Orders.detail.description.items.table.columns[0],dataIndex: 'imageSrc',key: uuid(),render: (_, record) => (
                                            <Image width={40} src={record.imageSrc} fallback={error_img}/>
                                        )},
                                    {title: currentLocaleData.CommonContent.Orders.detail.description.items.table.columns[1],dataIndex: 'name',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
                                            <Tooltip placement="bottomLeft" title={record.name}>{record.name}</Tooltip>
                                        )},
                                    {title: currentLocaleData.CommonContent.Orders.detail.description.items.table.columns[2],dataIndex: 'logisticStatus',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
                                            <Tooltip placement="bottomLeft" title={record.logisticStatus}>{record.logisticStatus}</Tooltip>
                                        )},
                                    {title: currentLocaleData.CommonContent.Orders.detail.description.items.table.columns[3],dataIndex: 'quantity',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
                                            <Tooltip placement="bottomLeft" title={record.quantity}>{record.quantity}</Tooltip>
                                        )},
                                    {title: currentLocaleData.CommonContent.Orders.detail.description.items.table.columns[4],dataIndex: 'price',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
                                            <Tooltip placement="bottomLeft" title={record.price + ' x ' + record.quantity}>
                                                <div>
                                                    <span>{ convertMoney(detailInfo.currency, record.price) + ' x ' + record.quantity}</span>
                                                    <span style={{marginLeft: "15px"}}>{ detailInfo.currency }</span>
                                                </div>
                                            </Tooltip>
                                        )},
                                ]}
                                dataSource={detailInfo.orderLines}
                            />
                        },
                    ]}/>
                    <Descriptions column={1} title={currentLocaleData.CommonContent.Orders.detail.description.total.title} contentStyle={{justifyContent:'right'}} items={[
                        {key: '0', children: <Divider style={{margin: '10px 0'}}/>},
                        {key:'1',label:currentLocaleData.CommonContent.Orders.detail.description.total.items[0],children:<Flex flex={1} justify="end"><span style={{flex:1,textAlign:'center'}}>
                                {`${detailInfo.orderLines && detailInfo.orderLines.length} ${currentLocaleData.CommonContent.Orders.detail.description.total.items[1]}`}
                        </span><span>{detailInfo.orderLines && detailInfo.orderLines.length > 0 ? subtotal(detailInfo.orderLines) : ""}</span></Flex>},
                        {key:'2',label:currentLocaleData.CommonContent.Orders.detail.description.total.items[2],children:detailInfo.total ? calcShip(detailInfo.total, subtotal(detailInfo.orderLines), detailInfo.tax, detailInfo.discount) : '0.00'},
                        {key:'3',label:currentLocaleData.CommonContent.Orders.detail.description.total.items[3],children:detailInfo.discount ? detailInfo.discount : '0.00'},
                        {key:'4',label:currentLocaleData.CommonContent.Orders.detail.description.total.items[4],children:detailInfo.tax ? detailInfo.tax : '0.00'},
                        {key: '5', children: <Divider style={{margin: '10px 0'}}/>},
                        {key:'6',label:<span style={{color: "black"}}>{currentLocaleData.CommonContent.Orders.detail.description.total.items[5]}</span>,children: detailInfo.total && detailInfo.total},
                    ]}/>
                    <Descriptions contentStyle={{justifyContent: 'right'}} column={1} title={currentLocaleData.CommonContent.Orders.detail.description.cost.title} items={[
                        {key: '0', children: <Divider style={{margin: '10px 0'}}/>},
                        {
                            key: '1',
                            label: currentLocaleData.CommonContent.Orders.detail.description.cost.items[0],
                            children: detailInfo.fabliProductFee || "0.00"
                        },
                        {
                            key: '2',
                            label: currentLocaleData.CommonContent.Orders.detail.description.cost.items[1],
                            children: detailInfo.fabliShippingFee || "0.00"
                        },
                        {
                            key: '3',
                            label: currentLocaleData.CommonContent.Orders.detail.description.cost.items[2],
                            children: detailInfo.fabliServiceFee || "0.00"
                        },
                        {key: '4', children: <Divider style={{margin: '10px 0'}}/>},
                        {
                            key: '5',
                            label: <span style={{color: "black"}}>{currentLocaleData.CommonContent.Orders.detail.description.cost.items[3]}</span>,
                            children: countPrice(detailInfo.fabliProductFee, detailInfo.fabliShippingFee, detailInfo.fabliServiceFee) || "0.00"
                        },
                    ]}/>
                    <Descriptions
                        column={1}
                        labelStyle={{fontSize: '18px', fontWeight: 600, color: 'black', alignItems: 'center'}}
                        contentStyle={{justifyContent: 'right', fontSize: '2rem'}}
                        items={[
                            {
                                key: '1',
                                label: currentLocaleData.CommonContent.Orders.detail.description.percentage.items[0],
                                children: calcPercent(countPrice(detailInfo.fabliProductFee, detailInfo.fabliShippingFee, detailInfo.fabliServiceFee), detailInfo.total)
                            },
                            {key: '2', children: <Divider style={{margin: '5px 0'}}/>},
                            {
                                key: '3',
                                label: currentLocaleData.CommonContent.Orders.detail.description.percentage.items[1],
                                children: calcProfit(detailInfo.total, countPrice(detailInfo.fabliProductFee, detailInfo.fabliShippingFee, detailInfo.fabliServiceFee))
                            },
                        ]}
                    />
                </Flex>
            </Flex>
            <Modal
                open={showDialog}
                maskClosable={true}
                title={currentLocaleData.CommonContent.Orders.detail.dialog.title}
                okText={currentLocaleData.CommonContent.Orders.detail.dialog.okButton}
                cancelText={currentLocaleData.CommonContent.Orders.detail.dialog.cancelButton}
                onOk={afterSales}
                onCancel={() => {setShowDialog(false)}}
            >
                <Form form={formRef} labelCol={{xs: { span: 24 },sm: { span: 6 },}} wrapperCol={{xs: { span: 24 }, sm: { span: 14 },}}>
                    <Form.Item rules={[{required:true,message:currentLocaleData.CommonContent.Orders.detail.dialog.form.rules[0]}]}
                               label={currentLocaleData.CommonContent.Orders.detail.dialog.form.labels[0]} name="applyReason">
                        <Select options={typeList && typeList.map((item,index) => {
                            return {label: item.label ,value:item.value}
                        })}></Select>
                    </Form.Item>
                    <Form.Item rules={[{required:true,message:currentLocaleData.CommonContent.Orders.detail.dialog.form.rules[1]}]}
                               label={currentLocaleData.CommonContent.Orders.detail.dialog.form.labels[1]} name="refundMode">
                        <Select options={[
                            {label: currentLocaleData.CommonContent.Orders.detail.dialog.form.modeSelect.options[0], value: '1'},
                            {label: currentLocaleData.CommonContent.Orders.detail.dialog.form.modeSelect.options[1], value: '0'},
                            {label: currentLocaleData.CommonContent.Orders.detail.dialog.form.modeSelect.options[2], value: '2'}].map((item,index) => {
                            return {label:item.label,value:item.value}
                        })}></Select>
                    </Form.Item>
                    <Form.Item rules={[{required:true,message:currentLocaleData.CommonContent.Orders.detail.dialog.form.rules[2]}]}
                               label={currentLocaleData.CommonContent.Orders.detail.dialog.form.labels[2]} name="products">
                        <Select mode="multiple" options={productsList && productsList.map((item,index) => {
                            return {label:item.name,value:item.id}
                        })}></Select>
                    </Form.Item>
                </Form>
            </Modal>
        </CommonDetail>
    )
}
