import Icon from "./Icon";
import {Button, Tag, Tooltip} from "antd";

export const Theme = {
    components:{
        Button:{
            defaultHoverBorderColor:'rgb(239,111,56)',
            defaultHoverColor:'rgb(239,111,56)',
            defaultActiveBorderColor:'rgb(239,111,56)',
            defaultActiveColor:'rgb(239,111,56)',
            colorPrimary: 'rgb(239,111,56)',
            colorPrimaryHover:'rgba(239,111,56,.6)',
            colorPrimaryActive:'rgb(239,111,56)',
            colorLink:'rgb(239,111,56)',
            colorLinkActive:'rgba(239,111,56,.5)',
            colorLinkHover:'rgba(239,111,56,.5)',
            colorPrimaryBorder:'rgb(239,111,56)',
        },
        Menu:{
            itemActiveBg:'rgba(239,111,56,.25)',
            itemSelectedBg:'rgba(239,111,56,.25)',
            itemHoverBg:'rgba(239,111,56,.1)',
            itemHoverColor:'rgba(239,111,56)',
            itemSelectedColor:'rgba(239,111,56)',
            colorPrimaryBorder:'rgb(239,111,56)',
        },
        Input:{
            fontSize:'16',
            hoverBorderColor:'rgba(239,111,56)',
            activeBorderColor:'rgba(239,111,56)',
            activeShadow:'0 0 0 2px rgba(239,111,56,.1)',
            colorPrimary:'rgb(239,111,56)',
        },
        Dropdown:{
            controlItemBgActive:'rgba(239,111,56,.1)',
            controlItemBgHover:'rgba(239,111,56,.15)',
        },
        Select:{
            colorPrimaryHover:'rgb(239,111,56)',
            colorPrimary:'rgb(239,111,56)',
            optionSelectedBg:'rgba(239,111,56,.4)',
            optionActiveBg:'rgba(239,111,56,.15)',
            optionSelectedColor:'rgb(239,111,56)',
            colorSplit:'rgb(239,111,56)',
        },
        Tabs:{
            itemHoverColor:'rgb(239,111,56)',
            itemSelectedColor:'rgb(239,111,56)',
            inkBarColor:'rgb(239,111,56)',
            itemActiveColor:'rgb(239,111,56)',
            horizontalMargin:0,
        },
        Pagination:{
            colorPrimary:'rgb(239,111,56)',
            colorPrimaryBorder:'rgb(239,111,56)',
            colorPrimaryHover:'rgb(239,111,56)',
            colorPrimaryActive:'rgb(239,111,56)',
            colorBgTextHover:'rgba(239,111,56,.1)',
            colorBgTextActive:'rgba(239,111,56,.1)',
        },
        Spin:{
            colorPrimary:'rgb(239,111,56)',
            contentHeight:'100%'
        },
        Table:{
            headerBg:'#f8f2f2',
            rowSelectedBg:'rgba(239,111,56,.4)',
            rowHoverBg:'rgba(239,111,56,.1)',
            rowSelectedHoverBg:'rgba(239,111,56,.4)',
            stickyScrollBarBg:'',
        },
        Checkbox:{
            colorPrimary:'rgb(239,111,56)',
            colorPrimaryActive:'rgb(239,111,56)',
            colorPrimaryHover:'rgb(239,111,56)',
        },
        Switch:{
            colorPrimary:'rgb(19, 206, 102)',
            colorPrimaryActive:'rgb(19, 206, 102)',
            colorPrimaryHover:'rgb(19, 206, 102)',
            colorTextQuaternary:'rgb(255, 73, 73)',
            colorTextTertiary:'rgb(255, 73, 73)',
        },
        Divider:{
            colorSplit:'rgba(205, 202, 202, 0.5)',
        },
        Timeline:{
            dotBg:'rgb(239,111,56)',
            tailColor:'rgb(239,111,56)',
            colorPrimary:'rgb(239,111,56)'
        },
        Tag:{
            lineWidth:2
        }
    }
}

export const OrderStatusClient = {
    1: 'Pending',
    2: 'Processing',
    3: 'In transit',
    4: 'Exception',
    5: 'Delivered'
}

export const OrderStatusClientColor = {
    1: 'default',
    2: 'processing',
    3: 'warning',
    4: 'error',
    5: 'success'
}

export const OrderStatus = {
    '':'All',
    3:'Paid',
    4:'Refunded',
    7:'Partially Refunded'
}

export const OrderStatusColor = {
    '':'default',
    3:'success',
    4:'error',
    7:'warning'
}

export const StoreProductStatus = {
    '1':'Active',
    '2':'Draft'
}

export const StoreProductStatusColor = {
    '1':'success',
    '2':'default'
}

export const AfterSalesStatus = {
    '':'All',
    '1':'Pending',
    '2':'In process',
    '3':'Completed',
}

export const AfterSalesStatusColor = {
    'Pending':'default',
    'In process':'error',
    'Completed':'success',
}

export const ProductStatusColor = {
    'Sourcing':'orange',
    'OutOfStock':'error',
    'NoFulfilled':'purple',
}

export const LocaleDropdown = {
    '1':{'English':<Icon type="English"/>},
    '2': {'German': <Icon type="German"/>},
    '3': {'French': <Icon type="French"/>},
}

export const NewSourceStatus = {
    'sourcing':(record) => {
        return (
            <Tag color="cyan">
                <Tooltip title="Sourcing" placement="bottomLeft">Sourcing</Tooltip>
            </Tag>
        )
    },
    'error':(record) => {
        return (
            <Tag color="red">
                <Tooltip title="Sourcing" placement="bottomLeft">Error</Tooltip>
            </Tag>
        )
    },
    'sourced':(record) => {
        return (
            <Tag color="orange">
                <Tooltip title={'Sourced'} placement="bottomLeft">Sourced</Tooltip>
            </Tag>
        )
    },
    'ask sourcing':(record,clickCallback) => {
        return (
            <Button type={'link'} style={{padding:0}} onClick={(e) => {
                e.stopPropagation();
                clickCallback(record);
            }}>
                <Tooltip title="Ask Sourcing" placement="bottomLeft">Ask Sourcing</Tooltip>
            </Button>
        )
    }
}

export const NewOrderStatus = {
    'fulfill order':(record,index,clickCallback) => {return (
        <Tooltip title="Fulfill Order" placement="bottomLeft">
            <Button type={'link'} style={{padding:0}} onClick={(e) => {
                e.stopPropagation();
                clickCallback(record,index);
            }}>
                Fulfill Order
            </Button>
        </Tooltip>
    )},
    'sourcing':(record) => {
        return (
            <Tooltip title={'Sourcing'} placement="bottomLeft">
                <Tag color="cyan">
                    Sourcing
                </Tag>
            </Tooltip>
        )
    },
    'processing':(record) => {
        return (
            <Tooltip title={record.processStatus} placement="bottomLeft">
                <Tag color="blue">
                    {record.processStatus}
                </Tag>
            </Tooltip>
        )
    },
    'top-up':(record,index,clickCallback) => {
        return (
            <Tooltip title={record.processStatus} placement="bottomLeft">
                <Button type={'link'} style={{padding:0}} onClick={(e) => {
                    e.stopPropagation();
                    clickCallback(record,index);
                }}>
                    {record.processStatus}
                </Button>
            </Tooltip>
        )
    },
    'cancel':(record) => {
        return (
            <Tooltip title={record.processStatus} placement="bottomLeft">
                <Tag color="default">
                    {record.processStatus}
                </Tag>
            </Tooltip>
        )
    },
    'shipped':(record) => {
        return (
            <Tooltip title={record.processStatus} placement="bottomLeft">
                <Tag color="green">
                    {record.processStatus}
                </Tag>
            </Tooltip>
        )
    },
    'ask sourcing':(record,index,clickCallback) => {
        return (
            <Tooltip title="Ask Sourcing" placement="bottomLeft">
                <Button type={'link'} style={{padding:0}} onClick={(e) => {
                    e.stopPropagation();
                    clickCallback(record,index);
                }}>
                    Ask Sourcing
                </Button>
            </Tooltip>
        )
    }
}

export const NewOrderStatusColor = {
    'Fulfill Order':'#ff9d17',
    'Ask Sourcing':'#7182eb',
    'Processing':'blue',
    'Sourcing':'cyan',
    'Top-Up':'#C84EFF',
    'Cancel':'default',
    'Shipped':'green'
}

export const VariantStatusColor = {
    'Sourcing':'#ffd591',
    'OutOfStock':'#ffccc7',
    'NoFulfilled':'#d3adf7'
}

export const PayStatus = {
    '0':'UnPaid',
    '1':'Paid',
    '2':'Refund',
    '4':'Closed'
}

export const PayStatusColor = {
    'UnPaid':'error',
    'Paid':'success',
    'Refund':'warning',
    'Closed':'default'
}

export const TypeStatus = {
    '1':'Order Cost',
    '2':'Recharge',
    '3':'Refund/Partial Refund',
    '4':'Additional Charges'
}

export const CostStatusColor = {
    0:'#ffd370',
    1:'#72f1ae',
    2:'#ff90c1'
}

export const ProductOrderStatus = {
    '7,12':'Processing',
    '7': 'Processing',
    '12': 'Processing',
    '11': 'Top-Up',
    '10': 'Sourcing',
    '14': 'Shipped',
    '13': 'Cancel'
}

export const ProductSourceStatus = {
    '1':'Sourced',
    '0':'Sourcing',
    '2': 'Ask Sourcing',
    '-1': 'Error'
}
