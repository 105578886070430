import './Settings.scss'
import {
    Avatar,
    Button,
    Card,
    Col,
    Form,
    Image,
    Input,
    message,
    Pagination,
    Progress,
    Row,
    Table,
    Tooltip,
    Upload
} from "antd";
import uuid from "../../../utils/uuid";
import {useEffect, useState} from "react";
import {findUserInfo, getGoodsStoreList, updateUserInfo} from "../../../api/user";
import {EditOutlined, UserOutlined} from "@ant-design/icons";
import {uploadAvatar, uploadStatic} from "../../../api/product";
import {isEmpty} from "../../../utils/stringUtils";
import useStore from "../../../store/store";

export default function PersonalCenter(props){
    const [userForm,setUserForm] = useState({name:'',email:'',phone:'',lastname:'',avatar:''});
    const [tableData,setTableData] = useState([]);
    const [listQuery,setListQuery] = useState({page:1,limit:10,total:0});
    const [form] = Form.useForm();
    const [readonly,setReadOnly] = useState(true);
    const [file, setFile] = useState(null);
    const [percent,setPercent] = useState(0);
    const [openProgress,setOpenProgress] = useState({opacity:0,zIndex:0});
    const currentLocaleData = useStore(state=>state.currentLocaleData);
    useEffect(() => {
        getUserInfo();
        getShopInfo();
    }, []);

    const getUserInfo = () => {
        props.setLoading(true);
        findUserInfo().then(res => {
            setUserForm({...res.data});
            form.setFieldsValue(res.data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            props.setLoading(false);
        })
    }

    const getShopInfo = () => {
        props.setLoading(true);
        getGoodsStoreList({pageNum: 1, pageSize: 999}).then(res => {
            const list = res.data.list
            const newlist = list.map((item,index) => {
                item.key = item.storeId;
                return item
            })
            setTableData(newlist);
            listQuery.total = +res.data.total;
            setListQuery({...listQuery});
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            props.setLoading(false);
        })
    }

    const handleChangeUserInfo = () => {
        if(isEmpty(userForm.username) || isEmpty(userForm.email)){
            message.error('Please enter your info!');
            return;
        }
        setReadOnly(true);
        props.setLoading(true);
        updateUserInfo(userForm).then(res => {
            message.success('modify success');
                getUserInfo();
        })
        .catch(err => console.error(err))
        .finally(() => {
            props.setLoading(false);
        })
    }

    const handleBeforeUpload = (file) => {
        return new Promise((resolve, reject) => {
            const isLt2M = file.size / 1024 / 1024 < 2;
            isLt2M ? resolve() : reject(new Error('Error'));
        }).then(
            () => {
                setFile(file);
                return file;
            },
            (err) => {
                message.error('The uploaded image size exceeds 2M！');
                return false;
            }
        )
    }

    const handleUpload = () => {
        setOpenProgress({opacity:1,zIndex:2});
        const formData = new FormData();
        formData.append('file', file);
        uploadStatic(formData,(progress) => {
            setPercent(Math.round((progress.loaded / progress.total) * 100));
        }).then(res => {
            uploadAvatar({avatar: res.data}, () => {}).then(res2 => {
                userForm.avatar = res.data
                setUserForm({...userForm})
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setTimeout(() => {
                    setOpenProgress({opacity:0,zIndex:0});
                    setPercent(0);
                },2000);
            })
        }).catch(err => console.error(err));

        // var reader = new FileReader();
        // reader.onloadend = function() {
        //     const avatar = reader.result
        //     uploadAvatar({avatar: avatar}, (progress) => {
        //         setPercent(Math.round((progress.loaded / progress.total) * 100));
        //     }).then(res => {
        //         userForm.avatar = avatar
        //         setUserForm({...userForm})
        //     }).catch(err => {
        //         console.log(err);
        //     }).finally(() => {
        //         setTimeout(() => {
        //             setOpenProgress({opacity:0,zIndex:0});
        //             setPercent(0);
        //         },2000);
        //     })
        // }
        // reader.readAsDataURL(file);
    }

    const handleFormChange = (changedValues,allValues) => {
        setUserForm({...allValues,avatar:userForm.avatar});
    }

    const updatePage = (page,pageSize) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        getShopInfo();
    }

    return (
        <Row style={{gap:'15px',flexDirection:'column',flexWrap:'nowrap',padding:'0 15px 15px 15px',width:'100%',height:'100%',overflow:'auto'}}>
            <Card
                style={{width:'100%',borderColor:'rgba(239,111,56,.3)'}}
                title={<h3>{currentLocaleData.CommonContent.Settings.personalCenter.personal.title}</h3>}
                extra={<Button onClick={handleChangeUserInfo} type="primary">{currentLocaleData.CommonContent.Settings.personalCenter.personal.storageButton}</Button>}
            >
                <Row style={{alignItems: "center", columnGap: '30px', justifyContent: 'space-between'}}>
                    <Progress
                        format={(percent) => (
                            <span style={{color:'#fff'}}>{`${percent}%`}</span>
                        )}
                        percent={percent}
                        style={openProgress}
                        className="progress-circle"
                        type="circle"
                        strokeColor="rgb(239,111,56)"
                        trailColor="rgba(239,111,56,.2)"
                    />
                    <div className="avatar-mask">
                        <Upload showUploadList={false} maxCount={1} name="avatar" customRequest={handleUpload} beforeUpload={handleBeforeUpload} accept="image/png, image/jpeg">
                            <Button icon={<EditOutlined/>}></Button>
                        </Upload>
                    </div>
                    <Avatar size={90}
                            style={{fontSize: '40px', zIndex: 0,borderColor:'rgb(239,111,56)'}}
                            src={userForm.avatar || "/avatar.png"}>
                    </Avatar>
                    <Form onValuesChange={handleFormChange} style={{flex: 1}} form={form} layout="vertical">
                        <Row style={{justifyContent: 'space-between'}}>
                            <Col span={11}>
                                <Form.Item label={currentLocaleData.CommonContent.Settings.personalCenter.personal.forms[0]} name="username">
                                    <Input style={{height: '35px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label={currentLocaleData.CommonContent.Settings.personalCenter.personal.forms[1]} name="lastname">
                                    <Input style={{height: '35px'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'space-between'}}>
                            <Col style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                columnGap: '10px'
                            }} span={11}>
                                <Form.Item style={{flex: 1}} label={currentLocaleData.CommonContent.Settings.personalCenter.personal.forms[2]} name="email2">
                                    {/* <Input disabled={readonly} style={{height: '35px'}}/> */}
                                    <p>{userForm.email}</p>
                                </Form.Item>
                                {/* <Button onClick={() => {
                                    setReadOnly(false)
                                }} style={{marginTop: '5px', height: '35px'}} type="primary">
                                    {currentLocaleData.CommonContent.Settings.personalCenter.personal.changeEmailAddressButton}
                                </Button> */}
                            </Col>
                            <Col span={11}>
                                <Form.Item label={currentLocaleData.CommonContent.Settings.personalCenter.personal.forms[3]} name="phone">
                                    <Input style={{height: '35px'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Card>
            <Card
                className="shop-card"
                style={{width: '100%',borderColor:'rgba(239,111,56,.3)'}}
                title={<h3>{currentLocaleData.CommonContent.Settings.personalCenter.shops.title}</h3>}
            >
                <Table
                    loading={false}
                    pagination={false}
                    columns={[
                        {title: currentLocaleData.CommonContent.Settings.personalCenter.shops.table.columns[0], dataIndex: 'storeName', key: uuid(), ellipsis: {showTitle: false},render:(_,record) => (
                            <Tooltip placement="bottomLeft" title={record.storeName}>{record.storeName}</Tooltip>
                        )},
                        {title: currentLocaleData.CommonContent.Settings.personalCenter.shops.table.columns[1], dataIndex: 'storeUrl',key:uuid(),ellipsis: {showTitle:false},render:(_,record) => (
                            <Tooltip placement="bottomLeft" title={record.storeUrl}>{record.storeUrl}</Tooltip>
                        )},
                    ]}
                    dataSource={tableData}
                />
                <Pagination
                    showSizeChanger
                    style={{marginTop:'15px',display:"flex",justifyContent:'flex-end',alignItems:'center'}}
                    showTotal={(total) => `Total ${total} items`}
                    current={listQuery.page}
                    total={listQuery.total}
                    pageSize={listQuery.limit}
                    onChange={updatePage}
                />
            </Card>
        </Row>
    )
}
