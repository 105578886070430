import {useState} from "react";
import Notifications from "./Notifications";
import ChangePassword from "./ChangePassword";
import PersonalCenter from "./PersonalCenter";
import CommonContent from "../../../components/CommonContent";
import useStore from "../../../store/store";

export default function Settings(props) {
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const handleTabChange = (type,activeKey) => {
        setActiveKey(activeKey);
    }
    return (
        <CommonContent
            loading={loading}
            filterable={false}
            pageTabs={[{
                key:'1',
                label:currentLocaleData.CommonContent.Settings.pageTabs[0],
            },{
                key:'2',
                label:currentLocaleData.CommonContent.Settings.pageTabs[1],
            },{
                key:'3',
                label:currentLocaleData.CommonContent.Settings.pageTabs[2],
            }]}
            onTabChange={handleTabChange}
        >
            {activeKey === '1' && <Notifications setLoading={setLoading}/>}
            {activeKey === '2' && <ChangePassword setLoading={setLoading}/>}
            {activeKey === '3' && <PersonalCenter setLoading={setLoading}/>}
        </CommonContent>
    )
}
