import request from '../utils/request'

// wifi测速
export function getBaseMonitor(params) {
  return request({
    url: '/user/network/speed',
    method: 'get',
    params
  })
}
