import {Flex, Tag, Tooltip} from "antd";
import {
    NewOrderStatus, PayStatus, PayStatusColor, ProductOrderStatus
} from "../../../commons/enum";
import uuid from "../../../utils/uuid";
import {storeUrlFormat} from "../../../utils/stringUtils";
import Decimal from "decimal.js"

function toDecimal(num) {
  return new Decimal(num)
}

function countPrice(a1, a2, a3){
    let count = toDecimal(0);
    if(a1){
        a1 = toDecimal(a1)
        count = count.plus(a1)
    }
    if(a2){
        a2 = toDecimal(a2)
        count = count.plus(a2)
    }
    if(a3){
        a3 = toDecimal(a3)
        count = count.plus(a3)
    }
    return count.toNumber()
}

const columns = (jumpToDetail,rowSelect,cols,tagClicks,autoPush) => [
    {title: cols[0],dataIndex: 'thirdOrderNo',key: uuid(),ellipsis: {showTitle:false},render: (_,record) => (
        <span
            onClick={(e) => {
                e.stopPropagation();
                jumpToDetail(record);
            }}
            style={{color:'rgb(239,111,56)'}} key={uuid()}>
            <Tooltip placement="bottomLeft" title={record.thirdOrderNo}>
                {record.thirdOrderNo}
            </Tooltip>
        </span>),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[1],dataIndex: 'storeName',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip placement="bottomLeft" title={record.storeName}>{storeUrlFormat(record.storeName)}</Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[2],dataIndex: 'thirdCreateTime',key: uuid(),width: 160,ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip placement="bottomLeft" title={record.thirdCreateTime}>
                {record.thirdCreateTime}
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    // {title: cols[3],dataIndex: 'customer',key: uuid(),width: 160,ellipsis: {showTitle: false},render: (_, record) => (
    //         <Tooltip title={_} placement="bottomLeft">
    //             {_}
    //         </Tooltip>
    //     ),onCell:(record,rowIndex) => {
    //         return {
    //             onClick:() => {rowSelect(record,rowIndex)},
    //             style:{
    //                 cursor:'pointer'
    //             }
    //         }
    //     }},
    {title: cols[4],dataIndex: 'payStatus',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip placement="bottomLeft" title={record.payStatus}>
                <Tag color={PayStatusColor[record.payStatus] || 'error'} key={uuid()}>
                    {record.payStatus || 'UnPaid'}
                </Tag>
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: `${cols[5]}($)`,dataIndex: 'totalPrice',key: uuid(),ellipsis: {showTitle: false},render: (_, record) => (
            <Tooltip placement="bottomLeft" title={record.total_price}>
                {`$${countPrice(record.fabliServiceFee, record.fabliProductFee, record.fabliShippingFee)}`}
            </Tooltip>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
    {title: cols[6],dataIndex: 'processStatus',key: uuid(),ellipsis:{showTitle: false},render: (_, record,index) => (
            <Flex vertical="vertical" gap={5} align="start">
                {getOrderStatus(autoPush,record,index,tagClicks)}
            </Flex>
        ),onCell:(record,rowIndex) => {
            return {
                onClick:() => {rowSelect(record,rowIndex)},
                style:{
                    cursor:'pointer'
                }
            }
        }},
];

function getOrderStatus(autoPush,record,index,tagClicks) {
    if(NewOrderStatus[String(record.processStatus.toLowerCase())])
    {
        return NewOrderStatus[String(record.processStatus.toLowerCase())](record,index,tagClicks[record.processStatus.toLowerCase()])
    }
    return null;
}

const pageTabs = (tabs) => [
    {
        key: '2',
        label: tabs[0],
    },
    {
        key: '1',
        label: tabs[1],
    }
]

const tableTabs = (tabs) => [
    {
        key: '',
        label: tabs[0],
    },
    {
        key: '1',
        label: tabs[1],
    }
    ,
    {
        key: '-1',
        label: tabs[2]
    }
    ,
    {
        key: '0',
        label: tabs[3]
    }
    ,
    {
        key: '2',
        label: tabs[4]
    }
    ,
    {
        key: '3',
        label: tabs[5]
    }
]

export {columns,pageTabs,tableTabs}
