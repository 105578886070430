import {Layout} from "antd";
import './FbAliLayout.scss'
import React, {memo, useState} from "react";

const FbAliLayout = memo(function FbAliLayout(props){
    const [collapsed, setCollapsed] = useState(false);
    const handleSideCollapse = (collapse) => {
        setCollapsed(collapse);
    }
    return (
        <Layout className="fbali-layout">
            <Layout.Header className="layout-header">{props.header}</Layout.Header>
            <Layout>
                <Layout.Sider width={'220px'} onCollapse={handleSideCollapse} className="layout-side" theme="light" breakpoint="lg" collapsedWidth="50">
                    <Layout style={{height:'100%'}}>
                        {props.side}
                        <Layout.Footer style={collapsed?{width:'50px'}:{}}>{props.sideFooter(collapsed)}</Layout.Footer>
                    </Layout>
                </Layout.Sider>
                <Layout.Content className="layout-content">
                    {props.content}
                </Layout.Content>
            </Layout>
        </Layout>
    )
})

export default FbAliLayout;
