import CommonTable from "../../../components/CommonTable";
import {useEffect, useRef, useState} from "react";
import {billColumns} from "./render";
import {businesslist, fundslist} from "../../../api/orders";
import CommonContent from "../../../components/CommonContent";
import useStore from "../../../store/store";
import {Button, DatePicker, message, Select} from "antd";
import uuid from "../../../utils/uuid";
import Exceljs from "exceljs";
import {DateFormatFile} from "../../../utils/DateUtils";
import I18N from "../../../commons/i18n";
import {TypeStatus} from "../../../commons/enum";
import {getStoreList} from "../../../api/product";
import dayjs from "dayjs";

// const storeIdMap = new Map()

function convertDate(a){
    const t = new Date(a)
    const y = t.getFullYear()
    const m = t.getMonth() + 1 > 9 ? (t.getMonth() + 1) : ("0" + (t.getMonth() + 1))
    const d = t.getDate() > 9 ? t.getDate() : ("0" + t.getDate())
    return `${y}-${m}-${d}`
}

const storeUrlMap = new Map()
export default function Bill(){
    const [loading,setLoading] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [listQuery,setListQuery] = useState({page:1,limit:10,total:0});
    const [currentTab,setCurrentTab] = useState('1');
    const currentLocaleData = useStore(state => state.currentLocaleData);
    const [columns,setColumns] = useState([]);
    const [queryData,setQueryData] = useState({order_no:'',order_name:'',type:'',storeUrl:''})
    const [currentSearch,setCurrentSearch] = useState('1');
    const [storeList,setStoreList] = useState([]);
    useEffect(() => {
        setLoading(true);
        (async function r(){
            const result = await getStoreList({pageNum: 1, pageSize: 999}).then((res) => {
                return res.data
            }).catch((err) => {
                console.error(err);
            })
            if(result && result.list.length){
                const list = result.list
                for(let i = 0;i<list.length;i++){
                    // storeIdMap.set(list[i].storeId, list[i])
                    storeUrlMap.set(list[i].storeUrl, list[i].storeId)
                }
                setStoreList(list);
                getTransactionList();
            }
        })()
        setLoading(false);
    }, []);

    useEffect(() => {
        setColumns(billColumns[currentTab](currentLocaleData.CommonContent.Bill.tables[currentTab]));
    },[currentLocaleData]);

    const getTransactionList = (formObj) => {
        setLoading(true);
        queryData.pageSize = listQuery.limit;
        queryData.pageNum = listQuery.page;
        const params = formObj? {...queryData,...formObj}:queryData;
        setQueryData({...params});
        const {flowId,orderName,type,storeUrl,createStartTime, createEndTime,pageSize,pageNum} = params;
        fundslist({flowId,orderName,type,storeUrl,createStartTime, createEndTime,pageSize,pageNum}).then(res => {
            const arr = res.data.list.map((item,index) => {
                item.key = index;
                return item
            })
            listQuery.total = parseInt(res.data.total);
            setListQuery({...listQuery});
            setTableData(arr);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getOrderCostList = (formObj) => {
        setLoading(true);
        queryData.pageSize = listQuery.limit;
        queryData.pageNum = listQuery.page;
        const params = formObj? {...queryData,...formObj}:queryData;
        setQueryData({...params});
        const {flowId,orderName,type,storeUrl,createStartTime, createEndTime,pageSize,pageNum} = params;
        businesslist({flowId,orderName,type,storeId: storeUrlMap.get(storeUrl),createStartTime, createEndTime,pageSize,pageNum}).then(res => {
            const arr = res.data.list.map((item,index) => {
                item.key = index;
                return item
            })
            listQuery.total = parseInt(res.data.total)
            setListQuery({...listQuery});
            setTableData(arr);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTabChange = (type,activeKey,filterShowList) => {
        const initQueryData = {flowId:'',orderName:'',type:'',storeUrl:'',createStartTime: '', createEndTime: ''};
        setQueryData(initQueryData);
        setTableData([]);
        setColumns(billColumns[activeKey](currentLocaleData.CommonContent.Bill.tables[activeKey]));
        setCurrentTab(String(activeKey));
        filterShowList = {};
        updatePage(1,10,String(activeKey),initQueryData);
        return [JSON.parse(JSON.stringify(initQueryData)),JSON.parse(JSON.stringify(filterShowList))];
    }

    const updatePage = (page,pageSize,activeKey,formObj) => {
        listQuery.page = page;
        listQuery.limit = pageSize;
        setListQuery({...listQuery});
        if(activeKey === '1') {
            getTransactionList(formObj);
        }else {
            getOrderCostList(formObj);
        }
    }

    const handleSearch = (e) => {
        const obj = {};
        if(currentSearch === '1'){
            obj.flowId = e.target.value;
            obj.orderName = '';
        }else{
            obj.orderName = e.target.value;
            obj.flowId = '';
        }
        updatePage(1,10,currentTab,obj);
    }

    const handleExport = () => {
        const localeColumns = currentLocaleData.CommonContent.Bill.tables[currentTab];
        const columns = billColumns[currentTab](I18N.en.CommonContent.Bill.tables[currentTab])
        const titles = [];
        const head = [];
        columns.map((item, index) => {
            titles.push(item.dataIndex);
            head.push({name:localeColumns[index]});
        });
        const body = tableData.map((item, index) => {
            const arr = [];
            titles.forEach(t => {
                if(currentTab === '2'){
                    arr.push(item[t]);
                }else{
                    arr.push(item[t]);
                }
            });
            return arr;
        });
        const workbook = new Exceljs.Workbook();
        const sheetName = `bill_${DateFormatFile()}.xlsx`;
        const sheet = workbook.addWorksheet(sheetName);
        sheet.addTable({
            name: "ThisTestXlsx",
            ref: "A1", // 主要数据从A5单元格开始
            headerRow: true,
            totalsRow: false,
            style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
            },
            columns: head,  // 把之前定义的表头数据传递进来
            rows: body
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            const link = document.createElement("a");
            const blob = new Blob([buffer], {
                type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = sheetName;
            link.href = URL.createObjectURL(blob);
            link.className = "download-blob"
            link.click();
            URL.revokeObjectURL(link.href);
            link.remove();
        });
    }

    return (
        <CommonContent
            defaultActiveKey={"1"}
            loading={loading}
            filterable={true}
            searchText={currentLocaleData.CommonContent.Bill.searchText}
            searchAfter={<Select value={currentSearch} onChange={(value) => {setCurrentSearch(value)}}>
                <Select.Option value="1">{currentLocaleData.CommonContent.Bill.filter.searchType[0]}</Select.Option>
                <Select.Option value="2">{currentLocaleData.CommonContent.Bill.filter.searchType[1]}</Select.Option>
            </Select>}
            searchEvent={handleSearch}
            pageTabs={[{key:'1',label:currentLocaleData.CommonContent.Bill.pageTabs[0]},{key:'2',label:currentLocaleData.CommonContent.Bill.pageTabs[1]}]}
            onTabChange={handleTabChange}
            apply={(formObj) => {
                formObj.type = formObj.type || '';
                formObj.storeUrl = formObj.storeUrl || '';
                if(formObj.date_range && formObj.date_range.length>0){
                    formObj.date_range = [formObj.date_range[0], formObj.date_range[1]]
                } else {
                    formObj.date_range = []
                    formObj.createStartTime = ""
                    formObj.createEndTime = ""
                }   
                const filterShowList = {};
                if(formObj.type){
                    filterShowList['Type'] = formObj.type;
                }
                if(formObj.storeUrl){
                    filterShowList['Store URL'] = formObj.storeUrl;
                }
                if(formObj.date_range && formObj.date_range.length>0){
                    filterShowList['Date Range'] = `${convertDate(formObj.date_range[0])} - ${convertDate(formObj.date_range[1])}`;
                    formObj.createStartTime = convertDate(formObj.date_range[0])
                    formObj.createEndTime = convertDate(formObj.date_range[1])
                }
                const copyForm = JSON.parse(JSON.stringify(formObj));
                copyForm.date_range = formObj.date_range && formObj.date_range.length>0?[dayjs(formObj.date_range[0]), dayjs(formObj.date_range[1])]:[];
                updatePage(1,10,currentTab,formObj);
                return [copyForm,filterShowList];
            }}
            filterList={currentTab==='1'?[
                {
                    name:"type",
                    title:currentLocaleData.CommonContent.Bill.filter.selects[0].title,
                    component:<Select allowClear options={[
                        {label:'Order Cost',value:'Order Cost'},
                        {label:'Recharge',value:'Recharge'},
                        {label:'Refund/Partial Refund',value:'Refund/Partial Refund'},
                        {label:'Additional Charges',value:'Additional Charges'},
                    ]}/>
                },
                {
                    name:"storeUrl",
                    title:currentLocaleData.CommonContent.Bill.filter.selects[2].title,
                    component:<Select showSearch allowClear options={storeList && storeList.map((item, index) => {
                        return {value: item.storeUrl, label: item.storeUrl}})}/>
                },
                {
                    name:"date_range",
                    title:currentLocaleData.CommonContent.Bill.filter.ranges[0],
                    component:
                        <DatePicker.RangePicker
                            style={{width:'100%'}}
                            // showTime
                            format="YYYY-MM-DD"
                        />
                },
            ]:[
                {
                    name:"storeUrl",
                    title:currentLocaleData.CommonContent.Bill.filter.selects[2].title,
                    component:<Select showSearch allowClear options={storeList && storeList.map((item, index) => {
                        return {value: item.storeUrl, label: item.storeUrl}})}/>
                },
                {
                    name:"date_range",
                    title:currentLocaleData.CommonContent.Bill.filter.ranges[0],
                    component:
                        <DatePicker.RangePicker
                            style={{width:'100%'}}
                            // showTime
                            format="YYYY-MM-DD"
                        />
                },
            ]}
            extra={[
                <Button key={uuid()} type="primary" onClick={handleExport}>{currentLocaleData.CommonContent.Bill.filter.extra.exportButton}</Button>
            ]}
        >
            <CommonTable
                loading={false}
                rowSelection={false}
                columns={columns}
                dataSource={tableData}
                page={listQuery.page}
                total={listQuery.total}
                pageSize={listQuery.limit}
                pageChange={(page,pageSize) => {updatePage(page,pageSize,currentTab)}}
            />
        </CommonContent>
    )
}
